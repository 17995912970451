import React, { Component } from 'react';
import EmailDashBoard from './EmailDashBoard';
import { inject, observer } from 'mobx-react';

@inject('session', 'email')
@observer
class EmailDashBoardContainer extends Component {
  render() {
    const { session, email } = this.props;
    return (
      <React.Fragment>
        <EmailDashBoard
          isSession={session.isSession}
          emailTabList={emailTabList}
          onClickTab={email.onClickTab}
          currentTab={email.getCurrentTab}
        />
      </React.Fragment>
    );
  }
}

const emailTabList = [
  {
    value: 'send',
    text: '이메일 발송하기'
  },
  {
    value: 'sent-email-list',
    text: '이메일 발송내역 조회'
  }
];

export default EmailDashBoardContainer;
