import React from 'react';
import './PromotionRegister.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import DateTimePicker from 'react-datetime-picker';
const PromotionRegister = ({
  onChangeIssuer,
  onChangePromotionCode,
  onChangeDcRate,
  onChangeExpireDate,
  expireDate,
  onClickRegisterPromotion,
  onClickClear
}) => {
  return (
    <div className='PromotionRegister'>
      <div className='promotion-register-section'>
        <LabelWithNode
          label={'발급자'}
          node={
            <input
              id='promotion-issuer'
              type='text'
              placeholder={'발급자를 입력해 주세요.'}
              onChange={e => onChangeIssuer(e.target.value)}
            />
          }
        />
      </div>
      <div className='promotion-register-section'>
        <LabelWithNode
          label={'프로모션 코드 (기업코드)'}
          node={
            <input
              id='promotion-code'
              type='text'
              placeholder={'프로모션 코드를 입력해 주세요.'}
              onChange={e => onChangePromotionCode(e.target.value)}
            />
          }
        />
      </div>
      <div className='promotion-register-section'>
        <LabelWithNode
          label={'코드 할인율'}
          node={
            <input
              id='promotion-dc-rate'
              type='text'
              placeholder={'프로모션 할인율을 입력해 주세요. (숫자만)'}
              onChange={e => onChangeDcRate(e.target.value)}
              maxLength={2}
            />
          }
        />
      </div>
      <div className='promotion-register-section'>
        <LabelWithNode label={'유효 기간'} node={<DateTimePicker onChange={onChangeExpireDate} value={expireDate} />} />
      </div>
      <div className='promotion-register-section'>
        <div className='promotion-register-btns'>
          <div className='promotion-register-btn'>
            <button className='promotion-add-btn' onClick={onClickRegisterPromotion}>
              등록
            </button>
          </div>
          <div className='promotion-register-btn'>
            <button className='promotion-clear-btn' onClick={onClickClear}>
              초기화
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionRegister;
