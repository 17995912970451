import React from 'react';
import './MapDashBoard.scss';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { MapListContainer } from '../MapList';
import { MapListOptionsContainer } from '../MapListOptions';
import { MapDetailPopupContainer } from '../MapDetailPopup';
const MapDashBoard = ({ tabList, currentTab, onClickTab, isSession }) => {
  if (!isSession) return (window.location = '/');
  return (
    <DashBoardTemplate title={'맵'} tabList={tabList} currentTab={currentTab} onClickTab={onClickTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<MapListOptionsContainer />}>
          <MapListContainer />
          <MapDetailPopupContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default MapDashBoard;
