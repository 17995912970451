import React from 'react';
import './LicenseCodeDetailPopup.scss';
import { NewPopup } from '../../common/NewPopup';
import { LicenseCodeUsedUsers } from '../LicenseCodeUsedUsers';
import { dateFormat } from '../../../lib/common/dateFormat';

const LicenseCodeDetailPopup = ({ isShowPopup, licenseCodeInfo, onTogglePopup }) => {
  if (!isShowPopup) return null;
  return (
    <label className='LicenseCodeDetailPopup'>
      <NewPopup title={'라이선스 코드 상세정보'} onTogglePopup={onTogglePopup}>
        <div className='license-code-detail-info-wrap'>
          <div className='license-code-detail-info'>
            <div className='license-code-detail-section'>
              <div className='license-code-detail-box'>
                <label>라이선스 타입</label>
                <p>{licenseCodeInfo.licenseType}</p>
              </div>
              <div className='license-code-detail-box'>
                <label>라이선스 등급</label>
                <p>{licenseCodeInfo.licenseGrade}</p>
              </div>
            </div>
            <div className='license-code-detail-section'>
              <div className='license-code-detail-box'>
                <label>라이선스 코드</label>
                <p>{licenseCodeInfo.licenseCode}</p>
              </div>
              <div className='license-code-detail-box'>
                <label>라이선스 기간</label>
                <p>{licenseCodeInfo.licenseDate}일</p>
              </div>
            </div>
            <div className='license-code-detail-section'>
              <div className='license-code-used-title'>사용자 명단 ({licenseCodeInfo.codeUsers.length}명)</div>
              {licenseCodeInfo.codeUsers.map(userInfo => (
                <LicenseCodeUsedUsers userInfo={userInfo} />
              ))}
            </div>
            <div className='license-code-detail-section'>
              <div className='license-code-detail-box'>
                <label>라이선스 코드 사용 가능일</label>
                <p>{dateFormat(licenseCodeInfo.startDate, 'YYYY년 MM월 DD일 HH시 mm분')}</p>
              </div>
              <div className='license-code-detail-box'>
                <label>라이선스 코드 사용 만료일</label>
                <p>{dateFormat(licenseCodeInfo.endDate, 'YYYY년 MM월 DD일 HH시 mm분')}</p>
              </div>
            </div>
            <div className='license-code-detail-section'>
              <div className='license-code-detail-box'>
                <label>라이선스 코드 등록일</label>
                <p>{dateFormat(licenseCodeInfo.regDate, 'YYYY년 MM월 DD일 HH시 mm분')}</p>
              </div>
              <div className='license-code-detail-box'>
                <label>라이선스 코드 수정일</label>
                <p>{dateFormat(licenseCodeInfo.modDate, 'YYYY년 MM월 DD일 HH시 mm분')}</p>
              </div>
            </div>
          </div>
        </div>
      </NewPopup>
    </label>
  );
};

export default LicenseCodeDetailPopup;
