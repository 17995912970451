import React from 'react';
import { Link } from 'react-router-dom';
import './NoticeItem.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Table, Button } from 'semantic-ui-react';
const NoticeItem = ({ noticeInfo, onDeleteNotice }) => {
  return (
    <Table.Row className='NoticeItem'>
      <Table.Cell>{noticeInfo.title}</Table.Cell>
      <Table.Cell>
        {(noticeInfo.noticeType === 'notice' && '공지사항') || (noticeInfo.noticeType === 'event' && '이벤트')}
      </Table.Cell>
      <Table.Cell>{dateFormat(noticeInfo.regDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell>{dateFormat(noticeInfo.modDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell>
        <Link to={`editor?editor_type=notice&notice_id=${noticeInfo.noticeId}`}>
          <Button size='mini' positive>
            수정하기
          </Button>
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Button size='mini' negative onClick={() => onDeleteNotice(noticeInfo.noticeId)}>
          삭제하기
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default NoticeItem;
