import { action, observable, computed } from 'mobx';
import { VrwareApi } from '../lib/api';
class LicenseCodesStore {
  //#region 탭
  @observable currentTab = 'list';

  @action
  onClickTab = (tab) => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 라이선스 코드 등록
  @observable addLicenseType = null;
  @observable addStartDate = new Date();
  @observable addEndDate = new Date().setDate(new Date().getDate() + 30);
  @observable addLicenseDate = null;
  @observable addLicenseGrade = null;
  @observable addUsageLimit = null;

  @action
  onClickAddDataClear = () => {
    document.getElementById('license-date').value = '';
    document.getElementById('license-code-usage-limit').value = '';

    this.addLicenseDate = null;
    this.addUsageLimit = null;
    this.addLicenseType = null;
    this.addLicenseGrade = null;
    this.addStartDate = new Date();
    this.addEndDate = new Date().setDate(new Date().getDate() + 30);
  };

  @action
  onClickAddLicenseCode = async () => {
    const { addLicenseDate, addEndDate, addLicenseGrade, addLicenseType, addStartDate, addUsageLimit } = this;

    if (!addLicenseDate || !addEndDate || !addLicenseGrade || !addLicenseType || !addStartDate || !addUsageLimit) {
      return alert('입력하지 않은 값이 존재합니다. 다시 확인해 주세요.');
    }

    const bodyData = {
      licenseType: addLicenseType,
      licenseGrade: addLicenseGrade,
      licenseDate: addLicenseDate,
      startDate: addStartDate,
      endDate: addEndDate,
      usageLimit: addUsageLimit,
    };

    try {
      const insertInfo = await VrwareApi.post(`/v1.0/license-codes`, bodyData);

      if (insertInfo.data.status_code === 200) {
        this.onClickAddDataClear();
        return alert('라이선스 코드 등록 완료');
      }
    } catch (error) {
      if (error.response.data.status_code >= 400) {
        return alert(error.response.data.alert_message);
      }
    }
  };

  @action
  onChangeAddLicenseType = (licenseType) => {
    this.addLicenseType = licenseType;
  };

  @action
  onChangeAddLicenseGrade = (licenseGrade) => {
    this.addLicenseGrade = licenseGrade;
  };

  @action
  onChangeAddLicenseDate = (licenseDate) => {
    this.addLicenseDate = licenseDate;
  };

  @action
  onChangeAddStartDate = (startDate) => {
    this.addStartDate = startDate;
  };

  @action
  onChangeAddEndDate = (endDate) => {
    this.addEndDate = endDate;
  };

  @action
  onChangeAddUsageLimit = (usageLimit) => {
    this.addUsageLimit = usageLimit;
  };

  @computed
  get getAddLicenseType() {
    return this.addLicenseType;
  }
  @computed
  get getAddLicenseDate() {
    return this.addLicenseDate;
  }
  @computed
  get getAddLicenseGrade() {
    return this.addLicenseGrade;
  }
  @computed
  get getAddStartDate() {
    return this.addStartDate;
  }
  @computed
  get getAddEndDate() {
    return this.addEndDate;
  }
  @computed
  get getAddUsageLimit() {
    return this.addUsageLimit;
  }
  //#endregion

  //#region 라이선스 코드 목록
  @observable licenseCodeList = [];
  @observable licenseCodeInfo = {};
  @observable totalCount = 0;
  @observable totalPages = 0;
  @observable currentPage = 1;
  @observable limit = 15;
  @observable offset = 0;
  @observable searchLicenseCode = '';
  @observable searchLicenseType = '';
  @observable searchLicenseGrade = '';
  //몽고디비에선 날짜를 쿼리할때 ISODate를 권장함
  @observable searchStartDate = '';
  @observable searchEndDate = '';
  @observable isShowDetailPopup = false;
  @observable isSearchDate = false;

  @action
  onClickLicenseCodeDetail = async (licenseCode) => {
    if (!licenseCode) return;

    const licenseCodeInfo = await VrwareApi.get(`/v1.0/license-codes?licenseCode=${licenseCode}`);

    if (licenseCodeInfo.data.status_code === 200) {
      this.licenseCodeInfo = licenseCodeInfo.data.data.licenseCodeList[0];
      this.isShowDetailPopup = true;
    }
  };

  @action
  onToggleSearchDate = (bool) => {
    if (bool) {
      this.searchStartDate = new Date(new Date().setDate(new Date().getDate() - 90)).toISOString();
      this.searchEndDate = new Date(new Date().setDate(new Date().getDate() + 90)).toISOString();
    } else {
      this.searchStartDate = '';
      this.searchEndDate = '';
    }
    this.isSearchDate = bool;
  };

  @action
  onToggleDetailPopup = () => {
    this.isShowDetailPopup = !this.isShowDetailPopup;
    this.licenseCodeInfo = {};
  };

  @action
  onClickSearchDataClear = () => {
    document.getElementById('search-license-code').value = '';
    document.getElementById('search-license-type').value = '';
    if (document.getElementById('search-license-grade')) {
      //라이선스 타입을 선택했을 때만 지우기
      document.getElementById('search-license-grade').value = '';
    }

    this.onToggleSearchDate(false);
    this.searchLicenseCode = '';
    this.searchLicenseType = '';
    this.searchLicenseGrade = '';
  };

  @action
  onChangeSearchLicenseCode = (licenseCode) => {
    this.searchLicenseCode = licenseCode;
  };
  @action
  onChangeSearchLicenseType = (licenseType) => {
    this.searchLicenseType = licenseType;
  };
  @action
  onChangeSearchLicenseGrade = (licenseGrade) => {
    this.searchLicenseGrade = licenseGrade;
  };
  @action
  onChangeSearchStartDate = (startDate) => {
    this.searchStartDate = new Date(startDate).toISOString();
  };
  @action
  onChangeSearchEndDate = (endDate) => {
    this.searchEndDate = new Date(endDate).toISOString();
  };

  @action
  onClickSearch = async () => {
    const {
      offset,
      limit,
      searchLicenseCode,
      searchEndDate,
      searchLicenseGrade,
      searchLicenseType,
      searchStartDate,
    } = this;

    try {
      const res = await VrwareApi.get(
        `/v1.0/license-codes?offset=${offset}&limit=${limit}&licenseCode=${
          searchLicenseCode.toUpperCase() || ''
        }&licenseType=${searchLicenseType || ''}&licenseGrade=${searchLicenseGrade || ''}&startDate=${
          searchStartDate || ''
        }&endDate=${searchEndDate || ''}`
      );
      if (res.data.status_code === 200) {
        this.licenseCodeList = res.data.data.licenseCodeList;
        this.totalCount = res.data.data.totalCount;
        this.totalPages = Math.floor(this.totalCount / limit);
      }
    } catch (error) {
      if (error.response.data.status_code > 400) {
        alert('일치하는 정보가 없습니다. 다시 시도해 주세요.');
      }
    }
  };

  @action
  onChangePage = (page) => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearch();
  };

  @action
  onEnterSearch = (e) => {
    if (e.key === 'Enter') {
      this.onClickSearch();
    }
  };

  @computed
  get getLicenseCodeList() {
    return this.licenseCodeList;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getTotalPages() {
    return this.totalPages;
  }

  @computed
  get getSearchLicenseType() {
    return this.searchLicenseType;
  }
  @computed
  get getSearchLicenseGrade() {
    return this.searchLicenseGrade;
  }
  @computed
  get getSearchStartDate() {
    return this.searchStartDate;
  }
  @computed
  get getSearchEndDate() {
    return this.searchEndDate;
  }

  @computed
  get getLicenseCodeInfo() {
    return this.licenseCodeInfo;
  }
  @computed
  get getIsShowDetailPopup() {
    return this.isShowDetailPopup;
  }

  @computed
  get getIsSearchDate() {
    return this.isSearchDate;
  }

  //#endregion
}

export default new LicenseCodesStore();
