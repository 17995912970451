import React from 'react';
import './LicenseList.scss';
import { LicenseItem } from '../LicenseItem';
import { Table } from 'semantic-ui-react';

const LicenseList = ({ licenseList, count }) => {
  return (
    <div className='LicenseList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>이메일</Table.HeaderCell>
            <Table.HeaderCell>라이선스 등급</Table.HeaderCell>
            <Table.HeaderCell>라이선스 타입</Table.HeaderCell>
            <Table.HeaderCell>등록 날짜</Table.HeaderCell>
            <Table.HeaderCell>만료 날짜</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {licenseList.map(license => (
            <LicenseItem license={license} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default LicenseList;
