import React, { Component } from 'react';
import StudentListOptions from './StudentListOptions';

import { inject, observer } from 'mobx-react';

@inject('students')
@observer
class StudentListOptionsContainer extends Component {
  render() {
    const { students } = this.props;
    return (
      <React.Fragment>
        <StudentListOptions
          onChangeSearch={students.onChangeSearch}
          onClickSearch={students.onClickSearch}
          onEnterSearch={students.onEnterSearch}
          onChangeLimit={students.onChangeLimit}
          onChangeMemberState={students.onChangeMemberState}
          onChangeMemberType={students.onChangeMemberType}
          onChangeSearchType={students.onChangeSearchType}
          limit={students.getLimit}
          memberState={students.getMemberState}
          memberType={students.getMemberType}
          searchType={students.getSearchType}
          onClickClear={students.onClickClearMemberList}
          onChangeGrade={students.onChangeGrade}
          grade={students.getGrade}
          onChangeSchool={students.onChangeSchool}
          onChangeName={students.onChangeName}
          onChangeUid={students.onChangeUid}
          onClickStudentSearch={students.onClickStudentSearch}
        />
      </React.Fragment>
    );
  }
}

export default StudentListOptionsContainer;
