import React from 'react';
import './NursingDetailPopup.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Popup } from '../../common/Popup';
import moment from 'moment';

import { LabelWithNode } from '../../common/LabelWithNode';
import DateTimePicker from 'react-datetime-picker';

import { Button, Item, Segment, Input } from 'semantic-ui-react';
const NursingDetailPopup = ({
  showMemberDetail,
  onToggleSchoolDetail,
  onToggleShowUsage,
  viewname,
  viewstart,
  viewexpired,
  onChangeRequestUpdateStartDate,
  onChangeRequestUpdateEndDate,
  requestUpdateEndDate,
  requestUpdateStartDate,
  onClickUpdateDate
}) => {
  return (
    <div className='MemberDetailPopup'>
      <Popup
        title={'사용기간 변경'}
        isOpen={showMemberDetail}
        onTogglePopup={() => onToggleSchoolDetail()}
        btns={
          <React.Fragment>
 
            <Button positive onClick={onClickUpdateDate}>
              수정
            </Button>

          </React.Fragment>
        }
      >
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>학교명</Item.Header>
              <Item.Description>
                <Segment>{viewname}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <Item.Header>시작일</Item.Header>
              <Item.Description>
        
              <LabelWithNode
          label={'시작일'}
          node={<DateTimePicker id="sms-date-pickerr" clearIcon={null}  onChange={onChangeRequestUpdateStartDate} value={moment(viewstart, moment.defaultFormat).toDate()} />}
        />


              </Item.Description>
            </Item.Content>
          </Item>
          
          <Item>
            <Item.Content>
              <Item.Header>종료일</Item.Header>
              <Item.Description>


              <LabelWithNode
          label={'종료일'}
          node={<DateTimePicker id="sms-date-pickerr" clearIcon={null}  onChange={onChangeRequestUpdateEndDate} value={moment(viewexpired, moment.defaultFormat).toDate()} />}
        />


              </Item.Description>
            </Item.Content>
          </Item>

        </Item.Group>
      </Popup>
    </div>
  );
};

export default NursingDetailPopup;
