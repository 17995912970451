import React from 'react';
import './LoginTemplate.scss';

const LoginTemplate = ({ header, children, footer }) => {
  return (
    <div className='LoginTemplate'>
      {header}
      <main>
        <div
          className='login-bg'
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1515847049296-a281d6401047?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80)`
          }}
        ></div>
        {children}
      </main>
      {footer}
    </div>
  );
};

export default LoginTemplate;
