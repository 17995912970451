import React from 'react';
import './ProfessorListPagination.scss';
import { Pagination } from '../../common/Pagination';

const ProfessorListPagination = ({ memberListTotalCount, onChangePage, currentPage, limit, totalPages }) => {
  return (
    <div className='MemberListPagination'>
      <Pagination
        onChangePage={onChangePage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default ProfessorListPagination;
