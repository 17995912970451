import React, { Component } from 'react';
import ProfessorListPagination from './ProfessorListPagination';
import { inject, observer } from 'mobx-react';

@inject('professors')
@observer
class ProfessorListPaginationContainer extends Component {
  render() {
    const { professors } = this.props;
    return (
      <React.Fragment>
        <ProfessorListPagination
          currentPage={professors.currentPage}
          limit={professors.getLimit}
          memberListTotalCount={professors.memberListTotalCount}
          onChangePage={professors.onChangePage}
          totalPages={professors.getTotalPages}
        />
      </React.Fragment>
    );
  }
}

export default ProfessorListPaginationContainer;
