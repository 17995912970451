import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MemberUpdatePopup from './MemberUpdatePopup';

@inject('members')
@observer
class MemberUpdatePopupContainer extends Component {
  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        <MemberUpdatePopup
          showMemberUpdate={members.showMemberUpdate}
          onToggleMemberUpdate={members.onToggleMemberUpdate}
          memberDetail={members.getMemberDetail}
          onClickMemberUpdate={members.onClickMemberUpdate}
          onChangeSelectMemberType={members.onChangeSelectMemberType}
          onChangeSelectMemberState={members.onChangeSelectMemberState}
        />
      </React.Fragment>
    );
  }
}

export default MemberUpdatePopupContainer;
