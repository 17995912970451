import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { observer } from 'mobx-react';
import './EmailReceiverList.scss';

const EmailReceiverList = ({ receiverList, onDeleteReceiver }) => {
  return (
    <div className="EmailReceiverList">
      {receiverList.map(receiver => (
        <div key={receiver} className="EmailReceiverItem">
          <div className="receiver-email">{receiver}</div>
          <div className="receiver-remove">
            <IoMdClose onClick={() => onDeleteReceiver(receiver)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(EmailReceiverList);
