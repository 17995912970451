import React from 'react';
import './AuthList.scss';
import { Table, Icon } from 'semantic-ui-react';
import { Pagination } from '../../common/Pagination';
import { dateFormat } from '../../../lib/common/dateFormat';
const AuthList = ({ authList, totalCount, currentPage, onChangePage }) => {
  return (
    <div className='AuthList'>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>프로그램 이름</Table.HeaderCell>
            <Table.HeaderCell>프로그램 플랫폼</Table.HeaderCell>
            <Table.HeaderCell>클라이언트 ID</Table.HeaderCell>
            <Table.HeaderCell>클라이언트 Key</Table.HeaderCell>
            <Table.HeaderCell>유효 기간</Table.HeaderCell>
            <Table.HeaderCell>등록 날짜</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {authList.map(authInfo => (
            <Table.Row>
              <Table.Cell>{authInfo.programName}</Table.Cell>
              <Table.Cell>
                <Icon name={getPlatformIcon(authInfo.programPlatform)} /> {authInfo.programPlatform}
              </Table.Cell>
              <Table.Cell>{authInfo.clientId}</Table.Cell>
              <Table.Cell>{authInfo.clientSecretKey}</Table.Cell>
              <Table.Cell>{dateFormat(authInfo.expireDate, 'YYYY년 MM월 DD일')}</Table.Cell>
              <Table.Cell>{dateFormat(authInfo.regDate, 'YYYY년 MM월 DD일')}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination totalPages={totalCount} currentPage={currentPage} onChangePage={onChangePage} />
    </div>
  );
};

const getPlatformIcon = programPlatform => {
  if (programPlatform === 'windows') {
    return 'windows';
  } else if (programPlatform === 'macOs' || programPlatform === 'iOS') {
    return 'apple';
  } else if (programPlatform === 'android') {
    return 'android';
  } else if (programPlatform === 'web') {
    return 'chrome';
  }
};

export default AuthList;
