import React from 'react';
import './ProfessorListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import Select from 'react-select';
import { Button, Icon } from 'semantic-ui-react';
const ProfessorListOptions = ({
  onClickSearch,
  onChangeSearch,
  onEnterSearch,
  onChangeLimit,
  onChangeMemberState,
  onChangeMemberType,
  onChangeSearchType,
  onClickClear,
  limit,
  memberState,
  memberType,
  searchType,
  onChangeGrade,
  grade,
  onChangeSchool,
  onChangeName,
  onChangeAid,
  onClickProfessorSearch
}) => {
  return (
    <div className='MemberListOptions'>
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'목록 수'}
          node={
            <Select
              id='member-limit'
              defaultValue={limitList[0]}
              options={limitList}
              value={limit}
              onChange={onChangeLimit}
            ></Select>
          }
        ></LabelWithNode>
      </div>
      
      
      
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'학교명'}
          node={
            <input
              id='member-list-search1'
              type='text'
              name='search'
              placeholder='검색어를 입력해주세요.'
              onChange={e => onChangeSchool(e.target.value)}
              onKeyPress={onEnterSearch}
            ></input>
          }
        ></LabelWithNode>
      </div>

      <div className='member-list-option-section'>
        <LabelWithNode
          label={'학번'}
          node={
            <input
              id='member-list-search2'
              type='text'
              name='search'
              placeholder='검색어를 입력해주세요.'
              onChange={e => onChangeAid(e.target.value)}
              onKeyPress={onEnterSearch}
            ></input>
          }
        ></LabelWithNode>
      </div>

      <div className='member-list-option-section'>
        <LabelWithNode
          label={'이름'}
          node={
            <input
              id='member-list-search3'
              type='text'
              name='search'
              placeholder='검색어를 입력해주세요.'
              onChange={e => onChangeName(e.target.value)}
              onKeyPress={onEnterSearch}
            ></input>
          }
        ></LabelWithNode>
      </div>

      
      <div className='member-list-option-section'>
        <div className='member-list-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickProfessorSearch} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

const limitList = [
  {
    label: '5',
    value: 5
  },
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  }
];

const memberStateList = [
  { label: '전체', value: 'all' },
  { label: '정상', value: '1' },
  { label: '가입대기', value: '0' },
  { label: '휴면', value: '2' },
  { label: '정지', value: '3' },
  { label: '탈퇴', value: '4' }
];
const memberTypeList = [
  { label: '전체', value: 'all' },
  { label: '일반', value: '1' },
  { label: '학생', value: '2' },
  { label: '선생님', value: '3' }
];
const searchTypeList = [
  { label: '이메일', value: 'email' },
  { label: '닉네임', value: 'nickname' }
];

export default ProfessorListOptions;
