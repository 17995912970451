import React, { Component } from 'react';
import NursingList from './NursingList';
import { inject, observer } from 'mobx-react';

@inject('nursing')
@observer
class NursingListContainer extends Component {
  render() {
    const { nursing } = this.props;
    return (
      <React.Fragment>
        <NursingList
          schoolList={nursing.getSchoolList}
          adminList={nursing.getAdminList}
          currentPage={nursing.getCurrentPage}
          totalPages={nursing.getTotalPages}
          onChangePage={nursing.onChangeAdminListPage}
          onClickReleaseAdmin={nursing.onClickReleaseAdmin}
          onToggleSchoolDetail={nursing.onToggleSchoolDetail}
        />
      </React.Fragment>
    );
  }
}

export default NursingListContainer;
