import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { PaymentDashBoardContainer } from '../components/payments/PaymentDashBoard';
export const Payments = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <PaymentDashBoardContainer />
    </React.Fragment>
  );
};

export default Payments;
