import React, { Component } from 'react';
import EmailSentHistoryDetailPopup from './EmailSentHistoryDetailPopup';
import { inject, observer } from 'mobx-react';

@inject('email')
@observer
class EmailSentHistoryDetailPopupContainer extends Component {
  render() {
    const { email } = this.props;
    return (
      <React.Fragment>
        <EmailSentHistoryDetailPopup
          isShown={email.getIsShownDetailPopup}
          onTogglePopup={email.onToggleEmailDetail}
          emailInfo={email.getSentEmailDetail}
        />
      </React.Fragment>
    );
  }
}

export default EmailSentHistoryDetailPopupContainer;
