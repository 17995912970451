import React from 'react';
import './SubmitBox.scss';
import { Select } from 'semantic-ui-react';
const SubmitBox = ({
  isSubmitBox,
  onSubmit,
  onChangeNoticeType,
  onChangeManualTarget,
  onChangeManualType,
  noticeType,
  manualType,
  manualTarget,
  editorType
}) => {
  if (!isSubmitBox) return null;
  const noticeTypeList = [
    {
      text: '공지사항',
      value: 'notice'
    },
    {
      text: '이벤트',
      value: 'event'
    }
  ];
  const manualTypeList = [
    {
      text: 'VRWARE School',
      value: 'school'
    }
  ];
  const manualTargetList = [
    {
      text: '일반/학생',
      value: 'student'
    },
    {
      text: '강사',
      value: 'teacher'
    }
  ];
  return (
    <div className='SubmitBox'>
      <section className='option-wrap'>
        {editorType === 'notice' && (
          <section className='option-section'>
            <div className='option-title'>유형</div>
            <div>
              <Select
                id='notice-type'
                defaultValue={noticeType || 'notice'}
                options={noticeTypeList}
                onChange={(e, { value }) => onChangeNoticeType(value)}
                fluid
              ></Select>
            </div>
          </section>
        )}
        {editorType === 'manual' && (
          <>
            <section className='option-section'>
              <div className='option-title'>매뉴얼 유형</div>
              <div>
                <Select
                  id='manual-type'
                  defaultValue={manualType || 'school'}
                  options={manualTypeList}
                  onChange={(e, { value }) => onChangeManualType(value)}
                  fluid
                ></Select>
              </div>
            </section>
            <section className='option-section'>
              <div className='option-title'>매뉴얼 대상</div>
              <div>
                <Select
                  id='manual-target'
                  defaultValue={manualTarget || 'student'}
                  options={manualTargetList}
                  onChange={(e, { value }) => onChangeManualTarget(value)}
                  fluid
                ></Select>
              </div>
            </section>
          </>
        )}
        <div className='post-submit'>
          <button onClick={onSubmit}>완료</button>
        </div>
      </section>
    </div>
  );
};

export default SubmitBox;
