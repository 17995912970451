import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import './ProgramDashBoard.scss';
import { ProgramRegisterContainer } from '../ProgramRegister';
import { ProgramListContainer } from '../ProgramList';
import { ProgramListOptionsContainer } from '../ProgramListOptions';
const ProgramDashBoard = ({ tabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location = '/');
  return (
    <DashBoardTemplate title={'프로그램'} tabList={tabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'add' && (
        <DashBoardBody>
          <ProgramRegisterContainer />
        </DashBoardBody>
      )}
      {currentTab === 'list' && (
        <DashBoardBody option={<ProgramListOptionsContainer />}>
          <ProgramListContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default ProgramDashBoard;
