import React, { useEffect, useState, useCallback } from 'react';
import './UsagePopup.scss';
import { Item } from 'semantic-ui-react';
import { Popup } from '../../common/Popup';
import { SchoolApi } from '../../../lib/api';
import ProgressBar from '@ramonak/react-progress-bar';
import Axios from 'axios';

export const UsagePopup = ({ isShowUsage, onToggleShowUsage, memberInfo, licenseDetail }) => {
  const [totalBytes, setTotalBytes] = useState(0);
  const [classList, setClassList] = useState([]);
  const [limitMb, setLimitMb] = useState(100);
  const [limitClassCount, setLimitClassCount] = useState(50);
  const [extraCharge, setExtraCharge] = useState(0);

  const onLoadExtraCharge = useCallback(async () => {
    if (!memberInfo.email) return;

    const email = memberInfo.email;
    const priceRes = await Axios.get(`https://school-vrware-gateway.toast.paas-ta.com/map/price/${email}`, {
      headers: {
        clientId: 'JWPU0SBJPHKMZS9YJFNB',
        clientSecretKey: 'KJIKapzeWvyIviZm5wRKMYv86k7tVaBdE8n9TcWN',
      },
    });

    if (priceRes.status === 200) {
      setExtraCharge(priceRes.data.money);
    }
  }, [memberInfo.email]);

  const onLoadLimit = useCallback(async () => {
    const licenseGrade = licenseDetail.licenseGrade;

    if (licenseGrade === 'home') {
      setLimitMb(100);
      setLimitClassCount(50);
    } else if (licenseGrade === 'tutor') {
      setLimitMb(150);
      setLimitClassCount(70);
    } else if (licenseGrade === 'lab') {
      setLimitMb(200);
      setLimitClassCount(100);
    } else if (licenseGrade === 'class') {
      setLimitMb(300);
      setLimitClassCount(150);
    }
  }, [licenseDetail.licenseGrade]);

  const onLoadMapInfo = useCallback(async () => {
    if (!memberInfo.email) return;

    const email = memberInfo.email;
    const mapRes = await SchoolApi.get(`/v2.0/maps?email=${email}`);

    if (mapRes.data.status_code === 200) {
      setTotalBytes(mapRes.data.data.totalBytes);
    }
  }, [memberInfo]);

  const onLoadClassInfo = useCallback(async () => {
    if (!memberInfo.email) return;

    const email = memberInfo.email;
    const classRes = await SchoolApi.get(`/v1.0/classes?teacherEmail=${email}`);

    if (classRes.data.status_code === 200) {
      setClassList(classRes.data.data.classList);
    }
  }, [memberInfo]);

  useEffect(() => {
    onLoadMapInfo();
    onLoadClassInfo();
    onLoadLimit();
    onLoadExtraCharge();

    return () => {
      setTotalBytes(0);
      setClassList([]);
      setLimitClassCount(50);
      setLimitMb(100);
    };
  }, [onLoadMapInfo, onLoadClassInfo, onLoadLimit, onLoadExtraCharge]);

  return (
    <div className='UsagePopup'>
      <Popup isOpen={isShowUsage} title={'사용량 보기'} onTogglePopup={onToggleShowUsage}>
        <Item.Group>
          <Item>
            <Item.Content>
              <div style={{ marginBottom: '2rem' }}>
                <h3 style={{ marginBottom: '1rem' }}>
                  저작 맵 현황 <label>({Math.round(totalBytes / 1024 / 1024)}MB)</label>
                  <p style={{ fontSize: '16px', marginTop: '0.5rem' }}>
                    {Math.round(limitMb - totalBytes / 1024 / 1024)}MB 사용 가능
                  </p>
                </h3>
                <ProgressBar
                  completed={totalBytes / 1024 / 1024}
                  bgColor={'#2185d0'}
                  maxCompleted={limitMb}
                  isLabelVisible={false}
                  animateOnRender={true}
                />
              </div>
              <div>
                <h3 style={{ marginBottom: '1rem' }}>
                  학급 수 <label>({classList.length}개)</label>
                  <p style={{ fontSize: '16px', marginTop: '0.5rem' }}>
                    {limitClassCount - classList.length}개 학급 생성 가능
                  </p>
                </h3>
                <ProgressBar
                  completed={classList.length}
                  bgColor={'#2185d0'}
                  maxCompleted={limitClassCount}
                  isLabelVisible={false}
                  animateOnRender={true}
                />
              </div>
              <div style={{ marginTop: '1rem', color: 'red' }}>{extraCharge && <h3>추가요금 {extraCharge}원</h3>}</div>
            </Item.Content>
          </Item>
        </Item.Group>
      </Popup>
    </div>
  );
};
