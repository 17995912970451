import React from 'react';
import './AuthDashBoard.scss';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { AuthListContainer } from '../AuthList';
import { AuthListOptionsContainer } from '../AuthListOptions';
const AuthDashBoard = ({ tabList, currentTab, onClickTab, isSession }) => {
  if (!isSession) return (window.location = '/');
  return (
    <DashBoardTemplate title={'API 인증 키'} tabList={tabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<AuthListOptionsContainer />}>
          <AuthListContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default AuthDashBoard;
