import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Editor,
  Main,
  Members,
  Licenses,
  Notices,
  Payments,
  Sms,
  Email,
  Settings,
  Promotions,
  Programs,
  Auths,
  Maps,
  Admins,
  LicenseCodes,
  Manuals,
  Students,
  Professors
} from '../pages';

import { inject, observer } from 'mobx-react';
import Nursings from '../pages/Nursing';

@inject('session')
@observer
class App extends Component {
  componentDidMount() {
    const { session } = this.props;
    session.loadSession();
  }
  render() {
    return (
      <Switch>
        <Route exact path='/' component={Main} />
        <Route path='/editor' component={Editor} />
        <Route path='/members' component={Members} />
        <Route path='/licenses' component={Licenses} />
        <Route path='/notices' component={Notices} />
        <Route path='/payments' component={Payments} />
        <Route path='/sms' component={Sms} />
        <Route path='/email' component={Email} />
        <Route path='/settings' component={Settings} />
        <Route path='/promotions' component={Promotions} />
        <Route path='/programs' component={Programs} />
        <Route path='/auths' component={Auths} />
        <Route path='/maps' component={Maps} />
        <Route path='/admins' component={Admins} />
        <Route path='/license-codes' component={LicenseCodes} />
        <Route path='/manuals' component={Manuals} />
        <Route path='/nursing' component={Nursings} />
        <Route path='/students' component={Students} />
        <Route path='/professors' component={Professors} />
      </Switch>
    );
  }
}

export default App;
