import React from 'react';
import HTMLRenderer from 'react-html-renderer';
import { NewPopup } from '../../common/NewPopup';
import './EmailPreview.scss';

const EmailPreview = ({
  title,
  body,
  senderAddress,
  emailType,
  receiverList,
  carbonCopyList,
  isShown,
  onTogglePopup,
  onClickSendEmail
}) => {
  if (!isShown) return null;
  return (
    <div className='EmailPreview'>
      <NewPopup
        title='이메일 미리보기'
        onTogglePopup={onTogglePopup}
        buttons={
          <button className='email-send-btn' onClick={onClickSendEmail}>
            보내기
          </button>
        }
      >
        <div className='email-preview-info-wrap'>
          <div className='email-preview-section'>
            <label>보내는 사람</label>
            <p>{senderAddress}</p>
          </div>
          <div className='email-preview-section'>
            <label>받는 사람</label>
            <div className='preview-receiver-list'>
              {receiverList.map(receiverEmail => (
                <div className='preview-receiver-item'>{receiverEmail}</div>
              ))}
            </div>
          </div>
          <div className='email-preview-section'>
            <label>참조</label>
            <div className='preview-carbon-copy-list'>
              {carbonCopyList.map(carbonCopyEmail => (
                <div className='preview-carbon-copy-item'>{carbonCopyEmail}</div>
              ))}
            </div>
          </div>
          <div className='email-preview-section'>
            <label>제목</label>
            <p>{title}</p>
          </div>
        </div>
        <div className='email-preview-body'>
          <HTMLRenderer html={emailBodyParser(emailType, body)} />
        </div>
      </NewPopup>
    </div>
  );
};

const emailBodyParser = (emailType, body) => {
  if (emailType.value === '3' || emailType.value === '4') {
    //광고 메일이면
    return `${body}<br><br><div align="left" style="color: #4C4C4C; font-size: 12px; font-family:'돋움','helvetica'; padding : 10px; text-align: left; border:1px solid #d4d4d4; background-color: #F1F1F1;">
    메일 수신을 원치 않으시면 ##BLOCK_RECEIVER_LINK##를 클릭하세요.<br>
    If you do not want to receive it, please click a ##EN_BLOCK_RECEIVER_LINK##
</div>
    `;
  }
  //광고 메일이 아니면
  return `<div
  style="height: 100%;
margin: 0;
padding: 0;
width: 100%;
background-color: #fafafa;"
>
  <table
  width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#FAFAFA" role="presentation
    style="table-layout: fixed;border:0;text-align:center;border-collapse:collapse;border-spacing:0"
  >
    <tbody>
      <tr>
        <td style="font-size: 0px;"></td>
        <td style="text-align:left">
          <div style="max-width:600px;margin:0 auto;padding:0 40px;background-color: #fff;">
            <table width="600" border="0" cellspacing="0" cellpadding="0" align="center" style="width:100%;border:0;margin:0 auto;border-collapse:collapse;border-spacing:0;word-break:break-all;">
              <tbody>
                <tr>
                  <td style="height:40px"></td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                  <img
                  src="https://api-storage.cloud.toast.com/v1/AUTH_23d1dda527114675bca9b5d7d4f29f6e/images/notification-email/%EF%BF%BD.png"
                  alt="VRWARE"
                  style="border:0;vertical-align:top;max-width: 600px;height: auto;width: auto;display: block;"
                />
                  </td>
                </tr>
                <tr>
                  <td style="height:20px;padding:0;border-bottom:3px solid #007edf;"></td>
                </tr>
                <tr>
                  <td style="text-align: left; height: 32px; padding: 0px;"></td>
                </tr>
                <tr>
                  <td
                    style='text-align: left; padding: 0px; font-family: "Noto Sans KR", 나눔고딕, NanumGothic, 맑은고딕, "Malgun Gothic", 돋움, Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif; font-size: 22px; font-weight: normal; color: rgb(17, 17, 17); line-height: 33px;'
                  >
                    안녕하세요. VRWARE 입니다.
                  </td>
                </tr>
                <tr>
                  <td style="height:16px;padding:0">&nbsp;</td>
                </tr>
                <tr>
                  <td
                    align="center"
                    width="600"
                    style="font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:14px;color:#555555;line-height:26px;font-weight:normal"
                  >
                    <div style="text-align: left;">
                      <span>
                        VRWARE를 이용해주시는 고객님께 항상 감사의 말씀드립니다.
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; height: 16px; padding: 0px;"></td>
                </tr>
                <tr>
                  <td
                    style="font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:14px;color:#555555;line-height:26px;font-weight:normal"
                  >
                    <div style="text-align: left;">${body}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; height: 32px; padding: 0px;"></td>
                </tr>
                <tr>
                  <td style="height:16px;padding:0"></td>
                </tr>
                <tr>
                <td  style="font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:12px;color:#555555;line-height:26px;font-weight:normal">
                  <div style="background: #eee;padding: 10px;">궁금하신 사항은 <a href="https://my.vrware.us/cs" target="_sub">고객센터</a>로 문의해 주세요.</div>
                </td>
              </tr>
              <tr>
                <td style="height:16px;padding:0"></td>
              </tr>
                <tr>
                  <td
                    style="padding:0;font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:12px;line-height:14px;color:#999;text-align:left;"
                  >
                    ©Globepoint Inc. All rights reserved.
                  </td>
                </tr>
                <tr>
                  <td style="height:60px;padding:0">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
        <td style="font-size: 0px;"></td>
      </tr>
    </tbody>
  </table>
</div>`;
};

export default EmailPreview;
