import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import SmsDeliveryContainer from '../SmsDelivery/SmsDeliveryContainer';
import { SmsSentHistoryOptionContainer } from '../SmsSentHistoryOption';
import { SmsSentHistoryListContainer } from '../SmsSentHistoryList';
import { SmsSentHistoryDetailPopupContainer } from '../SmsSentHistoryDetailPopup';
import { SmsSentHistoryListPaginationContainer } from '../SmsSentHistoryListPagination';

const SmsDashBoard = ({ smsTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'SMS'} tabList={smsTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'send' && (
        <DashBoardBody>
          <SmsDeliveryContainer />
        </DashBoardBody>
      )}
      {currentTab === 'sent-sms-list' && (
        <DashBoardBody option={<SmsSentHistoryOptionContainer />}>
          <SmsSentHistoryListContainer />
          <SmsSentHistoryListPaginationContainer />
          <SmsSentHistoryDetailPopupContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default SmsDashBoard;
