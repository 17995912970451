import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import Root from './Root';
import { Provider } from 'mobx-react';
import {
  base,
  session,
  licenses,
  notices,
  payments,
  email,
  editor,
  members,
  sms,
  memberAdd,
  settings,
  promotions,
  programs,
  auths,
  maps,
  admins,
  licenseCodes,
  manuals,
  nursing,
  students,
  studentAdd,
  professors,
  professorsAdd
} from './stores';

ReactDOM.render(
  <Provider
    editor={editor}
    session={session}
    members={members}
    base={base}
    licenses={licenses}
    notices={notices}
    payments={payments}
    sms={sms}
    email={email}
    memberAdd={memberAdd}
    settings={settings}
    promotions={promotions}
    programs={programs}
    auths={auths}
    maps={maps}
    admins={admins}
    licenseCodes={licenseCodes}
    manuals={manuals}
    nursing={nursing}
    students={students}
    studentAdd={studentAdd}
    professors={professors}
    professorsAdd={professorsAdd}
  >
    <Root />
  </Provider>,
  document.getElementById('vrware-admin-web')
);
