import React, { Component } from 'react';
import EmailReceiverList from './EmailReceiverList';
import { inject, observer } from 'mobx-react';

@inject('email')
@observer
class EmailReceiverListContainer extends Component {
  render() {
    const { email } = this.props;
    return (
      <React.Fragment>
        <EmailReceiverList receiverList={email.getReceiverList} onDeleteReceiver={email.onDeleteReceiver} />
      </React.Fragment>
    );
  }
}

export default EmailReceiverListContainer;
