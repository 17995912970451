import React, { Component } from 'react';
import EmailDelivery from './EmailDelivery';
import { inject, observer } from 'mobx-react';
@inject('email')
@observer
class EmailDeliveryContainer extends Component {
  render() {
    const { email } = this.props;

    return (
      <React.Fragment>
        <EmailDelivery
          onChangeBody={email.onChangeBody}
          onChangeEmailType={email.onChangeEmailType}
          onChangeRequestDate={email.onChangeRequestDate}
          onChangeSenderAddress={email.onChangeSenderAddress}
          onChangeTitle={email.onChangeTitle}
          onInsertReceiver={email.onInsertReceiver}
          onInsertCarbonCopy={email.onInsertCarbonCopy}
          onClickClear={email.onClickClear}
          onClickSendEmail={email.onClickSendEmail}
          requestDate={email.getRequestDate}
          emailType={email.getEmailType}
          senderAddress={email.getSenderAddress}
          onTogglePopup={email.onToggleEmailPreview}
        />
      </React.Fragment>
    );
  }
}

export default EmailDeliveryContainer;
