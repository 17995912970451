import { action, observable, computed } from 'mobx';
import { VrwareApi } from '../lib/api';

class LicensesStore {
  @observable licenseList = [];
  @observable licenseListTotalCount = 0;
  @observable licenseGrade = '';
  @observable licenseType = '';
  @observable email = '';
  @observable currentPage = 1;
  @observable offset = 0;
  @observable limit = 10;
  @observable currentTab = 'list';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };

  @computed
  get getCurrentTab() {
    return this.currentTab;
  }

  @action
  onChangePage = page => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    VrwareApi.get(
      `/licenses?limit=${this.limit}&offset=${this.offset}&licenseGrade=${
        this.licenseGrade !== 'all' ? this.licenseGrade : ''
      }&licenseType=${this.licenseType !== 'all' ? this.licenseType : ''}&email=${this.email}`
    )
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.licenseList = res.data.data.licenseList;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.licenseList = [];
          this.licenseListTotalCount = 0;
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickSearchLicenses = () => {
    VrwareApi.get(
      `/licenses?limit=${this.limit}&offset=${this.offset}&licenseGrade=${
        this.licenseGrade !== 'all' ? this.licenseGrade : ''
      }&licenseType=${this.licenseType !== 'all' ? this.licenseType : ''}&email=${this.email}`
    )
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.licenseList = res.data.data.licenseList;
            this.licenseListTotalCount = res.data.data.licenseListTotalCount;
            this.offset += this.limit;
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.licenseList = [];
            this.licenseListTotalCount = 0;
          }
        })
      );
  };

  @action
  onClickSearch = () => {
    this.clearPagination();
    this.onClickSearchLicenses();
  };

  @action
  onEnterSearch = e => {
    if (e.key === 'Enter') {
      this.onClickSearch();
    }
  };

  @action
  onChangeLimit = limit => {
    this.limit = limit;
    this.clearPagination();
    this.loadLicenseList();
  };

  @action
  onChangeEmail = email => {
    this.email = email;
  };

  @action
  onChangeLicenseGrade = licenseGrade => {
    this.licenseGrade = licenseGrade;
    this.clearPagination();
    this.loadLicenseList();
  };

  @action
  onChangeLicenseType = licenseType => {
    this.licenseType = licenseType;
    this.clearPagination();
    this.loadLicenseList();
  };

  @action
  clearPagination = () => {
    this.offset = 0;
    this.currentPage = 1;
    this.licenseList = [];
    this.licenseListTotalCount = 0;
  };

  @computed
  get getLicenseList() {
    return this.licenseList;
  }

  @computed
  get getLicenseListTotalCount() {
    return this.licenseListTotalCount;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getLimit() {
    return this.limit;
  }
}

export default new LicensesStore();
