import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { MemberListOptionsContainer } from '../MemberListOptions';
import { MemberAddOptionsContainer } from '../MemberAddOptions';
import { MemberListContainer } from '../MemberList';
import { MemberListPaginationContainer } from '../MemberListPagination';

const MemberDashBoard = ({ memberTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'회원'} tabList={memberTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<MemberListOptionsContainer />}>
          <MemberListContainer />
          <MemberListPaginationContainer />
        </DashBoardBody>
      )}
      {currentTab === 'add' && <DashBoardBody option={<MemberAddOptionsContainer />}></DashBoardBody>}
    </DashBoardTemplate>
  );
};

export default MemberDashBoard;
