import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import EmailDeliveryContainer from '../EmailDelivery/EmailDeliveryContainer';
import { EmailSentHistoryOptionContainer } from '../EmailSentHistoryOption';
import { EmailSentHistoryListContainer } from '../EmailSentHistoryList';
import EmailPreviewContainer from '../EmailPreview/EmailPreviewContainer';
import { EmailSentHistoryListPaginationContainer } from '../EmailSentHistoryListPagination';

const EmailDashBoard = ({ emailTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'Email'} tabList={emailTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'send' && (
        <DashBoardBody>
          <EmailDeliveryContainer />
          <EmailPreviewContainer />
        </DashBoardBody>
      )}
      {currentTab === 'sent-email-list' && (
        <DashBoardBody option={<EmailSentHistoryOptionContainer />}>
          <EmailSentHistoryListContainer />
          <EmailSentHistoryListPaginationContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default EmailDashBoard;
