import React from 'react';
import './LicenseListPagination.scss';
import { Pagination } from '../../common/Pagination';
const LicenseListPagination = ({ onChangePage, currentPage, limit, licenseListTotalCount }) => {
  return (
    <div className='LicenseListPagination'>
      <Pagination
        onChangePage={onChangePage}
        currentPage={currentPage}
        totalPages={Math.floor(licenseListTotalCount / limit)}
      ></Pagination>
    </div>
  );
};

export default LicenseListPagination;
