import React from 'react';
import './AuthListOptions.scss';
import { Button, Icon, Input, Dropdown } from 'semantic-ui-react';
import { LabelWithNode } from '../../common/LabelWithNode';
const AuthListOptions = ({
  onClickSearchAuth,
  onClickSearchClear,
  onEnterSearch,
  onChangeSearchProgramName,
  searchProgramPlatform,
  onChangeSearchProgramPlatform
}) => {
  return (
    <div className='AuthListOptions'>
      <div className='auth-list-options-section'>
        <LabelWithNode
          label={'프로그램 이름'}
          node={
            <Input
              id='auth-search-name'
              type='text'
              placeholder={'프로그램 이름을 입력해 주세요.'}
              onChange={e => onChangeSearchProgramName(e.target.value)}
              onKeyPress={onEnterSearch}
            />
          }
        />
      </div>
      <div className='auth-list-options-section'>
        <LabelWithNode
          label={'프로그램 플랫폼'}
          node={
            <Dropdown
              placeholder='Select Platform'
              fluid
              search
              selection
              clearable
              value={searchProgramPlatform}
              options={platformOptions}
              id='auth-search-platform'
              onChange={(e, { value }) => onChangeSearchProgramPlatform(value)}
            />
          }
        />
      </div>
      <div className='auth-list-options-section'>
        <div className='auth-list-options-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickSearchAuth} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickSearchClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

const platformOptions = [
  { key: 'windows', value: 'windows', icon: 'windows', text: 'Windows' },
  { key: 'android', value: 'android', icon: 'android', text: 'Android' },
  { key: 'macOS', value: 'macOS', icon: 'apple', text: 'macOS' },
  { key: 'iOS', value: 'iOS', icon: 'apple', text: 'iOS' },
  { key: 'web', value: 'web', icon: 'chrome', text: 'Web' }
];

export default AuthListOptions;
