import React from 'react';
import './ProfessorItem.scss';

import { dateFormat } from '../../../lib/common/dateFormat';
import { Table } from 'semantic-ui-react';
const ProfessorItem = ({ index, professor, onToggleMemberDetail }) => {
  return (
    <Table.Row className='MemberItem'>
      <Table.Cell>{index + 1}</Table.Cell>
      
      <Table.Cell>{professor.name}</Table.Cell>
      <Table.Cell>{professor.aid}</Table.Cell>
      <Table.Cell>{professor.school}</Table.Cell>
      <Table.Cell>{dateFormat(professor.createDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      
    </Table.Row>
  );
};

export default ProfessorItem;
