import React from 'react';
import './LicenseListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Select, Input, Button, Icon } from 'semantic-ui-react';

const LicenseListOptions = ({
  onChangeLimit,
  onChangeLicenseGrade,
  onChangeLicenseType,
  onChangeEmail,
  onClickSearch,
  onEnterSearch,
}) => {
  const listCount = [
    {
      text: '5',
      value: 5,
    },
    {
      text: '10',
      value: 10,
    },
    {
      text: '15',
      value: 15,
    },
    {
      text: '20',
      value: 20,
    },
  ];
  const licenseGrade = [
    { text: '전체', value: 'all' },
    { text: 'Lite', value: 'lite' },
    { text: 'Home', value: 'home' },
    { text: 'Tutor', value: 'tutor' },
    { text: 'Lab', value: 'lab' },
    { text: 'Class', value: 'class' },
    {
      text: 'Academy',
      value: 'academy',
    },
  ];
  const licenseType = [
    { text: '전체', value: 'all' },
    { text: 'School', value: 'school' },
    { text: 'PhotoZone', value: 'photozone' },
    { text: 'Antz', value: 'antz' },
  ];
  return (
    <div className='LicenseListOptions'>
      <div className='license-list-options-section'>
        <LabelWithNode
          label={'목록 수'}
          node={
            <Select
              id='license-limit'
              defaultValue={10}
              options={listCount}
              onChange={(e, { value }) => onChangeLimit(value)}
              fluid
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='license-list-options-section'>
        <LabelWithNode
          label={'라이선스 등급'}
          node={
            <Select
              id='license-grade'
              defaultValue={'all'}
              options={licenseGrade}
              onChange={(e, { value }) => onChangeLicenseGrade(value)}
              fluid
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='license-list-options-section'>
        <LabelWithNode
          label={'라이선스 타입'}
          node={
            <Select
              id='license-type'
              defaultValue={'all'}
              options={licenseType}
              onChange={(e, { value }) => onChangeLicenseType(value)}
              fluid
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='license-list-options-section'>
        <LabelWithNode
          label={'이메일 검색'}
          node={
            <Input
              id='license-email'
              placeholder={'이메일을 입력해주세요.'}
              onChange={(e) => onChangeEmail(e.target.value)}
              onKeyPress={onEnterSearch}
            ></Input>
          }
        ></LabelWithNode>
      </div>
      <div className='license-list-options-section'>
        <div className='license-list-options-btns'>
          <Button color={'blue'} onClick={onClickSearch} icon labelPosition='right'>
            검색
            <Icon name='search' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LicenseListOptions;
