import React from 'react';
import './MapDetailPopup.scss';
import { Image, Header, Button, Placeholder } from 'semantic-ui-react';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Popup } from '../../common/Popup';

const MapDetailPopup = ({
  mapDetail,
  isOpen,
  onTogglePopup,
  onClickMapShare,
  onClickReleaseShareMap,
  isLoading,
  onClickReleaseGpShareMap,
  onClickGpShare
}) => {
  return (
    <Popup
      title={'Map Detail'}
      isOpen={isOpen}
      onTogglePopup={onTogglePopup}
      btns={
        <React.Fragment>
          {mapDetail.isGpShared && (
            <Button negative onClick={() => onClickReleaseGpShareMap(mapDetail.map_id)}>
              GP 공유 맵 해제
            </Button>
          )}
          {!mapDetail.isGpShared && (
            <Button color='orange' onClick={() => onClickGpShare(mapDetail.map_id)}>
              GP 맵으로 공유하기
            </Button>
          )}
          {mapDetail.shareState && (
            <Button negative onClick={() => onClickReleaseShareMap(mapDetail.map_id)}>
              공유 맵 해제
            </Button>
          )}
          {!mapDetail.shareState && (
            <Button positive onClick={() => onClickMapShare(mapDetail.map_id)}>
              맵 공유하기
            </Button>
          )}
        </React.Fragment>
      }
    >
      {!isLoading ? (
        <Placeholder style={{ height: '30rem', width: '100%' }}>
          <Placeholder.Image />
        </Placeholder>
      ) : (
        <Image src={mapDetail.imgThumbUrl} wrapped />
      )}

      <Header>{mapDetail.title}</Header>
      <p className='map-detail-desc'>{mapDetail.desc}</p>
      <div className='map-date-wrap'>
        <p className='map-detail-producer'>{mapDetail.producer && `by ${mapDetail.producer}`}</p>
        {'·'}
        <p className='map-detail-date'>{dateFormat(mapDetail.modDate, 'YYYY년 MM월 DD일에 만듦')}</p>
      </div>
    </Popup>
  );
};

export default MapDetailPopup;
