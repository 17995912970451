import React from 'react';
import './LicenseItem.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Table } from 'semantic-ui-react';

const LicenseItem = ({ license }) => {
  return (
    <Table.Row className='LicenseItem'>
      <Table.Cell>{license.email || license.memberId}</Table.Cell>
      <Table.Cell>{license.licenseGrade || 'lite'}</Table.Cell>
      <Table.Cell>{license.type}</Table.Cell>
      <Table.Cell>{dateFormat(license.purchaseDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell>{dateFormat(license.expireDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
    </Table.Row>
  );
};

export default LicenseItem;
