import React from 'react';
import './MemberListPagination.scss';
import { Pagination } from '../../common/Pagination';

const MemberListPagination = ({ memberListTotalCount, onChangePage, currentPage, limit }) => {
  return (
    <div className='MemberListPagination'>
      <Pagination
        onChangePage={onChangePage}
        currentPage={currentPage}
        totalPages={Math.ceil(memberListTotalCount / limit.value)}
      />
    </div>
  );
};

export default MemberListPagination;
