import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MemberDashBoard from './MemberDashBoard';

@inject('members', 'session')
@observer
class MemberDashBoardContainer extends Component {
  render() {
    const { members, session } = this.props;
    return (
      <React.Fragment>
        <MemberDashBoard
          memberTabList={memberTabList}
          currentTab={members.getCurrentTab}
          onClickTab={members.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const memberTabList = [
  {
    value: 'list',
    text: '회원 목록'
  },
  {
    value: 'add',
    text: '회원 등록'
  }
];

export default MemberDashBoardContainer;
