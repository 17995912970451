import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProfessorDashBoard from './ProfessorDashBoard';

@inject('professors', 'session')
@observer
class ProfessorDashBoardContainer extends Component {
  render() {
    const { professors, session } = this.props;
    return (
      <React.Fragment>
        <ProfessorDashBoard
          memberTabList={memberTabList}
          currentTab={professors.getCurrentTab}
          onClickTab={professors.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const memberTabList = [
  {
    value: 'list',
    text: '교수 목록'
  },
  {
    value: 'add',
    text: '교수 등록'
  }
];

export default ProfessorDashBoardContainer;
