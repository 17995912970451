import React from 'react';
import './ProgramList.scss';
import { Table, Icon } from 'semantic-ui-react';
import { Pagination } from '../../common/Pagination';
import { dateFormat } from '../../../lib/common/dateFormat';
const ProgramList = ({ programList, totalCount, currentPage, onChangePage }) => {
  if (!programList) return null;
  return (
    <div className='ProgramList'>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>프로그램 이름</Table.HeaderCell>
            <Table.HeaderCell>프로그램 플랫폼</Table.HeaderCell>
            <Table.HeaderCell>버전 목록</Table.HeaderCell>
            <Table.HeaderCell>등록 날짜</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {programList.map(programInfo => (
            <Table.Row>
              <Table.Cell>{programInfo.programName}</Table.Cell>
              <Table.Cell>
                <Icon name={getPlatformIcon(programInfo.programPlatform)} /> {programInfo.programPlatform}
              </Table.Cell>
              <Table.Cell>
                {programInfo.versions.map(versionInfo => (
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>버전</Table.HeaderCell>
                        <Table.HeaderCell>파일 URL</Table.HeaderCell>
                        <Table.HeaderCell>등록 날짜</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{versionInfo.version}</Table.Cell>
                        <Table.Cell>{versionInfo.programFileUrl}</Table.Cell>
                        <Table.Cell>{dateFormat(versionInfo.regDate, 'YYYY년 MM월 DD일')}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ))}
              </Table.Cell>
              <Table.Cell>{dateFormat(programInfo.regDate, 'YYYY년 MM월 DD일')}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination totalPages={totalCount} currentPage={currentPage} onChangePage={onChangePage} />
    </div>
  );
};

const getPlatformIcon = programPlatform => {
  if (programPlatform === 'windows') {
    return 'windows';
  } else if (programPlatform === 'macOs' || programPlatform === 'iOS') {
    return 'apple';
  } else if (programPlatform === 'android') {
    return 'android';
  } else if (programPlatform === 'web') {
    return 'chrome';
  }
};

export default ProgramList;
