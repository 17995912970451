import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { LicenseCodeListOptions } from '.';

@inject('licenseCodes')
@observer
class LicenseCodeListOptionsContainer extends Component {
  render() {
    const { licenseCodes } = this.props;
    return (
      <React.Fragment>
        <LicenseCodeListOptions
          onClickSearch={licenseCodes.onClickSearch}
          onEnterSearch={licenseCodes.onEnterSearch}
          onClickClear={licenseCodes.onClickSearchDataClear}
          onChangeSearchLicenseCode={licenseCodes.onChangeSearchLicenseCode}
          onChangeSearchLicenseType={licenseCodes.onChangeSearchLicenseType}
          onChangeSearchLicenseGrade={licenseCodes.onChangeSearchLicenseGrade}
          onChangeSearchStartDate={licenseCodes.onChangeSearchStartDate}
          onChangeSearchEndDate={licenseCodes.onChangeSearchEndDate}
          searchLicenseType={licenseCodes.getSearchLicenseType}
          searchLicenseGrade={licenseCodes.getSearchLicenseGrade}
          searchStartDate={licenseCodes.getSearchStartDate}
          searchEndDate={licenseCodes.getSearchEndDate}
          onToggleSearchDate={licenseCodes.onToggleSearchDate}
          isSearchDate={licenseCodes.getIsSearchDate}
        />
      </React.Fragment>
    );
  }
}

export default LicenseCodeListOptionsContainer;
