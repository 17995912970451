import React, { Component } from 'react';
import PromotionListOptions from './PromotionListOptions';
import { inject, observer } from 'mobx-react';

@inject('promotions')
@observer
class PromotionListOptionsContainer extends Component {
  render() {
    const { promotions } = this.props;

    return (
      <React.Fragment>
        <PromotionListOptions
          onClickSearch={promotions.onClickSearchPromotions}
          onEnterSearch={promotions.onEnterSearch}
          onChangeSearchIssuer={promotions.onChangeSearchIssuer}
          onChangeSearchPromotionCode={promotions.onChangeSearchPromotionCode}
          onClickSearchClear={promotions.onClickSearchClear}
        />
      </React.Fragment>
    );
  }
}

export default PromotionListOptionsContainer;
