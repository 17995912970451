import React, { Component } from 'react';
import Header from './Header';
import { inject, observer } from 'mobx-react';

@inject('base', 'session')
@observer
class HeaderContainer extends Component {
  render() {
    const { session } = this.props;
    return (
      <React.Fragment>
        <Header isSession={session.isSession} />
      </React.Fragment>
    );
  }
}

export default HeaderContainer;
