import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PromotionDetailPopup from './PromotionDetailPopup';

@inject('promotions')
@observer
class PromotionDetailPopupContainer extends Component {
  render() {
    const { promotions } = this.props;

    return (
      <React.Fragment>
        <PromotionDetailPopup
          onTogglePromotionDetail={promotions.onTogglePromotionDetail}
          onToggleUpdatePromotion={promotions.onToggleUpdatePromotion}
          onClickDeletePromotion={promotions.onClickDeletePromotion}
          isShown={promotions.getIsShownPromotionDetail}
          promotionInfo={promotions.getPromotionDetail}
        />
      </React.Fragment>
    );
  }
}

export default PromotionDetailPopupContainer;
