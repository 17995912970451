import React from 'react';
import './LeftDashBoard.scss';
import { SidebarMenuContainer } from '../SidebarMenu';

const LeftDashBoard = () => {
  return (
    <div className='LeftDashBoard'>
      <SidebarMenuContainer />
    </div>
  );
};

export default LeftDashBoard;
