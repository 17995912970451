import React, { Component } from 'react';
import AdminList from './AdminList';
import { inject, observer } from 'mobx-react';

@inject('admins')
@observer
class AdminListContainer extends Component {
  render() {
    const { admins } = this.props;
    return (
      <React.Fragment>
        <AdminList
          adminList={admins.getAdminList}
          currentPage={admins.getCurrentPage}
          totalPages={admins.getTotalPages}
          onChangePage={admins.onChangeAdminListPage}
          onClickReleaseAdmin={admins.onClickReleaseAdmin}
        />
      </React.Fragment>
    );
  }
}

export default AdminListContainer;
