import React from 'react';
import './PaymentDetailPopup.scss';
import { NewPopup } from '../../common/NewPopup';
import { dateFormat } from '../../../lib/common/dateFormat';

const PaymentDetailPopup = ({ isShown, onTogglePopup, paymentInfo }) => {
  if (!isShown) return null;
  return (
    <div className='PaymentDetailPopup'>
      <NewPopup title={'결제 상세 조회'} onTogglePopup={onTogglePopup} paymentInfo={paymentInfo}>
        <div className='payment-info-wrap'>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제 ID</div>
              <div className='payment-info-body'>{paymentInfo.paymentId}</div>
            </div>
            <div className='payment-info-box'>
              <div className='payment-info-header'>구매자</div>
              <div className='payment-info-body'>{paymentInfo.email}</div>
            </div>
          </div>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제번호</div>
              <div className='payment-info-body'>{paymentInfo.paymentNum}</div>
            </div>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제 일시</div>
              <div className='payment-info-body'>
                {dateFormat(paymentInfo.paymentDate, 'YYYY년 MM월 DD일 HH시 mm분 ss초')}
              </div>
            </div>
          </div>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제 상품 정보</div>
              <div className='payment-info-body'>{paymentInfo.productInfo.map((product) => product.productName)}</div>
            </div>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제 금액</div>
              <div className='payment-info-body'>{_numberWithCommas(paymentInfo.price)}</div>
            </div>
          </div>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제 방법</div>
              <div className='payment-info-body'>{paymentInfo.paymentGroup}</div>
            </div>
            <div className='payment-info-box'>
              <div className='payment-info-header'>결제 타입</div>
              <div className='payment-info-body'>{paymentInfo.paymentType}</div>
            </div>
          </div>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>카드 정보</div>
              <div className='payment-info-body'>{paymentInfo.cardNo}</div>
            </div>
            <div className='payment-info-box'>
              <div className='payment-info-header'>할부 정보</div>
              <div className='payment-info-body'>
                {paymentInfo.cardQuota === '00' ? '일시불' : paymentInfo.cardQuota + '개월'}
              </div>
            </div>
          </div>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>영수증</div>
              <div className='payment-info-body'>
                {
                  <a href={paymentInfo.receiptUrl} target='_sub'>
                    <button>보기</button>
                  </a>
                }
              </div>
            </div>
          </div>
          <div className='payment-info-section'>
            <div className='payment-info-box'>
              <div className='payment-info-header'>프로모션 정보</div>
              <div className='payment-info-body'>
                {paymentInfo.promotionInfo[0] ? (
                  <React.Fragment>
                    <div className='payment-info-body-section'>
                      <div className='payment-info-body-box'>
                        <label>프로모션 코드</label>
                        <p>{paymentInfo.promotionInfo[0].promotionCode}</p>
                      </div>
                      <div className='payment-info-body-box'>
                        <label>발급자</label>
                        <p>{paymentInfo.promotionInfo[0].issuer}</p>
                      </div>
                    </div>
                    <div className='payment-info-body-section'>
                      <div className='payment-info-body-box'>
                        <label>할인율</label>
                        <p>{paymentInfo.promotionInfo[0].dcRate}%</p>
                      </div>
                      <div className='payment-info-body-box'>
                        <label>유효기간</label>
                        <p>{dateFormat(paymentInfo.promotionInfo[0].expireDate, 'YYYY년 MM월 DD일 HH시 mm분')}</p>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  '프로모션 정보가 없습니다.'
                )}
              </div>
            </div>
          </div>
        </div>
      </NewPopup>
    </div>
  );
};

const _numberWithCommas = (number) => {
  return String(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default PaymentDetailPopup;
