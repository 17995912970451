import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { ManualListOptionsContainer } from '../ManualListOptions';
import { ManualListContainer } from '../ManualList';

const ManualDashBoard = ({ tabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'매뉴얼'} tabList={tabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<ManualListOptionsContainer />}>
          <ManualListContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default ManualDashBoard;
