import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { StudentListOptionsContainer } from '../StudentListOptions';
import { StudentAddOptionsContainer } from '../StudentAddOptions';
import { StudentListContainer } from '../StudentList';
import { StudentListPaginationContainer } from '../StudentListPagination';

const StudentDashBoard = ({ memberTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'학생'} tabList={memberTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<StudentListOptionsContainer />}>
          <StudentListContainer />
          <StudentListPaginationContainer />
        </DashBoardBody>
      )}
      {currentTab === 'add' && <DashBoardBody option={<StudentAddOptionsContainer />}></DashBoardBody>}
    </DashBoardTemplate>
  );
};

export default StudentDashBoard;
