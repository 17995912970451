import React, { Component } from 'react';
import PaymentDashBoard from './PaymentDashBoard';
import { inject, observer } from 'mobx-react';

@inject('payments', 'session')
@observer
class PaymentDashBoardContainer extends Component {
  render() {
    const { payments, session } = this.props;
    return (
      <React.Fragment>
        <PaymentDashBoard
          paymentTabList={paymentTabList}
          currentTab={payments.getCurrentTab}
          onClickTab={payments.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const paymentTabList = [
  {
    value: 'list',
    text: '결제 목록'
  }
];

export default PaymentDashBoardContainer;
