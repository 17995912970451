import React from 'react';
import './LicenseCodeListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button, Icon, Select, Checkbox } from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';

const LicenseCodeListOptions = ({
  onClickSearch,
  onEnterSearch,
  onClickClear,
  onChangeSearchLicenseCode,
  onChangeSearchLicenseType,
  onChangeSearchLicenseGrade,
  onChangeSearchStartDate,
  onChangeSearchEndDate,
  onToggleSearchDate,
  searchLicenseType,
  searchLicenseGrade,
  searchStartDate,
  searchEndDate,
  isSearchDate,
}) => {
  return (
    <div className='LicenseCodeListOptions'>
      <div className='license-code-list-options-section'>
        <LabelWithNode
          label={'라이선스 코드'}
          node={
            <Input
              id='search-license-code'
              fluid
              placeholder={'검색하실 라이선스 코드를 입력해 주세요.'}
              onKeyPress={onEnterSearch}
              onChange={(e) => onChangeSearchLicenseCode(e.target.value)}
              maxLength={8}
            />
          }
        />
      </div>
      <div className='license-code-list-options-section'>
        <LabelWithNode
          label='라이선스 유형'
          node={
            <Select
              id='search-license-type'
              value={searchLicenseType}
              placeholder='라이선스 유형을 선택해 주세요.'
              options={licenseTypeList}
              onChange={(e, { value }) => onChangeSearchLicenseType(value)}
              fluid
            ></Select>
          }
        ></LabelWithNode>
      </div>
      {searchLicenseType && (
        <div className='license-code-list-options-section'>
          <LabelWithNode
            label='라이선스 등급'
            node={
              <Select
                id='search-license-grade'
                value={searchLicenseGrade}
                placeholder='라이선스 등급을 선택해 주세요.'
                options={licenseGradeList.find((license) => license.type === searchLicenseType).gradeList}
                onChange={(e, { value }) => onChangeSearchLicenseGrade(value)}
                fluid
              ></Select>
            }
          ></LabelWithNode>
        </div>
      )}
      <div>
        <LabelWithNode
          node={
            <Checkbox
              checked={isSearchDate}
              label='라이선스 코드 사용 기간으로 검색'
              onClick={(e, { checked }) => onToggleSearchDate(checked)}
            />
          }
        ></LabelWithNode>
      </div>
      {isSearchDate && (
        <>
          <div className='license-code-list-options-section'>
            <LabelWithNode
              label='라이선스 코드 사용 가능일'
              node={<DateTimePicker onChange={onChangeSearchStartDate} value={new Date(searchStartDate)} />}
            ></LabelWithNode>
          </div>
          <div className='license-code-list-options-section'>
            <LabelWithNode
              label='라이선스 코드 사용 만료일'
              node={<DateTimePicker onChange={onChangeSearchEndDate} value={new Date(searchEndDate)} />}
            ></LabelWithNode>
          </div>
        </>
      )}
      <div className='license-code-list-options-section'>
        <div className='license-code-list-options-btns'>
          <Button color='blue' icon labelPosition='right' onClick={onClickSearch}>
            검색
            <Icon name='search' />
          </Button>
          <Button onClick={onClickClear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

const licenseTypeList = [{ value: 'school', text: 'VRWARE School' }];

const licenseGradeList = [
  {
    type: 'school',
    gradeList: [
      {
        text: 'Home',
        value: 'home',
      },
      {
        text: 'Tutor',
        value: 'tutor',
      },
      {
        text: 'Lab',
        value: 'lab',
      },
      {
        text: 'Class',
        value: 'class',
      },
      {
        text: 'Academy',
        value: 'academy',
      },
    ],
  },
];

export default LicenseCodeListOptions;
