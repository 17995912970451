import React from 'react';
import { EmailSentHistoryItem } from '../EmailSentHistoryItem';
import { observer } from 'mobx-react';
import './EmailSentHistoryList.scss';
import { Table } from 'semantic-ui-react';

const EmailSentHistoryList = ({ sentEmailList, onClickDetailEmail }) => {
  if (!sentEmailList.data) return null;
  return (
    <div className='EmailSentHistoryList'>
      {sentEmailList.totalCount ? <h4>총 {sentEmailList.totalCount || '0'} 건이 검색되었습니다.</h4> : null}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>요청 ID</Table.HeaderCell>
            <Table.HeaderCell>발송 메일</Table.HeaderCell>
            <Table.HeaderCell>수신 메일</Table.HeaderCell>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.HeaderCell>요청 상태</Table.HeaderCell>
            <Table.HeaderCell>발송 일시</Table.HeaderCell>
            <Table.HeaderCell>수신 일시</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sentEmailList.data.length ? (
            sentEmailList.data.map((emailInfo, i) => (
              <EmailSentHistoryItem key={i} emailInfo={emailInfo} onClickDetailEmail={onClickDetailEmail} />
            ))
          ) : (
            <EmailSentHistoryItem
              key={sentEmailList.data.requestId}
              emailInfo={sentEmailList.data}
              onClickDetailEmail={onClickDetailEmail}
            />
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default observer(EmailSentHistoryList);
