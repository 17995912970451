import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { LicenseListOptionsContainer } from '../LicenseListOptions';
import { LicenseListContainer } from '../LicenseList';
import { LicenseListPaginationContainer } from '../LicenseListPagination';
const LicenseDashBoard = ({ licenseTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'라이선스'} tabList={licenseTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<LicenseListOptionsContainer />}>
          <LicenseListContainer />
          <LicenseListPaginationContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default LicenseDashBoard;
