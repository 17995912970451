import React from 'react';
import './DashBoardBody.scss';
const DashBoardBody = ({ children, option }) => {
  return (
    <div className="DashBoardBody">
      {option && <div className="DashBoardOption">{option}</div>}
      <div className="DashBoardContent">{children}</div>
    </div>
  );
};

export default DashBoardBody;
