import React from 'react';
import { Link } from 'react-router-dom';
import './ManualItem.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Table, Button } from 'semantic-ui-react';
const ManualItem = ({ manualInfo, onDeleteManual }) => {
  return (
    <Table.Row className='ManualItem'>
      <Table.Cell>{manualInfo.title}</Table.Cell>
      <Table.Cell>{manualInfo.manualType === 'school' && 'VRWARE School'}</Table.Cell>
      <Table.Cell>
        {(manualInfo.manualTarget === 'student' && '학생/일반') || (manualInfo.manualTarget === 'teacher' && '강사')}
      </Table.Cell>
      <Table.Cell>{dateFormat(manualInfo.regDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell>{dateFormat(manualInfo.modDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell>
        <Link to={`editor?editor_type=manual&manual_id=${manualInfo.manualId}`}>
          <Button size='mini' positive>
            수정하기
          </Button>
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Button size='mini' negative onClick={() => onDeleteManual(manualInfo.manualId)}>
          삭제하기
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default ManualItem;
