import React, { Component } from 'react';
import SidebarMenu from './SidebarMenu';
import { inject, observer } from 'mobx-react';

@inject('base')
@observer
class SidebarMenuContainer extends Component {
  render() {
    const { base } = this.props;
    return (
      <React.Fragment>
        <SidebarMenu onClickSidebarMenu={base.onClickSidebarMenu} activeIndex={base.getActiveIndex} />
      </React.Fragment>
    );
  }
}

export default SidebarMenuContainer;
