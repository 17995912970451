import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { AdminDashBoardContainer } from '../components/admins/AdminDashBoard';
const Admins = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <AdminDashBoardContainer />
    </React.Fragment>
  );
};

export default Admins;
