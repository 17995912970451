import React, { Component } from 'react';
import { MemberDetailPopup } from '.';
import { inject, observer } from 'mobx-react';

@inject('members')
@observer
class MemberDetailPopupContainer extends Component {
  render() {
    const { members, onToggleShowUsage } = this.props;
    return (
      <React.Fragment>
        <MemberDetailPopup
          showMemberDetail={members.showMemberDetail}
          memberDetail={members.getMemberDetail}
          licenseDetail={members.getLicenseDetail}
          onToggleMemberDetail={members.onToggleMemberDetail}
          onToggleMemberUpdate={members.onToggleMemberUpdate}
          onToggleLicenseUpdate={members.onToggleLicenseUpdate}
          onToggleShowUsage={onToggleShowUsage}
        />
      </React.Fragment>
    );
  }
}

export default MemberDetailPopupContainer;
