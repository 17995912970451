import React, { Component } from 'react';
import EmailPreview from './EmailPreview';
import { inject, observer } from 'mobx-react';

@inject('email')
@observer
class EmailPreviewContainer extends Component {
  render() {
    const { email } = this.props;

    return (
      <React.Fragment>
        <EmailPreview
          title={email.getTitle}
          body={email.getBody}
          isShown={email.getIsShownPreviewPopup}
          onTogglePopup={email.onToggleEmailPreview}
          onClickSendEmail={email.onClickSendEmail}
          emailType={email.getEmailType}
          senderAddress={email.getSenderAddress}
          receiverList={email.getReceiverList}
          carbonCopyList={email.getCarbonCopyList}
        />
      </React.Fragment>
    );
  }
}

export default EmailPreviewContainer;
