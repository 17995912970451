import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ProgramList } from '.';

@inject('programs')
@observer
class ProgramListContainer extends Component {
  render() {
    const { programs } = this.props;
    return (
      <React.Fragment>
        <ProgramList
          programList={programs.getProgramList}
          totalCount={programs.getTotalCount}
          onChangePage={programs.onChangePage}
          currentPage={programs.getCurrentPage}
        />
      </React.Fragment>
    );
  }
}

export default ProgramListContainer;
