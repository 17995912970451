import React, { Component } from 'react';
import LicenseDashBoard from './LicenseDashBoard';
import { inject, observer } from 'mobx-react';

@inject('licenses', 'session')
@observer
class LicenseDashBoardContainer extends Component {
  render() {
    const { licenses, session } = this.props;
    return (
      <React.Fragment>
        <LicenseDashBoard
          licenseTabList={licenseTabList}
          currentTab={licenses.getCurrentTab}
          isSession={session.isSession}
          onClickTab={licenses.onClickTab}
        />
      </React.Fragment>
    );
  }
}

const licenseTabList = [
  {
    value: 'list',
    text: '라이선스 목록'
  }
];

export default LicenseDashBoardContainer;
