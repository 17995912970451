import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LicenseCodeRegister from './LicenseCodeRegister';

@inject('licenseCodes')
@observer
class LicenseCodeRegisterContainer extends Component {
  render() {
    const { licenseCodes } = this.props;
    return (
      <React.Fragment>
        <LicenseCodeRegister
          onChangeLicenseType={licenseCodes.onChangeAddLicenseType}
          onChangeLicenseGrade={licenseCodes.onChangeAddLicenseGrade}
          onChangeLicenseDate={licenseCodes.onChangeAddLicenseDate}
          onChangeStartDate={licenseCodes.onChangeAddStartDate}
          onChangeEndDate={licenseCodes.onChangeAddEndDate}
          onChangeUsageLimit={licenseCodes.onChangeAddUsageLimit}
          licenseType={licenseCodes.getAddLicenseType}
          licenseGrade={licenseCodes.getAddLicenseGrade}
          startDate={licenseCodes.getAddStartDate}
          endDate={licenseCodes.getAddEndDate}
          onClickRegister={licenseCodes.onClickAddLicenseCode}
          onClickClear={licenseCodes.onClickAddDataClear}
        />
      </React.Fragment>
    );
  }
}

export default LicenseCodeRegisterContainer;
