import React from 'react';
import './MemberUpdatePopup.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Item, Segment, Button, Select } from 'semantic-ui-react';
import { Popup } from '../../common/Popup';

const MemberUpdatePopup = ({
  onToggleMemberUpdate,
  showMemberUpdate,
  memberDetail,
  onClickMemberUpdate,
  onChangeSelectMemberType,
  onChangeSelectMemberState
}) => {
  const memberTypeList = [
    { key: '1', value: '1', text: '일반' },
    { key: '2', value: '2', text: '학생' },
    { key: '3', value: '3', text: '선생님' }
  ];
  const memberStateList = [
    { key: '1', value: '1', text: '정상' },
    { key: '2', value: '2', text: '휴면' },
    { key: '3', value: '3', text: '정지' },
    { key: '4', value: '4', text: '탈퇴' }
  ];
  return (
    <div className='MemberUpdatePopup'>
      <Popup
        title={'회원정보 수정'}
        onTogglePopup={onToggleMemberUpdate}
        isOpen={showMemberUpdate}
        btns={
          <Button positive onClick={() => onClickMemberUpdate(memberDetail.email)}>
            수정하기
          </Button>
        }
      >
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>이메일</Item.Header>
              <Item.Description>
                <Segment>{memberDetail.email}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>닉네임</Item.Header>
              <Item.Description>
                <Segment>{memberDetail.nickname}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>회원타입</Item.Header>
              <Item.Description>
                <Segment>
                  <Select
                    placeholder='Select member type'
                    options={memberTypeList}
                    defaultValue={memberDetail.memberType}
                    onChange={(e, { value }) => onChangeSelectMemberType(value)}
                  />
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>회원상태</Item.Header>
              <Item.Description>
                <Segment>
                  <Select
                    placeholder='Select member state'
                    options={memberStateList}
                    onChange={(e, { value }) => onChangeSelectMemberState(value)}
                    defaultValue={memberDetail.memberState}
                  />
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>가입날짜</Item.Header>
              <Item.Description>
                <Segment>{dateFormat(memberDetail.joinDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>최근 접속일</Item.Header>
              <Item.Description>
                <Segment>
                  {memberDetail.lastLoginDate
                    ? dateFormat(memberDetail.lastLoginDate, 'YYYY년 MM월 DD일 HH시 mm분')
                    : '없음'}
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Popup>
    </div>
  );
};

export default MemberUpdatePopup;
