import React from 'react';
import './Popup.scss';
import { Modal, Button } from 'semantic-ui-react';

const Popup = ({ title, isOpen, onTogglePopup, btns, children, ...rest }) => {
  if (!isOpen) return null;
  return (
    <div className='Popup'>
      <Modal dimmer={'blurring'} open={isOpen} onClose={onTogglePopup} {...rest}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>{children}</Modal.Content>
        <Modal.Actions>
          {btns}
          <Button primary onClick={onTogglePopup}>
            닫기
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default Popup;
