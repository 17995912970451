import React from 'react';
import './ProgramRegister.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Dropdown, Input, Button, Label } from 'semantic-ui-react';
const ProgramRegister = ({
  onChangeProgramName,
  onChangeProgramPlatform,
  onChangeProgramVersion,
  onChangeProgramFileUrl,
  onClickRegisterProgram,
  notifyProgramName,
  notifyProgramFileUrl,
  notifyProgramVersion,
  notifyProgramPlatform,
  onClickRegisterClear,
  programPlatform
}) => {
  return (
    <div className='ProgramRegister'>
      <div className='program-register-section'>
        <LabelWithNode
          label={'프로그램 이름'}
          node={
            <React.Fragment>
              <Input
                error={Boolean(notifyProgramName)}
                id='program-name'
                type='text'
                placeholder={'프로그램 이름을 입력해 주세요.'}
                onChange={e => onChangeProgramName(e.target.value)}
              />
              {notifyProgramName && (
                <Label basic color='red' pointing>
                  {notifyProgramName}
                </Label>
              )}
            </React.Fragment>
          }
        />
      </div>
      <div className='program-register-section'>
        <LabelWithNode
          label={'프로그램 플랫폼'}
          node={
            <>
              <Dropdown
                error={Boolean(notifyProgramPlatform)}
                placeholder='Select Platform'
                fluid
                search
                selection
                clearable
                value={programPlatform}
                options={platformOptions}
                id='program-platform'
                onChange={(e, { value }) => onChangeProgramPlatform(value)}
              />
              {notifyProgramPlatform && (
                <Label basic color='red' pointing>
                  {notifyProgramPlatform}
                </Label>
              )}
            </>
          }
        />
      </div>
      <div className='program-register-section'>
        <LabelWithNode
          label={'프로그램 버전'}
          node={
            <>
              <Input
                error={Boolean(notifyProgramVersion)}
                id='program-version'
                type='text'
                placeholder={'프로그램 버전을 입력해 주세요.'}
                onChange={e => onChangeProgramVersion(e.target.value)}
              />
              {notifyProgramVersion && (
                <Label basic color='red' pointing>
                  {notifyProgramVersion}
                </Label>
              )}
            </>
          }
        />
      </div>
      <div className='program-register-section'>
        <LabelWithNode
          label={'프로그램 다운로드 URL'}
          node={
            <>
              <Input
                error={Boolean(notifyProgramFileUrl)}
                id='program-file-url'
                type='text'
                placeholder={'프로그램 다운로드 URL을 입력해 주세요.'}
                onChange={e => onChangeProgramFileUrl(e.target.value)}
              />
              {notifyProgramFileUrl && (
                <Label basic color='red' pointing>
                  {notifyProgramFileUrl}
                </Label>
              )}
            </>
          }
        />
      </div>
      <div className='program-register-section'>
        <div className='program-register-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickRegisterProgram}>
              등록
            </Button>
            <Button.Or />
            <Button onClick={onClickRegisterClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

const platformOptions = [
  { key: 'windows', value: 'windows', icon: 'windows', text: 'Windows' },
  { key: 'android', value: 'android', icon: 'android', text: 'Android' },
  { key: 'macOS', value: 'macOS', icon: 'apple', text: 'macOS' },
  { key: 'iOS', value: 'iOS', icon: 'apple', text: 'iOS' },
  { key: 'web', value: 'web', icon: 'chrome', text: 'Web' }
];

export default ProgramRegister;
