import React from 'react';
import './AdminList.scss';
import { Table, Button } from 'semantic-ui-react';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Pagination } from '../../common/Pagination';

const AdminList = ({ adminList, totalPages, currentPage, onChangePage, onClickReleaseAdmin }) => {
  if (!adminList) return null;
  return (
    <div className='AdminList'>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>관리자 이메일</Table.HeaderCell>
            <Table.HeaderCell>관리자 닉네임</Table.HeaderCell>
            <Table.HeaderCell>관리자 등록일</Table.HeaderCell>
            <Table.HeaderCell>관리자 권한 해제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {adminList.map(adminInfo => (
            <Table.Row key={adminInfo.adminEmail}>
              <Table.Cell>{adminInfo.adminEmail}</Table.Cell>
              <Table.Cell>{adminInfo.adminNickname}</Table.Cell>
              <Table.Cell>{dateFormat(adminInfo.regDate, 'YYYY년 MM월 DD일')}</Table.Cell>
              <Table.Cell>
                <Button size='mini' negative onClick={() => onClickReleaseAdmin(adminInfo.adminEmail)}>
                  권한 해제하기
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} onChangePage={onChangePage} />
    </div>
  );
};

export default AdminList;
