import React, { Component } from 'react';
import MapList from './MapList';
import { inject, observer } from 'mobx-react';

@inject('maps')
@observer
class MapListContainer extends Component {
  render() {
    const { maps } = this.props;
    return (
      <React.Fragment>
        <MapList
          totalCount={maps.getTotalCount}
          mapList={maps.getMapList}
          onChangePage={maps.onChangeMapListPage}
          currentPage={maps.getCurrentPage}
          onClickMapDetail={maps.onClickMapDetail}
        />
      </React.Fragment>
    );
  }
}

export default MapListContainer;
