import React, { Component } from 'react';
import { ProfessorDetailPopup } from '.';
import { inject, observer } from 'mobx-react';

@inject('professors')
@observer
class ProfessorDetailPopupContainer extends Component {
  render() {
    const { professors, onToggleShowUsage } = this.props;
    return (
      <React.Fragment>
        <ProfessorDetailPopup
          showMemberDetail={professors.showMemberDetail}
          memberDetail={professors.getMemberDetail}
          licenseDetail={professors.getLicenseDetail}
          onToggleMemberDetail={professors.onToggleMemberDetail}
          onToggleMemberUpdate={professors.onToggleMemberUpdate}
          onToggleLicenseUpdate={professors.onToggleLicenseUpdate}
          onToggleShowUsage={onToggleShowUsage}
        />
      </React.Fragment>
    );
  }
}

export default ProfessorDetailPopupContainer;
