import { observable, action, computed } from 'mobx';
import { SchoolApi } from 'lib/api';
import exportFromJSON from 'export-from-json';
import { dateFormat } from '../lib/common/dateFormat';
class PaymentsStore {
  //#region 결제 탭
  @observable currentTab = 'list';

  @action
  onClickTab = (tab) => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion
  //#region 결제내역 조회 @observable
  @observable paymentList = [];
  @observable paymentListTotalCount = 0;
  @observable isShownPaymentDetail = false;
  @observable email = '';
  @observable currentPage = 1;
  @observable offset = 0;
  @observable limit = 15;
  //#endregion

  //#region 결제내역 조회 @action
  @action
  onClickPaymentsExportExcel = () => {
    const { email, paymentList, paymentListTotalCount } = this;
    if (!paymentList[0]) return alert('추출할 데이터가 없습니다. 검색 후 다시 시도해주세요.');
    const fileName = `${dateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss')}-payments`,
      exportType = 'csv';

    SchoolApi.get(`/payments?email=${email || ''}&offset=${0}&limit=${paymentListTotalCount}`)
      .then(
        action((res) => {
          if (res.data.status_code === 200) {
            let data = [],
              dataParams;
            res.data.data.paymentList.map((payment) => {
              dataParams = {
                email: payment.email,
                paymentNum: payment.paymentNum,
                paymentDate: dateFormat(payment.paymentDate, 'YYYY-MM-DDTHH:mm:ss'),
                originalPrice: payment.originalPrice,
                price: payment.price,
                pgName: payment.pgName,
                paymentGroup: payment.paymentGroup,
                paymentType: payment.paymentType,
                cardCode: payment.cardCode,
                cardNo: payment.cardNo,
                cardQuota: payment.cardQuota,
                productName: payment.productInfo[0] && payment.productInfo[0].productName,
                promotionCode: payment.promotionInfo[0] && payment.promotionInfo[0].promotionCode,
                issuer: payment.promotionInfo[0] && payment.promotionInfo[0].issuer,
                dcRate: payment.promotionInfo[0] && payment.promotionInfo[0].dcRate + '%',
                expireDate:
                  payment.promotionInfo[0] && dateFormat(payment.promotionInfo[0].expireDate, 'YYYY-MM-DDTHH:mm:ss'),
              };
              return data.push(dataParams);
            });

            return exportFromJSON({ data, fileName, exportType });
          }
        }),
      )
      .catch(
        action((err) => {
          if (err.response.data.status_code >= 400) {
            return alert(err.response.data.alert_message);
          }
        }),
      );
  };

  @action
  onChangePage = (page) => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearchPayments();
  };
  @action
  onClickSearchPayments = () => {
    const { email, offset, limit } = this;
    SchoolApi.get(`/payments?email=${email || ''}&offset=${offset}&limit=${limit}`)
      .then(
        action((res) => {
          if (res.data.status_code === 200) {
            this.paymentList = res.data.data.paymentList;
            this.paymentListTotalCount = res.data.data.paymentListTotalCount;
          }
        }),
      )
      .catch(
        action((err) => {
          if (err.response.data.status_code >= 400) {
            this.paymentList = [];
            this.paymentListTotalCount = 0;
          }
        }),
      );
  };

  @action
  onEnterSearchPayments = (e) => {
    if (e.key === 'Enter') {
      this.onClickSearchPayments();
    }
  };

  @action
  onClearPaymentListOptions = () => {
    document.getElementById('payment-list-email').value = '';
    this.email = '';
  };

  @action
  onChangeEmail = (email) => {
    this.email = email;
  };

  @action
  onTogglePaymentDetail = () => {
    this.isShownPaymentDetail = !this.isShownPaymentDetail;
  };
  //#endregion

  //#region 결제내역 조회 @computed
  @computed
  get getPaymentList() {
    return this.paymentList;
  }

  @computed
  get getPaymentListTotalCount() {
    return this.paymentListTotalCount;
  }

  @computed
  get getIsShownPaymentDetail() {
    return this.isShownPaymentDetail;
  }

  @computed
  get getLimit() {
    return this.limit;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }
  //#endregion

  //#region 결제 상세보기 @observable
  @observable paymentInfo = {};
  //#endregion

  //#region 결제 상세보기 @action
  @action
  onClickPaymentDetail = (paymentId) => {
    SchoolApi.get(`/payments?paymentId=${paymentId}&offset=${0}&limit=${1}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          this.paymentInfo = res.data.data.paymentList[0];
          this.onTogglePaymentDetail();
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  //#endregion

  //#region 결제 상세보기 @computed
  @computed
  get getPaymentInfo() {
    return this.paymentInfo;
  }
  //#endregion
}

export default new PaymentsStore();
