import React, { Component } from 'react';
import SettingHeader from './SettingHeader';
import { inject, observer } from 'mobx-react';

@inject('settings')
@observer
class SettingHeaderContainer extends Component {
  componentDidMount() {
    const { settings } = this.props;
    settings.onLoadAdminInfo(settings.getEmail);
  }
  render() {
    const { settings } = this.props;

    return (
      <React.Fragment>
        <SettingHeader
          thumbnail={settings.getThumbnail}
          adminInfo={settings.getAdminInfo}
          onUploadThumbnail={settings.onUploadThumbnail}
          isUpdateNickname={settings.getIsUpdateNickname}
          onToggleUpdateNickname={settings.onToggleUpdateNickname}
          onModifyAdminNickname={settings.onModifyAdminNickname}
          onChangeAdminNickname={settings.onChangeAdminNickname}
        />
      </React.Fragment>
    );
  }
}

export default SettingHeaderContainer;
