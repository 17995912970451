import React from 'react';
import { Link } from 'react-router-dom';
import './NoticeListOptions.scss';
import { Button } from 'semantic-ui-react';

const NoticeListOptions = ({ onClickNewEditor }) => {
  return (
    <div className='NoticeListOptions'>
      <div>
        <Link to={'/editor?editor_type=notice'} onClick={onClickNewEditor}>
          <Button color='blue'>작성하기</Button>
        </Link>
      </div>
    </div>
  );
};

export default NoticeListOptions;
