import React, { Component } from 'react';
import { PaymentList } from '.';
import { inject, observer } from 'mobx-react';

@inject('payments')
@observer
class PaymentListContainer extends Component {
  render() {
    const { payments } = this.props;

    return (
      <React.Fragment>
        <PaymentList
          paymentList={payments.getPaymentList}
          count={payments.getPaymentListTotalCount}
          onClickPaymentDetail={payments.onClickPaymentDetail}
        />
      </React.Fragment>
    );
  }
}

export default PaymentListContainer;
