import React, { Component } from 'react';
import StudentListPagination from './StudentListPagination';
import { inject, observer } from 'mobx-react';

@inject('students')
@observer
class StudentListPaginationContainer extends Component {
  render() {
    const { students } = this.props;
    return (
      <React.Fragment>
        <StudentListPagination
          currentPage={students.currentPage}
          limit={students.getLimit}
          memberListTotalCount={students.memberListTotalCount}
          onChangePage={students.onChangePage}
          totalPages={students.getTotalPages}
        />
      </React.Fragment>
    );
  }
}

export default StudentListPaginationContainer;
