import { action } from 'mobx';
import { NursingApi } from '../lib/api';

//회원 등록 Store
//작성자 : 이승우
//작성일 : 2020-01-02
class ProfessorAddStore {
  //CSV 파일 읽고 일괄 회원가입
  //작성자 : 이승우
  //작성일 : 2020-01-02
  @action
  onFileLoadAndProfessorSignUp = (memberList) => {
    if (!memberList[0]) return alert('파일이 비어있습니다.');

    let filename = document.getElementById('member-csv-file').files[0].name;

    //파일 확장자 확인
    let fileExt = filename.substring(filename.lastIndexOf('.'), filename.length).toLowerCase();
    if (fileExt !== '.csv') {
      //파일 초기화
      document.getElementById('member-csv-file').value = '';
      document.getElementById('member-csv-file-name').value = 'csv 파일만 읽을 수 있습니다.';
      return alert('확장자 csv 파일만 읽을 수 있습니다.');
    }

    //파일 이름 렌더링
    document.getElementById('member-csv-file-name').value = filename;

    memberList.map((member, i) => {
      //회원가입 parameters
      let signUpOption = {},
        licenseOption = {};

      if (!member.id) return alert(i + '번째 email 열이 존재하지 않습니다.');
      if (!member.password) return alert(i + '번째 pwd 열이 존재하지 않습니다.');
      if (!member.school) return alert(i + '번째 pwdCheck 열이 존재하지 않습니다.');
      if (!member.name) return alert(i + '번째 memberType 열이 존재하지 않습니다.');

      
      Object.assign(signUpOption, {
        id: member.id,
        password: member.password.trim(),
        school: member.school.trim(),
        name: member.name.trim(),
      });
    
      return NursingApi.post('/professors', signUpOption)
        .then((res) => {
          if (res.data.status_code === 200) {
            
            if (memberList.length === i + 1) {
              document.getElementById('member-csv-file').value = '';
              document.getElementById('member-csv-file-name').value = '일괄 등록 요청 성공';
              return alert('일괄 등록 요청 성공');
            }
          }
        })
        .catch((err) => {
          if (err.response.data.code === -3000) {
            document.getElementById('member-csv-file').value = '';
            document.getElementById('member-csv-file-name').value = err.response.data.alert_message;
            return alert(err.response.data.msg);
          }else{
            document.getElementById('member-csv-file').value = '';
            document.getElementById('member-csv-file-name').value = err.response.data.alert_message;
            return alert('실패');
          }
        });

        


    });
  };
}

export default new ProfessorAddStore();
