import React from 'react';
import './AdminDashBoard.scss';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { AdminListContainer } from '../AdminList';
import { AdminListOptionsContainer } from '../AdminListOptions';
import { AdminRegisterContainer } from '../AdminRegister';

const AdminDashBoard = ({ tabList, currentTab, onClickTab, isSession }) => {
  if (!isSession) return (window.location = '/');
  return (
    <DashBoardTemplate title={'관리자'} tabList={tabList} currentTab={currentTab} onClickTab={onClickTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<AdminListOptionsContainer />}>
          <AdminListContainer />
        </DashBoardBody>
      )}
      {currentTab === 'add' && (
        <DashBoardBody>
          <AdminRegisterContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default AdminDashBoard;
