import React from 'react';
import './LicenseCodeList.scss';
import { Table } from 'semantic-ui-react';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Pagination } from '../../common/Pagination';

const LicenseCodeList = ({ licenseCodeList, totalPages, currentPage, onChangePage, onClickDetail }) => {
  if (!licenseCodeList) return null;

  return (
    <div className='LicenseCodeList'>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>라이선스 코드</Table.HeaderCell>
            <Table.HeaderCell>라이선스 타입</Table.HeaderCell>
            <Table.HeaderCell>라이선스 등급</Table.HeaderCell>
            <Table.HeaderCell>라이선스 기간</Table.HeaderCell>
            <Table.HeaderCell>라이선스 코드 사용 가능일</Table.HeaderCell>
            <Table.HeaderCell>라이선스 코드 사용 만료일</Table.HeaderCell>
            <Table.HeaderCell>라이선스 코드 사용 제한 수</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {licenseCodeList.map(licenseCodeInfo => (
            <Table.Row key={licenseCodeInfo.licenseCode}>
              <Table.Cell>
                <label className='license-code-detail-btn' onClick={() => onClickDetail(licenseCodeInfo.licenseCode)}>
                  {licenseCodeInfo.licenseCode}
                </label>
              </Table.Cell>
              <Table.Cell>{licenseCodeInfo.licenseType}</Table.Cell>
              <Table.Cell>{licenseCodeInfo.licenseGrade}</Table.Cell>
              <Table.Cell>{licenseCodeInfo.licenseDate}일</Table.Cell>
              <Table.Cell>{dateFormat(licenseCodeInfo.startDate, 'YYYY년 MM월 DD일')}</Table.Cell>
              <Table.Cell>{dateFormat(licenseCodeInfo.endDate, 'YYYY년 MM월 DD일')}</Table.Cell>
              <Table.Cell>{licenseCodeInfo.usageLimit}명</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} onChangePage={onChangePage} />
    </div>
  );
};

export default LicenseCodeList;
