import { action, computed, observable } from 'mobx';
import { VrwareApi } from '../lib/api';
class SettingsStore {
  @observable adminInfo = {};
  @observable isUpdateNickname = false;
  @observable adminNickname = '';
  @action
  onLoadAdminInfo = email => {
    VrwareApi.get(`/admins?adminEmail=${email}`)
      .then(res => {
        if (res.data.status_code === 200) {
          this.adminInfo = res.data.data.adminList[0];
          this.adminNickname = res.data.data.adminList[0].adminNickname;
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onUploadThumbnail = files => {
    if (!localStorage.getItem('globe_user')) return;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('filename', files[0].name);

    const email = JSON.parse(localStorage.getItem('globe_user')).email;

    VrwareApi.post(`/members/${email}/thumbnail`, data)
      .then(res => {
        if (res.data.status_code === 200) {
          const globeUser = JSON.parse(localStorage.getItem('globe_user'));
          globeUser.thumbnail = res.data.data.imgPath;
          localStorage.setItem('globe_user', JSON.stringify(globeUser));
          window.location.reload();
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onChangeAdminNickname = adminNickname => {
    this.adminNickname = adminNickname;
  };

  @action
  onModifyAdminNickname = adminEmail => {
    VrwareApi.patch(`/admins/${adminEmail}`, { adminNickname: this.adminNickname })
      .then(res => {
        if (res.data.status_code === 200) {
          this.onLoadAdminInfo(adminEmail);
          this.onToggleUpdateNickname();
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onToggleUpdateNickname = () => {
    this.isUpdateNickname = !this.isUpdateNickname;
  };

  @computed
  get getIsUpdateNickname() {
    return this.isUpdateNickname;
  }

  @computed
  get getThumbnail() {
    if (localStorage.getItem('globe_user')) {
      return JSON.parse(localStorage.getItem('globe_user')).thumbnail;
    } else {
      return null;
    }
  }
  @computed
  get getEmail() {
    if (localStorage.getItem('globe_user')) {
      return JSON.parse(localStorage.getItem('globe_user')).email;
    } else {
      return null;
    }
  }

  @computed
  get getAdminInfo() {
    return this.adminInfo;
  }
}

export default new SettingsStore();
