import React from 'react';
import './LicenseCodeRegister.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button, Select } from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';

const LicenseCodeRegister = ({
  onChangeLicenseType,
  onChangeStartDate,
  onChangeEndDate,
  onChangeLicenseDate,
  onChangeLicenseGrade,
  onChangeUsageLimit,
  onClickRegister,
  onClickClear,
  licenseType,
  licenseGrade,
  startDate,
  endDate,
}) => {
  return (
    <div className='LicenseCodeRegister'>
      <div className='license-code-register-section'>
        <LabelWithNode
          label='라이선스 유형'
          node={
            <Select
              id='license-type'
              value={licenseType}
              placeholder='라이선스 유형을 선택해 주세요.'
              options={licenseTypeList}
              onChange={(e, { value }) => onChangeLicenseType(value)}
              fluid
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='license-code-register-section'>
        <LabelWithNode
          label='라이선스 등급'
          node={
            <Select
              id='license-grade'
              value={licenseGrade}
              placeholder='라이선스 등급을 선택해 주세요.'
              options={licenseGradeList}
              onChange={(e, { value }) => onChangeLicenseGrade(value)}
              fluid
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='license-code-register-section'>
        <LabelWithNode
          label='라이선스 기간'
          node={
            <Input
              id='license-date'
              placeholder='라이선스 기간을 입력해 주세요 (단위 : 일)'
              onChange={(e) => onChangeLicenseDate(e.target.value)}
            />
          }
        ></LabelWithNode>
      </div>
      <div className='license-code-register-section'>
        <LabelWithNode
          label='라이선스 코드 사용 가능일'
          node={<DateTimePicker onChange={onChangeStartDate} value={new Date(startDate)} />}
        ></LabelWithNode>
      </div>
      <div className='license-code-register-section'>
        <LabelWithNode
          label='라이선스 코드 사용 만료일'
          node={<DateTimePicker onChange={onChangeEndDate} value={new Date(endDate)} />}
        ></LabelWithNode>
      </div>
      <div className='license-code-register-section'>
        <LabelWithNode
          label='라이선스 코드 사용 제한 수'
          node={
            <Input
              id='license-code-usage-limit'
              placeholder='사용 제한 수를 입력해 주세요. (단위 : 명)'
              onChange={(e) => onChangeUsageLimit(e.target.value)}
            />
          }
        ></LabelWithNode>
      </div>
      <div className='license-code-register-section'>
        <div className='license-code-register-btns'>
          <Button positive onClick={onClickRegister}>
            등록
          </Button>
          <Button onClick={onClickClear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

const licenseTypeList = [
  {
    text: 'VRWARE School',
    value: 'school',
  },
];
const licenseGradeList = [
  {
    text: 'Home',
    value: 'home',
  },
  {
    text: 'Tutor',
    value: 'tutor',
  },
  {
    text: 'Lab',
    value: 'lab',
  },
  {
    text: 'Class',
    value: 'class',
  },
  {
    text: 'Academy',
    value: 'academy',
  },
];

export default LicenseCodeRegister;
