import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { SettingHeaderContainer } from '../components/settings/SettingHeader';
import { SettingTemplate } from '../components/settings/SettingTemplate/SettingTemplate';

const Settings = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <SettingTemplate>
        <SettingHeaderContainer />
      </SettingTemplate>
    </React.Fragment>
  );
};

export default Settings;
