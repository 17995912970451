import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { PromotionDashBoardContainer } from '../components/promotion/PromotionDashBoard';

export const Promotions = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <PromotionDashBoardContainer />
    </React.Fragment>
  );
};

export default Promotions;
