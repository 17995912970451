import React from 'react';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Table, Button } from 'semantic-ui-react';

const PaymentItem = ({ paymentInfo, onClickPaymentDetail }) => {
  const { paymentDate, paymentNum, price, email, paymentGroup, cardNo, cardQuota, paymentId } = paymentInfo;
  if (!paymentInfo) return null;
  return (
    <Table.Row className='PaymentItem'>
      <Table.Cell>{email}</Table.Cell>
      {/* <Table.Cell>{productInfo[0].productName}</Table.Cell> */}
      <Table.Cell>{dateFormat(paymentDate, 'YYYY년 MM월 DD일 HH시 mm분 ss초')}</Table.Cell>
      <Table.Cell>{paymentNum}</Table.Cell>
      <Table.Cell>{_numberWithCommas(price)}</Table.Cell>
      <Table.Cell>{paymentGroup}</Table.Cell>
      <Table.Cell>{cardQuota === '00' ? '일시불' : cardQuota + '개월'}</Table.Cell>
      <Table.Cell>{cardNo}</Table.Cell>
      <Table.Cell onClick={() => onClickPaymentDetail(paymentId)}>
        <Button size='mini' positive>
          보기
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

const _numberWithCommas = (number) => {
  return String(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default PaymentItem;
