import React from 'react';
import { ProfessorItem } from '../ProfessorItem';
import './ProfessorList.scss';
import { Table } from 'semantic-ui-react';

const ProfessorList = ({ professorList, isLoading, onToggleMemberDetail, count }) => {
  if (isLoading) return null;
  return (
    <div className='MemberList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No</Table.HeaderCell>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>학번</Table.HeaderCell>
            <Table.HeaderCell>학교명</Table.HeaderCell>
            <Table.HeaderCell>생성일</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {professorList.map((professor, index) => (
            <ProfessorItem key={index} index={index} professor={professor} onToggleMemberDetail={onToggleMemberDetail} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ProfessorList;
