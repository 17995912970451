import moment from 'moment';
import 'moment-timezone';

//현재 DB에는 이미 서울 시간으로 들어가고 있음 따라서 밑에 함수에 적용시키면 한국시간에 +9가 더 되기때문에 한국시간이 아닌 +18 타임존이 되어버림 따라서 지금으로썬 표시하려면
//표시될 시간의 -9를 하거나 타임존이 +0인 곳으로 포맷팅해야함
//나는 우선 +0 타임존으로 포맷팅진행하겠음
//TODO: 추후에 DB에 있는 값을 모두 타임존을 없애거나, +0 타임존에 맞추어서 넣어주어야 할 것 같음.

export const dateFormat = (date, format) => {
  return moment(date).format(format);
};

export const dateFormatTimezone = (date, format, timezone) => {
  return moment(date)
    .tz(timezone)
    .format(format);
};
