import React from 'react';
import { NewPopup } from '../../common/NewPopup';
import './SmsSentHistoryDetailPopup.scss';
const SmsSentHistoryDetailPopup = ({ isShown, onTogglePopup, smsInfo }) => {
  if (!isShown) return null;
  return (
    <div className="SmsSentHistoryDetailPopup">
      <NewPopup title={'문자 상세 조회'} onTogglePopup={onTogglePopup}>
        <div className="sms-detail-info-wrap">
          <h2>문자 상세 정보</h2>
          <div className="sms-detail-info">
            <div className="sms-detail-section">
              <label>요청 ID</label>
              <p>{smsInfo.requestId}</p>
              <label>수신사 시퀀스</label>
              <p>{smsInfo.mtPr}</p>
            </div>
            <div className="sms-detail-section">
              <label>요청 상태</label>
              <p>{smsInfo.msgStatusName}</p>
              <label>단말기 발송 상태</label>
              <p>{smsInfo.resultCodeName}</p>
            </div>
            <div className="sms-detail-section">
              <label>요청 시간</label>
              <p>{smsInfo.requestDate}</p>
              <label>단말기 수신 시간</label>
              <p>{smsInfo.resultDate}</p>
            </div>
            <div className="sms-detail-section">
              <label>발신 번호</label>
              <p>{smsInfo.sendNo}</p>
              <label>수신 번호</label>
              <p>{smsInfo.recipientNo}</p>
            </div>
          </div>
        </div>
        <div className="sms-info-wrap">
          <h2>문자 내용</h2>
          <div className="sms-info">
            {smsInfo.title && (
              <div className="sms-title">
                <label>제목</label>
                <p className="sms-title-content">{smsInfo.title}</p>
              </div>
            )}
            <div className="sms-body">
              <label>내용</label>
              <p className="sms-body-content">{smsInfo.body}</p>
            </div>
          </div>
        </div>
      </NewPopup>
    </div>
  );
};

export default SmsSentHistoryDetailPopup;
