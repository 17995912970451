import React from 'react';
import './AdminRegister.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button } from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';
const NursingRegister = ({ onChangeAdminEmail, onChangeAdminNickname, onClickUsedDate, onClickClear,
  onChangeSchoolName,onChangeRequestInsertStartDate,onChangeRequestInsertEndDate,requestInsertStartDate,requestInsertEndDate }) => {
  return (
    <div className='AdminRegister'>
      <div className='admin-register-section'>
        <LabelWithNode
          label='학교명'
          node={
            <Input
              id='admin-register-email'
              placeholder='학교를 입력하여 주세요'
              onChange={e => onChangeSchoolName(e.target.value)}
            />
          }
        ></LabelWithNode>
      </div>
      <div className='date-group'>
      <div className="admin-list-options-section">
        <LabelWithNode
          label={'시작일'}
          node={<DateTimePicker id="sms-date-pickerr"   onChange={onChangeRequestInsertStartDate} value={requestInsertStartDate} />}
        />
      </div>

      <div className="admin-list-options-section">
        <LabelWithNode
          label={'종료일'}
          node={<DateTimePicker id="sms-date-picker"  onChange={onChangeRequestInsertEndDate} value={requestInsertEndDate} />}
        />
      </div>

      </div>
      <div className='admin-register-section'>
        <div className='admin-register-btns'>
          <Button positive onClick={onClickUsedDate}>
            등록
          </Button>
          <Button onClick={onClickClear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

export default NursingRegister;
