import React from 'react';
import './StudentItem.scss';

import { dateFormat } from '../../../lib/common/dateFormat';
import { Table } from 'semantic-ui-react';
const StudentItem = ({ index, student, onToggleMemberDetail }) => {
  return (
    <Table.Row className='MemberItem'>
      <Table.Cell>{index + 1}</Table.Cell>
      
      <Table.Cell>{student.name}</Table.Cell>
      <Table.Cell>{student.uid}</Table.Cell>
      <Table.Cell>{student.school}</Table.Cell>
      <Table.Cell>{student.grade}학년</Table.Cell>

      
      <Table.Cell>{dateFormat(student.createDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      
    </Table.Row>
  );
};

export default StudentItem;
