import React from 'react';
import './PromotionDetailPopup.scss';
import { NewPopup } from '../../common/NewPopup';
import { dateFormat } from '../../../lib/common/dateFormat';
const PromotionDetailPopup = ({
  isShown,
  promotionInfo,
  onTogglePromotionDetail,
  onToggleUpdatePromotion,
  onClickDeletePromotion
}) => {
  if (!isShown) return null;
  return (
    <div className='PromotionDetailPopup'>
      <NewPopup
        title={'프로모션 상세정보'}
        onTogglePopup={() => onTogglePromotionDetail()}
        buttons={
          <React.Fragment>
            <button className='promotion-update-btn' onClick={() => onToggleUpdatePromotion()}>
              프로모션 수정
            </button>
            <button className='promotion-delete-btn' onClick={() => onClickDeletePromotion(promotionInfo.promotionId)}>
              프로모션 삭제
            </button>
          </React.Fragment>
        }
      >
        <div className='promotion-info-wrap'>
          <div className='promotion-info-section'>
            <div className='promotion-info-box'>
              <div className='promotion-info-header'>프로모션 ID</div>
              <div className='promotion-info-body'>{promotionInfo.promotionId}</div>
            </div>
            <div className='promotion-info-box'>
              <div className='promotion-info-header'>발급자</div>
              <div className='promotion-info-body'>{promotionInfo.issuer}</div>
            </div>
          </div>
          <div className='promotion-info-section'>
            <div className='promotion-info-box'>
              <div className='promotion-info-header'>프로모션 코드 (기업코드)</div>
              <div className='promotion-info-body'>{promotionInfo.promotionCode}</div>
            </div>
            <div className='promotion-info-box'>
              <div className='promotion-info-header'>프로모션 할인율</div>
              <div className='promotion-info-body'>{promotionInfo.dcRate}%</div>
            </div>
          </div>
          <div className='promotion-info-section'>
            <div className='promotion-info-box'>
              <div className='promotion-info-header'>프로모션 유효기간</div>
              <div className='promotion-info-body'>
                {dateFormat(promotionInfo.expireDate, 'YYYY년 MM월 DD일 HH시 mm분')}
              </div>
            </div>
          </div>
        </div>
      </NewPopup>
    </div>
  );
};

export default PromotionDetailPopup;
