import React, { Component } from 'react';
import { SmsSentHistoryList } from '.';
import { inject, observer } from 'mobx-react';

@inject('sms')
@observer
class SmsSentHistoryListContainer extends Component {
  render() {
    const { sms } = this.props;
    return (
      <React.Fragment>
        <SmsSentHistoryList sentSmsList={sms.getSentSmsList} onClickDetailSms={sms.onClickDetailSms} />
      </React.Fragment>
    );
  }
}

export default SmsSentHistoryListContainer;
