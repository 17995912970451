import { observable, action, computed } from 'mobx';
import { VrwareApi } from '../lib/api';

class ProgramsStore {
  //#region 결제 탭
  @observable currentTab = 'add';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 프로그램 등록
  @observable programName = '';
  @observable programVersion = '';
  @observable programFileUrl = '';
  @observable programPlatform = '';
  @observable notifyProgramName = '';
  @observable notifyProgramVersion = '';
  @observable notifyProgramFileUrl = '';
  @observable notifyProgramPlatform = '';

  @action
  onChangeProgramName = programName => {
    if (programName.length > 0) {
      this.notifyProgramName = '';
    }
    this.programName = programName;
  };

  @action
  onChangeProgramVersion = programVersion => {
    if (programVersion.length > 0) {
      this.notifyProgramVersion = '';
    }
    this.programVersion = programVersion;
  };

  @action
  onChangeProgramFileUrl = programFileUrl => {
    if (programFileUrl.length > 0) {
      this.notifyProgramFileUrl = '';
    }
    this.programFileUrl = programFileUrl;
  };

  @action
  onChangeProgramPlatform = programPlatform => {
    if (programPlatform.length > 0) {
      this.notifyProgramPlatform = '';
    }
    this.programPlatform = programPlatform;
  };

  @action
  onClickRegisterProgram = () => {
    const { programName, programFileUrl, programPlatform, programVersion } = this;

    if (!programName) {
      return (this.notifyProgramName = '프로그램 이름을 입력하지 않았습니다.');
    }
    if (!programPlatform) {
      return (this.notifyProgramPlatform = '프로그램 플랫폼을 입력하지 않았습니다.');
    }
    if (!programVersion) {
      return (this.notifyProgramVersion = '프로그램 버전을 입력하지 않았습니다.');
    }
    if (!programFileUrl) {
      return (this.notifyProgramFileUrl = '프로그램 파일 URL을 입력하지 않았습니다.');
    }

    VrwareApi.post(`/v1.0/programs`, { programName, programPlatform, version: programVersion, programFileUrl })
      .then(res => {
        if (res.data.status_code === 200) {
          this.onClickRegisterClear();
          return alert('프로그램 등록을 성공하였습니다.');
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickRegisterClear = () => {
    document.getElementById('program-name').value = '';
    document.getElementById('program-version').value = '';
    document.getElementById('program-file-url').value = '';

    this.programName = '';
    this.programVersion = '';
    this.programFileUrl = '';
    this.programPlatform = '';
    // window.location.reload();
  };

  @computed
  get getNotifyProgramName() {
    return this.notifyProgramName;
  }

  @computed
  get getNotifyProgramVersion() {
    return this.notifyProgramVersion;
  }

  @computed
  get getNotifyProgramPlatform() {
    return this.notifyProgramPlatform;
  }

  @computed
  get getNotifyProgramFileUrl() {
    return this.notifyProgramFileUrl;
  }

  @computed
  get getProgramPlatform() {
    return this.programPlatform;
  }
  //#endregion

  //#region
  @observable programList = [];
  @observable limit = 3;
  @observable offset = 0;
  @observable searchProgramName = '';
  @observable searchProgramPlatform = '';
  @observable totalCount = 0;
  @observable currentPage = 1;

  @action
  onClickSearchProgram = () => {
    const { searchProgramName, searchProgramPlatform, offset, limit } = this;
    VrwareApi.get(
      `/v1.0/programs?offset=${offset}&limit=${limit}&programName=${searchProgramName ||
        ''}&programPlatform=${searchProgramPlatform || ''}`
    )
      .then(res => {
        if (res.data.status_code === 200) {
          this.programList = res.data.data.programList;
          this.totalCount = res.data.data.totalCount / limit;
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          this.programList = [];
          this.totalCount = 0;
        }
      });
  };

  @action
  onClickSearchClear = () => {
    document.getElementById('program-search-name').value = '';
    document.getElementById('program-search-platform').value = '';

    this.searchProgramName = '';
    this.searchProgramPlatform = '';
  };

  @action
  onChangePage = page => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearchProgram();
  };

  @action
  onEnterSearch = e => {
    if (e.key !== 'Enter') return;
    this.onClickSearchProgram();
  };

  @action
  onChangeSearchProgramName = searchProgramName => {
    this.searchProgramName = searchProgramName;
  };

  @action
  onChangeSearchProgramPlatform = searchProgramPlatform => {
    this.searchProgramPlatform = searchProgramPlatform;
  };

  @computed
  get getSearchProgramPlatform() {
    return this.searchProgramPlatform;
  }

  @computed
  get getProgramList() {
    return this.programList;
  }

  @computed
  get getTotalCount() {
    return this.totalCount;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  //#endregion
}

export default new ProgramsStore();
