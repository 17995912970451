import React, { Component } from 'react';
import ProfessorListOptions from './ProfessorListOptions';

import { inject, observer } from 'mobx-react';

@inject('professors')
@observer
class ProfessorListOptionsContainer extends Component {
  render() {
    const { professors } = this.props;
    return (
      <React.Fragment>
        <ProfessorListOptions
          onChangeSearch={professors.onChangeSearch}
          onClickSearch={professors.onClickSearch}
          onEnterSearch={professors.onEnterSearch}
          onChangeLimit={professors.onChangeLimit}
          onChangeMemberState={professors.onChangeMemberState}
          onChangeMemberType={professors.onChangeMemberType}
          onChangeSearchType={professors.onChangeSearchType}
          limit={professors.getLimit}
          memberState={professors.getMemberState}
          memberType={professors.getMemberType}
          searchType={professors.getSearchType}
          onClickClear={professors.onClickClearProfessorList}
          onChangeGrade={professors.onChangeGrade}
          grade={professors.getGrade}
          onChangeSchool={professors.onChangeSchool}
          onChangeName={professors.onChangeName}
          onChangeAid={professors.onChangeAid}
          onClickProfessorSearch={professors.onClickProfessorSearch}
        />
      </React.Fragment>
    );
  }
}

export default ProfessorListOptionsContainer;
