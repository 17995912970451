import React from 'react';
import './LabelWithNode.scss';

const LabelWithNode = ({ label, node, notifyLeft, notifyRight }) => {
  return (
    <div className="LabelWithNode">
      <label>{label}</label>
      <div className="node-wrap">{node}</div>
      <div className="notify-wrap">
        <span className="notify-left">{notifyLeft}</span>
        <span className="notify-right">{notifyRight}</span>
      </div>
    </div>
  );
};

export default LabelWithNode;
