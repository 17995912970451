import React from 'react';
import ReactFileReader from 'react-file-reader';
import './SettingHeader.scss';
import { MdModeEdit } from 'react-icons/md';
const SettingHeader = ({
  thumbnail,
  adminInfo,
  onUploadThumbnail,
  onChangeAdminNickname,
  isUpdateNickname,
  onToggleUpdateNickname,
  onModifyAdminNickname
}) => {
  return (
    <div className="SettingHeader">
      <div className="thumbnail">
        <img src={thumbnail} alt="user-thumbnail"></img>
        <ReactFileReader handleFiles={onUploadThumbnail}>
          <button className="change-thumbnail-btn">썸네일 변경</button>
        </ReactFileReader>
      </div>
      <div className="user-info-wrap">
        <div className="user-info">
          <div className="user-nickname-wrap">
            {!isUpdateNickname ? (
              <React.Fragment>
                <h2 className="user-nickname">{adminInfo.adminNickname || '닉네임'}</h2>
                <MdModeEdit title={'닉네임 수정하기'} onClick={onToggleUpdateNickname} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <input
                  type="text"
                  onChange={e => onChangeAdminNickname(e.target.value)}
                  defaultValue={adminInfo.adminNickname}
                ></input>
                <button onClick={() => onModifyAdminNickname(adminInfo.adminEmail)}>변경하기</button>
                <button onClick={onToggleUpdateNickname}>취소</button>
              </React.Fragment>
            )}
          </div>
          <h4 className="user-email">{adminInfo.adminEmail || '이메일'}</h4>
        </div>
      </div>
    </div>
  );
};

export default SettingHeader;
