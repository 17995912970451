import React, { useState } from 'react';
import HeaderContainer from '../components/base/Header/HeaderContainer';
import { StudentDetailPopupContainer } from '../components/students/StudentDetailPopup';
import { StudentDashBoardContainer } from '../components/students/StudentDashBoard';

const Students = () => {
  const [isShowUsage, setIsShowUsage] = useState(false);

  const onToggleShowUsage = () => {
    setIsShowUsage(!isShowUsage);
  };

  return (
    <React.Fragment>
      <HeaderContainer />
      <StudentDashBoardContainer />
      <StudentDetailPopupContainer onToggleShowUsage={onToggleShowUsage} />
    </React.Fragment>
  );
};

export default Students;
