import React from 'react';
import './PromotionDashBoard.scss';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { PromotionRegisterContainer } from '../PromotionRegister';
import PromotionListOptionsContainer from '../PromotionListOptions/PromotionListOptionsContainer';
import { PromotionListContainer } from '../PromotionList';
import { PromotionDetailPopupContainer } from '../PromotionDetailPopup';
import { PromotionUpdatePopupContainer } from '../PromotionUpdatePopup';
import { PromotionListPaginationContainer } from '../PromotionListPagination';
const PromotionDashBoard = ({ promotionTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location = '/');
  return (
    <DashBoardTemplate title={'프로모션'} tabList={promotionTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'add' && (
        <DashBoardBody>
          <PromotionRegisterContainer />
        </DashBoardBody>
      )}
      {currentTab === 'list' && (
        <DashBoardBody option={<PromotionListOptionsContainer />}>
          <PromotionListContainer />
          <PromotionListPaginationContainer />
          <PromotionDetailPopupContainer />
          <PromotionUpdatePopupContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default PromotionDashBoard;
