import React, { Component } from 'react';
import { LoginTemplate } from '../LoginTemplate';
import LoginForm from './LoginForm';
import { inject, observer } from 'mobx-react';

@inject('session')
@observer
class LoginFormContainer extends Component {
  render() {
    const { session } = this.props;
    return (
      <LoginTemplate>
        <LoginForm
          onClickLogin={session.login}
          onChangeEmail={session.onChangeEmail}
          onChangePwd={session.onChangePwd}
          isSession={session.isSession}
          loginEnter={session.loginEnter}
        />
      </LoginTemplate>
    );
  }
}

export default LoginFormContainer;
