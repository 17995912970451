import React from 'react';
import './NoticeList.scss';
import { NoticeItem } from '../NoticeItem';
import { Table } from 'semantic-ui-react';

const NoticeList = ({ noticeList, onDeleteNotice, count }) => {
  return (
    <div className='NoticeList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.HeaderCell>타입</Table.HeaderCell>
            <Table.HeaderCell>작성일</Table.HeaderCell>
            <Table.HeaderCell>수정일</Table.HeaderCell>
            <Table.HeaderCell>수정</Table.HeaderCell>
            <Table.HeaderCell>삭제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {noticeList.map(noticeInfo => (
            <NoticeItem noticeInfo={noticeInfo} onDeleteNotice={onDeleteNotice} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default NoticeList;
