import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import StudentDashBoard from './StudentDashBoard';

@inject('students', 'session')
@observer
class StudentDashBoardContainer extends Component {
  render() {
    const { students, session } = this.props;
    return (
      <React.Fragment>
        <StudentDashBoard
          memberTabList={memberTabList}
          currentTab={students.getCurrentTab}
          onClickTab={students.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const memberTabList = [
  {
    value: 'list',
    text: '학생 목록'
  },
  {
    value: 'add',
    text: '학생 등록'
  }
];

export default StudentDashBoardContainer;
