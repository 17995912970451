import React from 'react';
import './MemberListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import Select from 'react-select';
import { Button, Icon } from 'semantic-ui-react';
const MemberListOptions = ({
  onClickSearch,
  onChangeSearch,
  onEnterSearch,
  onChangeLimit,
  onChangeMemberState,
  onChangeMemberType,
  onChangeSearchType,
  onClickClear,
  limit,
  memberState,
  memberType,
  searchType
}) => {
  return (
    <div className='MemberListOptions'>
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'목록 수'}
          node={
            <Select
              id='member-limit'
              defaultValue={limitList[0]}
              options={limitList}
              value={limit}
              onChange={onChangeLimit}
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'회원 상태'}
          node={
            <Select
              id='member-state'
              defaultValue={memberStateList[0]}
              options={memberStateList}
              value={memberState}
              onChange={onChangeMemberState}
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'회원 구분'}
          node={
            <Select
              id='member-type'
              defaultValue={memberTypeList[0]}
              options={memberTypeList}
              value={memberType}
              onChange={onChangeMemberType}
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'검색 타입'}
          node={
            <Select
              id='search-type'
              defaultValue={searchTypeList[0]}
              options={searchTypeList}
              value={searchType}
              onChange={onChangeSearchType}
            ></Select>
          }
        ></LabelWithNode>
      </div>
      <div className='member-list-option-section'>
        <LabelWithNode
          label={'검색어'}
          node={
            <input
              id='member-list-search'
              type='text'
              name='search'
              placeholder='검색어를 입력해주세요.'
              onChange={e => onChangeSearch(e.target.value)}
              onKeyPress={onEnterSearch}
            ></input>
          }
        ></LabelWithNode>
      </div>
      <div className='member-list-option-section'>
        <div className='member-list-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickSearch} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

const limitList = [
  {
    label: '5',
    value: 5
  },
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  }
];
const memberStateList = [
  { label: '전체', value: 'all' },
  { label: '정상', value: '1' },
  { label: '가입대기', value: '0' },
  { label: '휴면', value: '2' },
  { label: '정지', value: '3' },
  { label: '탈퇴', value: '4' }
];
const memberTypeList = [
  { label: '전체', value: 'all' },
  { label: '일반', value: '1' },
  { label: '학생', value: '2' },
  { label: '선생님', value: '3' }
];
const searchTypeList = [
  { label: '이메일', value: 'email' },
  { label: '닉네임', value: 'nickname' }
];

export default MemberListOptions;
