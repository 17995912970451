import React, { Component } from 'react';
import SmsDelivery from './SmsDelivery';
import { inject, observer } from 'mobx-react';
@inject('sms')
@observer
class SmsDeliveryContainer extends Component {
  render() {
    const { sms } = this.props;
    return (
      <React.Fragment>
        <SmsDelivery
          onChangeRequestDate={sms.onChangeRequestDate}
          requestDate={sms.getRequestDate}
          onChangeTextareaBody={sms.onChangeTextareaBody}
          onChangeMessageType={sms.onChangeMessageType}
          onChangeTitle={sms.onChangeTitle}
          messageType={sms.getMessageType}
          onClickSendMessage={sms.onClickSendMessage}
          onClickClear={sms.onClickClear}
          onInsertRecipient={sms.onInsertRecipient}
          titleBytes={sms.getTitleBytes}
          bodyBytes={sms.getBodyBytes}
          notifyTitle={sms.notifyTitle}
          notifyBody={sms.notifyBody}
        />
      </React.Fragment>
    );
  }
}

export default SmsDeliveryContainer;
