import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import EmailSentHistoryListPagination from './EmailSentHistoryListPagination';

@inject('email')
@observer
class EmailSentHistoryListPaginationContainer extends Component {
  render() {
    const { email } = this.props;
    return (
      <React.Fragment>
        <EmailSentHistoryListPagination
          emailListTotalCount={email.getEmailListTotalCount}
          limit={email.getLimit}
          currentPage={email.getCurrentPage}
          onChangePage={email.onChangePage}
        />
      </React.Fragment>
    );
  }
}

export default EmailSentHistoryListPaginationContainer;
