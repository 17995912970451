import React, { Component } from 'react';
import LicenseListPagination from './LicenseListPagination';
import { inject, observer } from 'mobx-react';

@inject('licenses')
@observer
class LicenseListPaginationContainer extends Component {
  render() {
    const { licenses } = this.props;
    return (
      <React.Fragment>
        <LicenseListPagination
          currentPage={licenses.getCurrentPage}
          licenseListTotalCount={licenses.getLicenseListTotalCount}
          limit={licenses.getLimit}
          onChangePage={licenses.onChangePage}
        />
      </React.Fragment>
    );
  }
}

export default LicenseListPaginationContainer;
