import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { ManualDashBoardContainer } from '../components/manuals/ManualDashBoard';

const Manuals = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <ManualDashBoardContainer />
    </React.Fragment>
  );
};

export default Manuals;
