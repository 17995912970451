import React, { Component } from 'react';
import EmailSentHistoryOption from './EmailSentHistoryOption';
import { inject, observer } from 'mobx-react';

@inject('email')
@observer
class EmailSentHistoryOptionContainer extends Component {
  render() {
    const { email } = this.props;
    return (
      <React.Fragment>
        <EmailSentHistoryOption
          onChangeStartSendDate={email.onChangeStartSendDate}
          onChangeEndSendDate={email.onChangeEndSendDate}
          onChangeStartReceiveDate={email.onChangeStartReceiveDate}
          onChangeEndReceiveDate={email.onChangeEndReceiveDate}
          onChangeRequestId={email.onChangeRequestId}
          onChangeMailStatusCode={email.onChangeMailStatusCode}
          onChangeReceiveMailAddr={email.onChangeReceiveMailAddr}
          startSendDate={email.getStartSendDate}
          endSendDate={email.getEndSendDate}
          startReceiveDate={email.getStartReceiveDate}
          endReceiveDate={email.getEndReceiveDate}
          mailStatusCode={email.getMailStatusCode}
          onSearchSentEmailList={email.onSearchSentEmailList}
          onClearSentEmailList={email.onClearSentEmailList}
        />
      </React.Fragment>
    );
  }
}

export default EmailSentHistoryOptionContainer;
