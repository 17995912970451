import React, { Component } from 'react';
import { NoticeListOptions } from '.';
import { inject, observer } from 'mobx-react';

@inject('editor')
@observer
class NoticeListOptionsContainer extends Component {
  render() {
    const { editor } = this.props;

    return (
      <React.Fragment>
        <NoticeListOptions onClickNewEditor={editor.onClickNewEditor} />
      </React.Fragment>
    );
  }
}

export default NoticeListOptionsContainer;
