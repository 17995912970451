import React, { Component } from 'react';
import { UsagePopup } from './UsagePopup';
import { inject, observer } from 'mobx-react';

@inject('members')
@observer
class UsagePopupContainer extends Component {
  render() {
    const { members, isShowUsage, onToggleShowUsage } = this.props;
    return (
      <React.Fragment>
        <UsagePopup
          isShowUsage={isShowUsage}
          onToggleShowUsage={onToggleShowUsage}
          memberInfo={members.getMemberDetail}
          licenseDetail={members.getLicenseDetail}
        />
      </React.Fragment>
    );
  }
}

export default UsagePopupContainer;
