import { observable, action, computed } from 'mobx';
import { ToastApi } from '../lib/api';

class EmailStore {
  @observable currentTab = 'send';
  @observable emailType = { value: '0', label: '일반 메일' };
  @observable requestDate = ''; //예약 메일 발송 일자
  @observable body = ''; //메일 내용
  @observable title = ''; //메일 제목
  @observable senderAddress = 'gpsales@globepoint.co.kr'; //발신자 메일
  @observable receiverList = []; //수신자 메일 목록
  @observable receiver = ''; //인증메일 보낼때만 쓰는거라서 사실상 여기선 안쓰임
  @observable carbonCopyList = []; //참조 목록
  @observable isShownPreviewPopup = false; //이메일 미리보기 팝업 표시 여부

  //-------------발송 내역 조회 @observable---------------------
  @observable sentEmailList = []; //발송내역조회 조회 목록
  @observable requestId = ''; //요청 ID
  @observable startSendDate = new Date().setDate(new Date().getDate() - 7); //발송 일시 시작 날짜
  @observable endSendDate = new Date(); //발송 일시 종료 날짜
  @observable receiveMailAddr = ''; //수신 이메일
  @observable startReceiveDate = ''; //수신 일시 시작 날짜
  @observable endReceiveDate = ''; //수신 일시 종료 날짜
  @observable mailStatusCode = { value: '', label: '전체' }; //메일 발송 상태
  @observable sentEmailDetail = [];
  @observable isShownDetailPopup = false;
  @observable currentPage = 1; // 현재 페이지 번호
  @observable emailListTotalCount = 0;
  @observable limit = 15; //한 페이지당 조회할 수
  //-----------------------------------------------

  //--------------발송 내역 조회 @action-------------------

  @action
  onChangePage = page => {
    this.currentPage = page;
    const startSendDate = this.startSendDate && new Date(this.startSendDate).toUTCString(),
      endSendDate = this.endSendDate && new Date(this.endSendDate).toUTCString(),
      startReceiveDate = this.startReceiveDate && new Date(this.startReceiveDate).toUTCString(),
      endReceiveDate = this.endReceiveDate && new Date(this.endReceiveDate).toUTCString(),
      requestId = this.requestId && this.requestId,
      receiveMailAddr = this.receiveMailAddr && this.receiveMailAddr,
      mailStatusCode = this.mailStatusCode.value && this.mailStatusCode.value;
    let mailSeq = '';

    if (requestId) {
      mailSeq = 0;
    }
    if (startSendDate && endSendDate) {
      const startUnixTime = Date.parse(startSendDate),
        endUnixTime = Date.parse(endSendDate);

      if (startUnixTime >= endUnixTime) {
        return alert('발송 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }
    if (startReceiveDate && endReceiveDate) {
      const startUnixTime = Date.parse(startReceiveDate),
        endUnixTime = Date.parse(endReceiveDate);

      if (startUnixTime >= endUnixTime) {
        return alert('수신 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }

    ToastApi.get(
      `/email?pageNum=${this.currentPage}&pageSize=${this.limit}&startSendDate=${startSendDate}&endSendDate=${endSendDate}&startReceiveDate=${startReceiveDate}&endReceiveDate=${endReceiveDate}&receiveMailAddr=${receiveMailAddr}&mailStatusCode=${mailStatusCode}&requestId=${requestId}&mailSeq=${mailSeq}`
    )
      .then(res => {
        if (res.data.status_code === 200) {
          this.sentEmailList = res.data.data.sentEmailList;
          this.emailListTotalCount = res.data.data.sentEmailList.totalCount;
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onSearchSentEmailList = () => {
    const startSendDate = this.startSendDate && new Date(this.startSendDate).toUTCString(),
      endSendDate = this.endSendDate && new Date(this.endSendDate).toUTCString(),
      startReceiveDate = this.startReceiveDate && new Date(this.startReceiveDate).toUTCString(),
      endReceiveDate = this.endReceiveDate && new Date(this.endReceiveDate).toUTCString(),
      requestId = this.requestId && this.requestId,
      receiveMailAddr = this.receiveMailAddr && this.receiveMailAddr,
      mailStatusCode = this.mailStatusCode.value && this.mailStatusCode.value;
    let mailSeq = '';

    if (requestId) {
      mailSeq = 0;
    }
    if (startSendDate && endSendDate) {
      const startUnixTime = Date.parse(startSendDate),
        endUnixTime = Date.parse(endSendDate);

      if (startUnixTime >= endUnixTime) {
        return alert('발송 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }
    if (startReceiveDate && endReceiveDate) {
      const startUnixTime = Date.parse(startReceiveDate),
        endUnixTime = Date.parse(endReceiveDate);

      if (startUnixTime >= endUnixTime) {
        return alert('수신 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }

    ToastApi.get(
      `/email?pageNum=${this.currentPage}&pageSize=${this.limit}&startSendDate=${startSendDate}&endSendDate=${endSendDate}&startReceiveDate=${startReceiveDate}&endReceiveDate=${endReceiveDate}&receiveMailAddr=${receiveMailAddr}&mailStatusCode=${mailStatusCode}&requestId=${requestId}&mailSeq=${mailSeq}`
    )
      .then(res => {
        if (res.data.status_code === 200) {
          this.sentEmailList = res.data.data.sentEmailList;
          this.emailListTotalCount = res.data.data.sentEmailList.totalCount;
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickDetailEmail = (requestId, mailSeq) => {
    if (!requestId || !String(mailSeq)) {
      //mailSeq가 Number 0 이라서 false로 인식하길래 String으로 변환 후 비교
      return alert('요청 ID가 없습니다. 다시 시도해 주세요.');
    }

    ToastApi.get(`/email?requestId=${requestId}&mailSeq=${mailSeq}`)
      .then(res => {
        if (res.data.status_code === 200) {
          this.sentEmailDetail = res.data.data.sentEmailList.data;
          this.onToggleEmailDetail(); //디테일 팝업창 띄우기
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onToggleEmailDetail = () => {
    this.isShownDetailPopup = !this.isShownDetailPopup;
  };

  @action
  onClearSentEmailList = () => {
    document.getElementById('request-id').value = '';
    document.getElementById('receiver-email').value = '';
    this.mailStatusCode = { value: '', label: '전체' };
    this.receiveMailAddr = '';
    this.startSendDate = '';
    this.startReceiveDate = '';
    this.endSendDate = '';
    this.endReceiveDate = '';
    this.requestId = '';
  };

  @action
  onChangeRequestId = requestId => {
    this.requestId = requestId;
  };

  @action
  onChangeStartSendDate = startSendDate => {
    if (!startSendDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.startSendDate = '');
    }
    this.startSendDate = startSendDate;
  };

  @action
  onChangeEndSendDate = endSendDate => {
    if (!endSendDate) {
      return (this.endSendDate = '');
    }
    this.endSendDate = endSendDate;
  };

  @action
  onChangeStartReceiveDate = startReceiveDate => {
    if (!startReceiveDate) {
      return (this.startReceiveDate = '');
    }
    this.startReceiveDate = startReceiveDate;
  };
  @action
  onChangeEndReceiveDate = endReceiveDate => {
    if (!endReceiveDate) {
      return (this.endReceiveDate = '');
    }
    this.endReceiveDate = endReceiveDate;
  };

  @action
  onChangeReceiveMailAddr = receiveMailAddr => {
    this.receiveMailAddr = receiveMailAddr;
  };

  @action
  onChangeMailStatusCode = mailStatusCode => {
    this.mailStatusCode = mailStatusCode;
  };
  //-----------------------------------------------------

  //발송 내역 조회 @computed
  @computed
  get getStartSendDate() {
    return this.startSendDate;
  }
  @computed
  get getEndSendDate() {
    return this.endSendDate;
  }
  @computed
  get getStartReceiveDate() {
    return this.startReceiveDate;
  }
  @computed
  get getEndReceiveDate() {
    return this.endReceiveDate;
  }
  @computed
  get getMailStatusCode() {
    return this.mailStatusCode;
  }
  @computed
  get getSentEmailList() {
    return this.sentEmailList;
  }
  @computed
  get getIsShownDetailPopup() {
    return this.isShownDetailPopup;
  }

  @computed
  get getSentEmailDetail() {
    return this.sentEmailDetail;
  }

  @computed
  get getLimit() {
    return this.limit;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getEmailListTotalCount() {
    return this.emailListTotalCount;
  }

  //-----------------------

  @action
  onToggleEmailPreview = () => {
    this.isShownPreviewPopup = !this.isShownPreviewPopup;
  };
  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };

  @action
  onChangeTitle = title => {
    this.title = title;
  };

  @action
  onChangeBody = body => {
    this.body = body;
  };

  @action
  onChangeRequestDate = requestDate => {
    this.requestDate = requestDate;
  };

  @action
  onChangeSenderAddress = senderAddress => {
    this.senderAddress = senderAddress;
  };

  @action
  onChangeEmailType = emailType => {
    this.emailType = emailType;
  };

  @action
  onClickSendEmail = () => {
    const { title, body, emailType, senderAddress, requestDate, receiverList, receiver, carbonCopyList } = this;

    //FIXME:여기 email body 수정하게되면 EmailPreview.js의 emailBodyParser도 수정해줘야함

    const htmlBody = `<div
    style="height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #fafafa;"
  >
    <table
    width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#FAFAFA" role="presentation
      style="table-layout: fixed;border:0;text-align:center;border-collapse:collapse;border-spacing:0"
    >
      <tbody>
        <tr>
          <td style="font-size: 0px;"></td>
          <td style="text-align:left">
            <div style="max-width:600px;margin:0 auto;padding:0 40px;background-color: #fff;">
              <table width="600" border="0" cellspacing="0" cellpadding="0" align="center" style="width:100%;border:0;margin:0 auto;border-collapse:collapse;border-spacing:0;word-break:break-all;">
                <tbody>
                  <tr>
                    <td style="height:40px"></td>
                  </tr>
                  <tr>
                    <td style="text-align: left;">
                    <img
                    src="https://api-storage.cloud.toast.com/v1/AUTH_23d1dda527114675bca9b5d7d4f29f6e/images/notification-email/%EF%BF%BD.png"
                    alt="VRWARE"
                    style="border:0;vertical-align:top;max-width: 600px;height: auto;width: auto;display: block;"
                  />
                    </td>
                  </tr>
                  <tr>
                    <td style="height:20px;padding:0;border-bottom:3px solid #007edf;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: left; height: 32px; padding: 0px;"></td>
                  </tr>
                  <tr>
                    <td
                      style='text-align: left; padding: 0px; font-family: "Noto Sans KR", 나눔고딕, NanumGothic, 맑은고딕, "Malgun Gothic", 돋움, Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif; font-size: 22px; font-weight: normal; color: rgb(17, 17, 17); line-height: 33px;'
                    >
                      안녕하세요. VRWARE 입니다.
                    </td>
                  </tr>
                  <tr>
                    <td style="height:16px;padding:0">&nbsp;</td>
                  </tr>
                  <tr>
                    <td
                      align="center"
                      width="600"
                      style="font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:14px;color:#555555;line-height:26px;font-weight:normal"
                    >
                      <div style="text-align: left;">
                        <span>
                          VRWARE를 이용해주시는 고객님께 항상 감사의 말씀드립니다.
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; height: 16px; padding: 0px;"></td>
                  </tr>
                  <tr>
                    <td
                      style="font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:14px;color:#555555;line-height:26px;font-weight:normal"
                    >
                      <div style="text-align: left;">${body}</div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; height: 32px; padding: 0px;"></td>
                  </tr>
                  <tr>
                    <td style="height:16px;padding:0"></td>
                  </tr>
                  <tr>
                  <td  style="font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:12px;color:#555555;line-height:26px;font-weight:normal">
                    <div style="background: #eee;padding: 10px;">궁금하신 사항은 <a href="https://my.vrware.us/cs" target="_sub">고객센터</a>로 문의해 주세요.</div>
                  </td>
                </tr>
                <tr>
                  <td style="height:16px;padding:0"></td>
                </tr>
                  <tr>
                    <td
                      style="padding:0;font-family:'Noto Sans KR','나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,'돋움',Dotum,Helvetica,'Apple SD Gothic Neo',Sans-serif;font-size:12px;line-height:14px;color:#999;text-align:left;"
                    >
                      ©Globepoint Inc. All rights reserved.
                    </td>
                  </tr>
                  <tr>
                    <td style="height:60px;padding:0">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
          <td style="font-size: 0px;"></td>
        </tr>
      </tbody>
    </table>
  </div>`;

    let params = {
      title: title,
      body: htmlBody,
      emailType: emailType.value,
      senderAddress: senderAddress,
      requestDate: requestDate,
      receiverList: receiverList,
      receiver: receiver,
      carbonCopyList: carbonCopyList
    };

    if (emailType.value === '3' || emailType.value === '4') {
      //광고 메일이면
      params['title'] = `(광고) ${title}`;
      params[
        'body'
      ] = `${body}<br><br><div align="left" style="color: #4C4C4C; font-size: 12px; font-family:'돋움','helvetica'; padding : 10px; text-align: left; border:1px solid #d4d4d4; background-color: #F1F1F1;">
      메일 수신을 원치 않으시면 ##BLOCK_RECEIVER_LINK##를 클릭하세요.<br>
      If you do not want to receive it, please click a ##EN_BLOCK_RECEIVER_LINK##
  </div>
      `;
    }

    ToastApi.post(`/email`, params)
      .then(res => {
        if (res.data.status_code === 200) {
          this.onClickClear();
          this.onToggleEmailPreview();
          alert('성공적으로 메일을 발송하였습니다.');
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickClear = () => {
    document.getElementById('email-textarea-body').value = '';
    document.getElementById('email-title').value = '';
    document.getElementById('email-type').value = '0';
    this.emailType = {
      value: '0',
      label: '일반 메일'
    };
    this.title = '';
    this.body = '';
    this.requestDate = '';
    this.receiverList = [];
    this.carbonCopyList = [];
  };

  @action
  onInsertReceiver = e => {
    if (e.key !== 'Enter') return;
    var regExpEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!regExpEmail.test(e.target.value)) {
      e.target.value = '';
      return alert('이메일을 정확히 입력해 주세요.');
    }

    //추가할 수신자 값
    let receiverEmail = e.target.value;

    receiverEmail = receiverEmail.replace(/ +/g, ' ').trim();

    //같은 값이 있는지 확인
    const isExitsReceiver = this.receiverList.find(receiver => receiver === receiverEmail);
    if (isExitsReceiver) {
      e.target.value = '';
      return;
    }

    //엔터일때
    if (e.key === 'Enter' && receiverEmail.length > 0) {
      this.receiverList.push(receiverEmail);
    }

    //태그 인풋 값 초기화
    e.target.value = '';
  };

  @action
  onInsertCarbonCopy = e => {
    if (e.key !== 'Enter') return;
    var regExpEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!regExpEmail.test(e.target.value)) {
      e.target.value = '';
      return alert('이메일을 정확히 입력해 주세요.');
    }

    //추가할 수신자 값
    let carbonCopyEmail = e.target.value;

    carbonCopyEmail = carbonCopyEmail.replace(/ +/g, ' ').trim();

    //같은 값이 있는지 확인
    const isExitsCarbonCopy = this.carbonCopyList.find(carbonCopy => carbonCopy === carbonCopyEmail);
    if (isExitsCarbonCopy) {
      e.target.value = '';
      return;
    }

    //엔터일때
    if (e.key === 'Enter' && carbonCopyEmail.length > 0) {
      this.carbonCopyList.push(carbonCopyEmail);
    }

    //태그 인풋 값 초기화
    e.target.value = '';
  };

  @action
  onDeleteReceiver = receiverEmail => {
    const isDelete = this.receiverList.find(receiver => receiver === receiverEmail);
    this.receiverList.splice(this.receiverList.indexOf(isDelete), 1);
  };

  @action
  onDeleteCarbonCopy = carbonCopyEmail => {
    const isDelete = this.carbonCopyList.find(carbonCopy => carbonCopy === carbonCopyEmail);
    this.carbonCopyList.splice(this.carbonCopyList.indexOf(isDelete), 1);
  };

  @computed
  get getEmailType() {
    return this.emailType;
  }
  @computed
  get getRequestDate() {
    return this.requestDate;
  }
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  @computed
  get getReceiverList() {
    return this.receiverList;
  }
  @computed
  get getSenderAddress() {
    return this.senderAddress;
  }
  @computed
  get getCarbonCopyList() {
    return this.carbonCopyList;
  }
  @computed
  get getBody() {
    return this.body;
  }
  @computed
  get getTitle() {
    return this.title;
  }
  @computed
  get getIsShownPreviewPopup() {
    return this.isShownPreviewPopup;
  }
}

export default new EmailStore();
