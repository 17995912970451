import React, { Component } from 'react';
import UserMenu from './UserMenu';
import { inject, observer } from 'mobx-react';

@inject('base', 'session')
@observer
class UserMenuContainer extends Component {
  render() {
    const { isUserMenu, showUserMenu } = this.props.base;
    const { Logout } = this.props.session;

    if (!isUserMenu) return null;

    return (
      <React.Fragment>
        <UserMenu onClick={showUserMenu} onLogout={Logout} />
      </React.Fragment>
    );
  }
}

export default UserMenuContainer;
