import React from 'react';
import './EmailSentHistoryOption.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

const EmailSentHistoryOption = ({
  onChangeRequestId,
  onChangeStartSendDate,
  onChangeEndSendDate,
  onChangeStartReceiveDate,
  onChangeEndReceiveDate,
  onChangeReceiveMailAddr,
  onChangeMailStatusCode,
  onSearchSentEmailList,
  onClearSentEmailList,
  startSendDate,
  endSendDate,
  startReceiveDate,
  endReceiveDate,
  mailStatusCode
}) => {
  return (
    <div className='EmailSentHistoryOption'>
      <div className='email-sent-list-section'>
        <LabelWithNode
          label={'요청 ID'}
          node={
            <React.Fragment>
              <input
                id='request-id'
                type='text'
                placeholder='요청 ID를 입력해 주세요.'
                onChange={e => onChangeRequestId(e.target.value)}
              />
            </React.Fragment>
          }
        />
      </div>
      <div className='email-sent-list-section'>
        <LabelWithNode
          label={'발송 일시 (시작 날짜, 종료 날짜)'}
          node={
            <React.Fragment>
              <DateTimePicker onChange={onChangeStartSendDate} value={new Date(startSendDate)} />
              <DateTimePicker onChange={onChangeEndSendDate} value={new Date(endSendDate)} />
            </React.Fragment>
          }
        />
      </div>
      <div className='email-sent-list-section'>
        <LabelWithNode
          label={'수신 일시 (시작 날짜, 종료 날짜)'}
          node={
            <React.Fragment>
              <DateTimePicker onChange={onChangeStartReceiveDate} value={startReceiveDate} />
              <DateTimePicker onChange={onChangeEndReceiveDate} value={endReceiveDate} />
            </React.Fragment>
          }
        />
      </div>
      <div className='email-sent-list-section'>
        <LabelWithNode
          label={'수신 메일'}
          node={
            <React.Fragment>
              <input
                id='receiver-email'
                type='text'
                placeholder='수신 이메일을 입력해 주세요.'
                autoComplete='new-password'
                onChange={e => onChangeReceiveMailAddr(e.target.value)}
              />
            </React.Fragment>
          }
        />
      </div>
      <div className='email-sent-list-section'>
        <LabelWithNode
          label={'발송 상태'}
          node={
            <Select
              id='email-type'
              onChange={onChangeMailStatusCode}
              value={mailStatusCode}
              defaultValue={mailStatusCodeList[0]}
              options={mailStatusCodeList}
            />
          }
        />
      </div>
      <div className='email-sent-list-section'>
        <div className='email-sent-list-btns'>
          <div className='email-sent-list-btn'>
            <button className='email-search-btn' onClick={onSearchSentEmailList}>
              검색
            </button>
          </div>
          <div className='email-sent-list-btn'>
            <button className='email-sent-list-clear-btn' onClick={onClearSentEmailList}>
              초기화
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mailStatusCodeList = [
  { value: '', label: '전체' },
  { value: 'SST0', label: '발송준비' },
  { value: 'SST1', label: '발송중' },
  { value: 'SST2', label: '발송완료' },
  { value: 'SST3', label: '발송실패' }
];

export default EmailSentHistoryOption;
