import { action, observable, computed } from 'mobx';

class BaseStore {
  @observable isActiveMenu = true;
  @observable isUserMenu = false;
  @observable activeIndex = 0;

  @action
  showUserMenu = () => {
    this.isUserMenu = !this.isUserMenu;
  };

  @action
  onClickSidebarMenu = titleProps => {
    const { index } = titleProps;
    const newIndex = this.activeIndex === index ? -1 : index;
    this.activeIndex = newIndex;
  };

  @computed
  get getThumbnail() {
    if (localStorage.getItem('globe_user')) {
      return JSON.parse(localStorage.getItem('globe_user')).thumbnail;
    } else {
      return null;
    }
  }

  @computed
  get getActiveIndex() {
    return this.activeIndex;
  }
}

export default new BaseStore();
