import React from 'react';
import './NursingList.scss';
import { Table, Button } from 'semantic-ui-react';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Pagination } from '../../common/Pagination';

const NursingList = ({ schoolList, adminList, totalPages, currentPage, onChangePage, onClickReleaseAdmin, onToggleSchoolDetail }) => {
  if (!schoolList) return null;
  return (
    <div className='AdminList'>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>학교명</Table.HeaderCell>
            <Table.HeaderCell>시작일</Table.HeaderCell>
            <Table.HeaderCell>종료일</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {schoolList.map(schoolInfo => (
            <Table.Row key={schoolInfo.lid}>
              <Table.Cell onClick={() => onToggleSchoolDetail(schoolInfo.name, schoolInfo.startDate, schoolInfo.expiredDate, schoolInfo.lid)} className='member-email'>{schoolInfo.name}</Table.Cell>
              <Table.Cell>{dateFormat(schoolInfo.startDate, 'YYYY년 MM월 DD일')}</Table.Cell>
              <Table.Cell>{dateFormat(schoolInfo.expiredDate, 'YYYY년 MM월 DD일')}</Table.Cell>
              
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} onChangePage={onChangePage} />
    </div>
  );
};

export default NursingList;
