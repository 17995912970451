import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { NoticeDashBoardContainer } from '../components/notices/NoticeDashBoard';
const Notices = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <NoticeDashBoardContainer />
    </React.Fragment>
  );
};

export default Notices;
