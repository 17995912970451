import React, { Component } from 'react';
import AdminRegister from './AdminRegister';
import { inject, observer } from 'mobx-react';

@inject('admins')
@observer
class AdminRegisterContainer extends Component {
  render() {
    const { admins } = this.props;
    return (
      <React.Fragment>
        <AdminRegister
          onChangeAdminEmail={admins.onChangeAdminEmail}
          onChangeAdminNickname={admins.onChangeAdminNickname}
          onClickRegisterAdmin={admins.onClickRegisterAdmin}
          onClickClear={admins.onClickClear}
        />
      </React.Fragment>
    );
  }
}

export default AdminRegisterContainer;
