import React, { Component } from 'react';
import NursingRegister from './NursingRegister';
import { inject, observer } from 'mobx-react';

@inject('nursing')
@observer
class NursingRegisterContainer extends Component {
  render() {
    const { nursing } = this.props;
    return (
      <React.Fragment>
        <NursingRegister
          onChangeAdminEmail={nursing.onChangeAdminEmail}
          onChangeAdminNickname={nursing.onChangeAdminNickname}
          onClickUsedDate={nursing.onClickUsedDate}
          onClickClear={nursing.onClickClear}

          onChangeSchoolName={nursing.onChangeSchoolName}
          onChangeRequestInsertStartDate={nursing.onChangeRequestInsertStartDate}
          onChangeRequestInsertEndDate={nursing.onChangeRequestInsertEndDate}
          requestInsertStartDate={nursing.getRequestInsertStartDate}
          requestInsertEndDate={nursing.getRequestInsertEndDate}
        />
      </React.Fragment>
    );
  }
}

export default NursingRegisterContainer;
