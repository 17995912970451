import { action, observable, computed } from 'mobx';
import { VrwareApi } from '../lib/api';

class ManualsStore {
  //#region 결제 탭
  @observable currentTab = 'list';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  @observable manualList = [];
  @observable manualListTotalCount = 0;

  @action
  loadManualList = () => {
    VrwareApi.get(`/v1.0/manuals`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.manualList = res.data.data.manualList;
            this.manualListTotalCount = res.data.data.manualListTotalCount;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.manualList = [];
        }
      });
  };

  @action
  onDeleteManual = manualId => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('매뉴얼을 삭제하시겠습니까?') === true) {
      VrwareApi.delete(`/v1.0/manuals/${manualId}`)
        .then(
          action(res => {
            if (res.data.status_code === 200) {
              this.loadManualList();
              return alert('매뉴얼 삭제 성공');
            }
          })
        )
        .catch(err => {
          if (err.response.data.status_code >= 400) {
            return alert('매뉴얼 삭제 실패');
          }
        });
    }
  };

  @computed
  get getManualList() {
    return this.manualList;
  }

  @computed
  get getManualListTotalCount() {
    return this.manualListTotalCount;
  }
}

export default new ManualsStore();
