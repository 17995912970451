import { observable, action, computed } from 'mobx';
import { ToastApi } from '../lib/api';

class SmsStore {
  @observable currentTab = 'send';
  @observable requestDate = '';
  @observable body = '';
  @observable bodyBytes = 0;
  @observable messageType = {
    value: '0',
    label: 'SMS',
    maxBytes: '90',
    maxLength: '255',
    titleMaxBytes: '40',
    titleMaxLength: '120'
  };
  @observable notifyBody = '';
  @observable title = '';
  @observable titleBytes = 0;
  @observable notifyTitle = '';
  @observable sendNo = '07079322585';
  @observable recipientList = [];
  //-----------SMS 발송내역 조회 @observable-------------
  @observable msgType = { value: '0', label: 'SMS' };
  @observable msgResultCode = { value: '', label: '전체' };
  @observable msgStatus = { value: '', label: '전체' };
  @observable startRequestDate = new Date().setDate(new Date().getDate() - 7);
  @observable endRequestDate = new Date();
  @observable startResultDate = '';
  @observable endResultDate = '';
  @observable requestId = '';
  @observable receivePhoneNumber = '';
  @observable sentSmsList = [];
  @observable isShownDetailPopup = false;
  @observable sentSmsDetail = [];
  @observable currentPage = 1;
  @observable smsListTotalCount = 0;
  @observable limit = 15;
  //---------------------------------------------------

  //-----------SMS 발송내역 조회 @action------------------

  @action
  onChangePage = page => {
    this.currentPage = page;
    const startRequestDate = this.startRequestDate && new Date(this.startRequestDate).toUTCString(),
      endRequestDate = this.endRequestDate && new Date(this.endRequestDate).toUTCString(),
      startResultDate = this.startResultDate && new Date(this.startResultDate).toUTCString(),
      endResultDate = this.endResultDate && new Date(this.endResultDate).toUTCString(),
      requestId = this.requestId && this.requestId,
      receivePhoneNumber = this.receivePhoneNumber && this.receivePhoneNumber,
      msgStatus = this.msgStatus.value && this.msgStatus.value,
      msgType = this.msgType.value && this.msgType.value,
      msgResultCode = this.msgResultCode.value && this.msgResultCode.value;

    if (startRequestDate && endRequestDate) {
      const startUnixTime = Date.parse(startRequestDate),
        endUnixTime = Date.parse(endRequestDate);

      if (startUnixTime >= endUnixTime) {
        return alert('발송 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }
    if (startResultDate && endResultDate) {
      const startUnixTime = Date.parse(startResultDate),
        endUnixTime = Date.parse(endResultDate);

      if (startUnixTime >= endUnixTime) {
        return alert('수신 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }

    let mtPr = '';

    if (requestId) {
      mtPr = 1;
    }
    ToastApi.get(
      `/sms?pageNum=${this.currentPage}&pageSize=${this.limit}&startRequestDate=${startRequestDate}&endRequestDate=${endRequestDate}&startResultDate=${startResultDate}&endResultDate=${endResultDate}&receivePhoneNumber=${receivePhoneNumber}&msgStatus=${msgStatus}&requestId=${requestId}&msgType=${msgType}&msgResultCode=${msgResultCode}&mtPr=${mtPr}`
    )
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.sentSmsList = res.data.data.smsList;
            this.smsListTotalCount = res.data.data.smsList.totalCount;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onChangeRequestId = requestId => {
    this.requestId = requestId;
  };

  @action
  onToggleSmsDetail = () => {
    this.isShownDetailPopup = !this.isShownDetailPopup;
  };

  @action
  onChangeStartRequestDate = startRequestDate => {
    if (!startRequestDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.startRequestDate = '');
    }
    this.startRequestDate = startRequestDate;
  };
  @action
  onChangeEndRequestDate = endRequestDate => {
    if (!endRequestDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.endRequestDate = '');
    }
    this.endRequestDate = endRequestDate;
  };
  @action
  onChangeStartResultDate = startResultDate => {
    if (!startResultDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.startResultDate = '');
    }
    this.startResultDate = startResultDate;
  };

  @action
  onChangeEndResultDate = endResultDate => {
    if (!endResultDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.endResultDate = '');
    }
    this.endResultDate = endResultDate;
  };

  @action
  onChangeReceivePhoneNumber = receivePhoneNumber => {
    this.receivePhoneNumber = receivePhoneNumber;
  };

  @action
  onChangeMsgStatus = msgStatus => {
    this.msgStatus = msgStatus;
  };

  @action
  onChangeMsgResultCode = msgResultCode => {
    this.msgResultCode = msgResultCode;
  };

  @action
  onChangeMsgType = msgType => {
    this.msgType = msgType;
  };

  @action
  onClearSentSmsList = () => {
    document.getElementById('msg-type').value = '';
    document.getElementById('msg-status').value = '';
    document.getElementById('receiver-sms').value = '';
    document.getElementById('msg-result-code').value = '';
    document.getElementById('sms-request-id').value = '';
    this.requestId = '';
    this.receivePhoneNumber = '';
    this.startRequestDate = '';
    this.startResultDate = '';
    this.endResultDate = '';
    this.endRequestDate = '';
    this.msgType = { value: '0', label: 'SMS' };
    this.msgResultCode = { value: '', label: '전체' };
    this.msgStatus = { value: '', label: '전체' };
  };

  @action
  onSearchSentSmsList = () => {
    const startRequestDate = this.startRequestDate && new Date(this.startRequestDate).toUTCString(),
      endRequestDate = this.endRequestDate && new Date(this.endRequestDate).toUTCString(),
      startResultDate = this.startResultDate && new Date(this.startResultDate).toUTCString(),
      endResultDate = this.endResultDate && new Date(this.endResultDate).toUTCString(),
      requestId = this.requestId && this.requestId,
      receivePhoneNumber = this.receivePhoneNumber && this.receivePhoneNumber,
      msgStatus = this.msgStatus.value && this.msgStatus.value,
      msgType = this.msgType.value && this.msgType.value,
      msgResultCode = this.msgResultCode.value && this.msgResultCode.value;

    if (startRequestDate && endRequestDate) {
      const startUnixTime = Date.parse(startRequestDate),
        endUnixTime = Date.parse(endRequestDate);

      if (startUnixTime >= endUnixTime) {
        return alert('발송 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }
    if (startResultDate && endResultDate) {
      const startUnixTime = Date.parse(startResultDate),
        endUnixTime = Date.parse(endResultDate);

      if (startUnixTime >= endUnixTime) {
        return alert('수신 조회 종료 기간은 시작 기간보다 작거나 같을 수 없습니다.');
      }
    }

    let mtPr = '';

    if (requestId) {
      mtPr = 1;
    }

    ToastApi.get(
      `/sms?pageNum=${this.currentPage}&pageSize=${this.limit}&startRequestDate=${startRequestDate}&endRequestDate=${endRequestDate}&startResultDate=${startResultDate}&endResultDate=${endResultDate}&receivePhoneNumber=${receivePhoneNumber}&msgStatus=${msgStatus}&requestId=${requestId}&msgType=${msgType}&msgResultCode=${msgResultCode}&mtPr=${mtPr}`
    )
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.sentSmsList = res.data.data.smsList;
            this.smsListTotalCount = res.data.data.smsList.totalCount;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickDetailSms = (requestId, mtPr) => {
    if (!requestId || !String(mtPr)) {
      //mailSeq가 Number 0 이라서 false로 인식하길래 String으로 변환 후 비교
      return alert('요청 ID가 없습니다. 다시 시도해 주세요.');
    }

    ToastApi.get(`/sms?requestId=${requestId}&mtPr=${mtPr}&msgType=${this.msgType.value}`)
      .then(res => {
        if (res.data.status_code === 200) {
          this.sentSmsDetail = res.data.data.smsList.data;
          this.onToggleSmsDetail(); //디테일 팝업창 띄우기
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  //----------------------------------------------------

  //---------------SMS 발송내역 조회 @computed-----------------
  @computed
  get getStartRequestDate() {
    return this.startRequestDate;
  }

  @computed
  get getSentSmsDetail() {
    return this.sentSmsDetail;
  }
  @computed
  get getEndRequestDate() {
    return this.endRequestDate;
  }

  @computed
  get getStartResultDate() {
    return this.startResultDate;
  }

  @computed
  get getEndResultDate() {
    return this.endResultDate;
  }

  @computed
  get getMsgType() {
    return this.msgType;
  }
  @computed
  get getMsgStatus() {
    return this.msgStatus;
  }
  @computed
  get getMsgResultCode() {
    return this.msgResultCode;
  }

  @computed
  get getSentSmsList() {
    return this.sentSmsList;
  }

  @computed
  get getIsShownDetailPopup() {
    return this.isShownDetailPopup;
  }

  @computed
  get getLimit() {
    return this.limit;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getSmsListTotalCount() {
    return this.smsListTotalCount;
  }

  //---------------------------------------------------------

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };

  @action
  onClickSendMessage = () => {
    const { title, body, messageType, sendNo, requestDate, recipientList, notifyTitle, notifyBody } = this;
    if (notifyTitle || notifyBody) {
      return alert('메세지를 전송할 수 없습니다. 입력한 정보를 확인해 주세요.');
    }

    const params = {
      title: title,
      body: body,
      messageType: messageType.value,
      sendNo: sendNo,
      requestDate: requestDate,
      recipientList: recipientList
    };

    ToastApi.post(`/sms`, params)
      .then(res => {
        if (res.data.status_code === 200) {
          this.onClickClear();
          alert('성공적으로 메세지를 발송하였습니다.');
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickClear = () => {
    document.getElementById('sms-textarea-body').value = '';
    document.getElementById('sms-input-title').value = '';
    document.getElementById('sms-message-type').value = '0';
    this.messageType = {
      value: '0',
      label: 'SMS',
      maxBytes: '90',
      maxLength: '255',
      titleMaxBytes: '40',
      titleMaxLength: '120'
    };
    this.title = '';
    this.body = '';
    this.requestDate = '';
    this.recipientList = [];
    this.notifyTitle = '';
    this.notifyBody = '';
    this.titleBytes = 0;
    this.bodyBytes = 0;
  };

  @action
  onInsertRecipient = e => {
    if (e.key !== 'Enter') return;
    var regExpNumber = /^\d{2,3}\d{3,4}\d{4}$/;
    if (!regExpNumber.test(e.target.value)) {
      e.target.value = '';
      return alert('번호를 정확히 입력해 주세요.');
    }

    //추가할 수신자 값
    let recipientNumber = e.target.value;

    recipientNumber = recipientNumber.replace(/ +/g, ' ').trim();

    //특수문자 제거
    // eslint-disable-next-line
    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
    if (regExp.test(recipientNumber)) {
      recipientNumber = recipientNumber.replace(regExp, '');
    }

    //같은 값이 있는지 확인
    const isExitsRecipient = this.recipientList.find(recipient => recipient === recipientNumber);
    if (isExitsRecipient) {
      e.target.value = '';
      return;
    }

    //엔터일때
    if (e.key === 'Enter' && recipientNumber.length > 0) {
      this.recipientList.push(recipientNumber);
    }

    //태그 인풋 값 초기화
    e.target.value = '';
  };

  @action
  onDeleteRecipient = recipientNumber => {
    const isDelete = this.recipientList.find(recipient => recipient === recipientNumber);
    this.recipientList.splice(this.recipientList.indexOf(isDelete), 1);
  };

  @action
  onChangeTitle = title => {
    if (this.messageType.value !== '1') {
      return (this.notifyTitle = '');
    }
    this.title = title;

    if (title.length === 0) {
      this.titleBytes = 0;
    }

    var titleBytes = 0;
    for (let i = 0; i < title.length; i++) {
      const onChar = escape(title.charAt(i));
      if (onChar.length === 1) {
        //영어 일때
        titleBytes++;
      } else if (onChar.indexOf('%u') !== -1) {
        //한글 일때
        titleBytes += 2;
      } else if (onChar.indexOf('%') !== -1) {
        //특수 문자 일때
        titleBytes++;
      }
      this.titleBytes = titleBytes;
    }

    if (this.titleBytes > 40) {
      this.notifyTitle = '입력할 수 있는 바이트 수가 초과되어 내용이 잘리는 문제가 발생할 수 있습니다.';
    } else {
      this.notifyTitle = '';
    }
    if (title.length > 120) {
      return alert('글자 수를 초과했습니다.');
    }
  };

  @action
  onChangeRequestDate = requestDate => {
    this.requestDate = requestDate;
  };

  @action
  onChangeMessageType = messageType => {
    this.messageType = messageType;
    this.onChangeTitle(this.title);
    this.onChangeTextareaBody(this.body);
  };

  @action
  onChangeTextareaBody = body => {
    this.body = body;
    if (body.length === 0) {
      this.bodyBytes = 0;
    }

    var textareaBytes = 0;

    for (let i = 0; i < body.length; i++) {
      const onChar = escape(body.charAt(i));

      if (onChar.length === 1) {
        //영어 일때
        textareaBytes++;
      } else if (onChar.indexOf('%u') !== -1) {
        //한글 일때
        textareaBytes += 2;
      } else if (onChar.indexOf('%') !== -1) {
        //특수 문자 일때
        textareaBytes++;
      }
      this.bodyBytes = textareaBytes;
    }

    switch (this.messageType.value) {
      case '0':
        if (this.bodyBytes > 90) {
          this.notifyBody =
            '입력할 수 있는 바이트 수가 초과되어 내용이 잘리는 문제가 발생할 수 있습니다. MMS 발송을 권장합니다.';
        } else {
          this.notifyBody = '';
        }
        if (body.length > 255) {
          return alert('글자 수를 초과했습니다.');
        }
        break;
      case '1':
        if (this.bodyBytes > 2000) {
          this.notifyBody = '입력할 수 있는 바이트 수가 초과되어 내용이 잘리는 문제가 발생할 수 있습니다.';
        } else {
          this.notifyBody = '';
        }
        if (body.length > 4000) {
          return alert('글자 수를 초과했습니다.');
        }
        break;
      default:
        break;
    }
  };

  @computed
  get getCurrentTab() {
    return this.currentTab;
  }

  @computed
  get getRequestDate() {
    return this.requestDate;
  }

  @computed
  get getMessageType() {
    return this.messageType;
  }

  @computed
  get getRecipientList() {
    return this.recipientList;
  }

  @computed
  get getTitleBytes() {
    return this.titleBytes;
  }

  @computed
  get getBodyBytes() {
    return this.bodyBytes;
  }
}

export default new SmsStore();
