import React, { Component } from 'react';
import NursingDashBoard from './NursingDashBoard';
import { inject, observer } from 'mobx-react';

@inject('admins', 'session')
@observer
class NursingDashBoardContainer extends Component {
  render() {
    const { admins, session } = this.props;
    return (
      <React.Fragment>
        <NursingDashBoard
          tabList={tabList}
          currentTab={admins.getCurrentTab}
          onClickTab={admins.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}
const tabList = [
  { value: 'list', text: '사용기간 조회' },
  { value: 'add', text: '사용기간 등록' }
];
export default NursingDashBoardContainer;
