import React from 'react';
import './AdminListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button, Icon } from 'semantic-ui-react';

const AdminListOptions = ({ onClickSearch, onEnterSearch, onChangeSearchAdminEmail }) => {
  return (
    <div className='AdminListOptions'>
      <div className='admin-list-options-section'>
        <LabelWithNode
          label={'관리자 이메일'}
          node={
            <Input
              fluid
              placeholder={'관리자 이메일을 입력해 주세요.'}
              onKeyPress={onEnterSearch}
              onChange={e => onChangeSearchAdminEmail(e.target.value)}
            />
          }
        />
      </div>
      <div className='admin-list-options-section'>
        <div className='admin-list-options-btns'>
          <Button color='blue' icon labelPosition='right' onClick={onClickSearch}>
            검색
            <Icon name='search' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminListOptions;
