import React from 'react';
import './PaymentPagination.scss';
import { Pagination } from '../../common/Pagination';
const PaymentPagination = ({ currentPage, limit, onChangePage, total }) => {
  return (
    <div className='PaymentPagination'>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.floor(total / limit)}
        onChangePage={onChangePage}
      ></Pagination>
    </div>
  );
};

export default PaymentPagination;
