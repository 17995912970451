import React from 'react';
import './MapListOptions.scss';
import { Button, Icon, Input, Checkbox } from 'semantic-ui-react';
import { LabelWithNode } from '../../common/LabelWithNode';

const MapListOptions = ({
  onClickSearchMap,
  onClickSearchMapClear,
  onChangeSearchMapTitle,
  onChangeSearchMapEmail,
  onEnterSearchMap,
  onToggleIsShared,
  isShared,
  isGpShared,
  onToggleIsGpShared
}) => {
  return (
    <div className='MapListOptions'>
      <div className='map-list-options-section'>
        <LabelWithNode
          label={'맵 제목'}
          node={
            <Input
              id='map-search-name'
              type='text'
              placeholder={'맵 이름을 입력해 주세요.'}
              onChange={e => onChangeSearchMapTitle(e.target.value)}
              onKeyPress={onEnterSearchMap}
            />
          }
        />
      </div>
      <div className='map-list-options-section'>
        <LabelWithNode
          label={'맵 제작자'}
          node={
            <Input
              id='map-search-email'
              type='text'
              placeholder={'이메일을 입력해 주세요.'}
              onChange={e => onChangeSearchMapEmail(e.target.value)}
              onKeyPress={onEnterSearchMap}
            />
          }
        />
      </div>
      <div className='map-list-options-section'>
        <LabelWithNode
          label={''}
          node={<Checkbox label='공유된 맵 보기' checked={isShared} onChange={onToggleIsShared} />}
        />
      </div>
      <div className='map-list-options-section'>
        <LabelWithNode
          label={''}
          node={<Checkbox label='GP 공유 맵 보기' checked={isGpShared} onChange={onToggleIsGpShared} />}
        />
      </div>
      <div className='map-list-options-section'>
        <div className='map-list-options-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={() => onClickSearchMap(true)} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickSearchMapClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

export default MapListOptions;
