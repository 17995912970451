import React from 'react';
import { EditorTemplate } from '../components/editor/EditorTemplate';
import { Helmet } from 'react-helmet';
import queryParser from 'lib/common/queryParser';
import { EditorHeaderContainer } from '../components/editor/EditorHeader';
import { EditorPanesContainer } from '../components/editor/EditorPanes';
import { SubmitBoxContainer } from '../components/editor/SubmitBox';
const Editor = ({ location }) => {
  const noticeId = queryParser(location.search).notice_id;
  const manualId = queryParser(location.search).manual_id;
  const type = queryParser(location.search).editor_type;
  return (
    <EditorTemplate header={<EditorHeaderContainer />}>
      <Helmet>
        <title>글 작성하기 | VRWARE Admin</title>
      </Helmet>
      <SubmitBoxContainer />
      <EditorPanesContainer noticeId={noticeId} manualId={manualId} type={type} />
    </EditorTemplate>
  );
};

export default Editor;
