import React, { Component } from 'react';
import SmsSentHistoryDetailPopup from './SmsSentHistoryDetailPopup';
import { inject, observer } from 'mobx-react';

@inject('sms')
@observer
class SmsSentHistoryDetailPopupContainer extends Component {
  render() {
    const { sms } = this.props;
    return (
      <React.Fragment>
        <SmsSentHistoryDetailPopup
          onTogglePopup={sms.onToggleSmsDetail}
          isShown={sms.getIsShownDetailPopup}
          smsInfo={sms.getSentSmsDetail}
        />
      </React.Fragment>
    );
  }
}

export default SmsSentHistoryDetailPopupContainer;
