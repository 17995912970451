import React, { Component } from 'react';
import NoticeList from './NoticeList';
import { inject, observer } from 'mobx-react';

@inject('notices')
@observer
class NoticeListContainer extends Component {
  componentDidMount() {
    const { notices } = this.props;
    notices.loadNoticeList();
  }
  render() {
    const { notices } = this.props;
    return (
      <React.Fragment>
        <NoticeList
          noticeList={notices.getNoticeList}
          onDeleteNotice={notices.onDeleteNotice}
          count={notices.getNoticeListTotalCount}
        />
      </React.Fragment>
    );
  }
}

export default NoticeListContainer;
