import React from 'react';
import { Link } from 'react-router-dom';
import './SidebarMenu.scss';
import { Accordion } from 'semantic-ui-react';

const SidebarMenu = ({ onClickSidebarMenu, activeIndex }) => {
  const pathname = window.location.pathname;
  return (
    <div className='SidebarMenu'>
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          content='VRWARE'
          index={0}
          icon={activeIndex === 0 ? 'minus' : 'plus'}
          onClick={(e, titleProps) => onClickSidebarMenu(titleProps)}
        />
        <Accordion.Content active={activeIndex === 0}>
          <ul>
            <Link to='/members'>
              <li className={pathname === '/members' || pathname === '/' ? 'active' : ''}>{'회원'}</li>
            </Link>
            <Link to='/licenses'>
              <li className={pathname === '/licenses' ? 'active' : ''}>{'라이선스'}</li>
            </Link>
            <Link to='/license-codes'>
              <li className={pathname === '/license-codes' ? 'active' : ''}>{'라이선스 코드'}</li>
            </Link>
            <Link to='/notices'>
              <li className={pathname === '/notices' ? 'active' : ''}>{'공지사항'}</li>
            </Link>
            <Link to='/manuals'>
              <li className={pathname === '/manuals' ? 'active' : ''}>{'매뉴얼'}</li>
            </Link>
            <Link to='/programs'>
              <li className={pathname === '/programs' ? 'active' : ''}>{'프로그램'}</li>
            </Link>
            <Link to='/auths'>
              <li className={pathname === '/auths' ? 'active' : ''}>{'API 인증 키'}</li>
            </Link>
            <Link to='/admins'>
              <li className={pathname === '/admins' ? 'active' : ''}>{'관리자'}</li>
            </Link>
          </ul>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          content='VRWARE School'
          index={1}
          icon={activeIndex === 1 ? 'minus' : 'plus'}
          onClick={(e, titleProps) => onClickSidebarMenu(titleProps)}
        />
        <Accordion.Content active={activeIndex === 1}>
          <ul>
            <Link to='/maps'>
              <li className={pathname === '/maps' ? 'active' : ''}>{'맵'}</li>
            </Link>
            <Link to='/payments'>
              <li className={pathname === '/payments' ? 'active' : ''}>{'결제내역'}</li>
            </Link>
            <Link to='/promotions'>
              <li className={pathname === '/promotions' ? 'active' : ''}>{'프로모션'}</li>
            </Link>
          </ul>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          content='NHN Toast'
          index={2}
          icon={activeIndex === 2 ? 'minus' : 'plus'}
          onClick={(e, titleProps) => onClickSidebarMenu(titleProps)}
        />
        <Accordion.Content active={activeIndex === 2}>
          <ul>
            <Link to='/sms'>
              <li className={pathname === '/sms' ? 'active' : ''}>{'SMS '}</li>
            </Link>
            <Link to='/email'>
              <li className={pathname === '/email' ? 'active' : ''}>{'Email'}</li>
            </Link>
          </ul>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 3}
          content='핵심간호술기'
          index={3}
          icon={activeIndex === 3 ? 'minus' : 'plus'}
          onClick={(e, titleProps) => onClickSidebarMenu(titleProps)}
        />
        <Accordion.Content active={activeIndex === 3}>
          <ul>
            <Link to='/nursing'>
              <li className={pathname === '/nursing' ? 'active' : ''}>{'사용기간'}</li>
            </Link>
            <Link to='/students'>
              <li className={pathname === '/students' ? 'active' : ''}>{'학생'}</li>
            </Link>
            <Link to='/professors'>
              <li className={pathname === '/professors' ? 'active' : ''}>{'교수'}</li>
            </Link>
          </ul>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default SidebarMenu;
