import React, { Component } from 'react';
import MapDetailPopup from './MapDetailPopup';
import { inject, observer } from 'mobx-react';

@inject('maps')
@observer
class MapDetailPopupContainer extends Component {
  render() {
    const { maps } = this.props;
    return (
      <React.Fragment>
        <MapDetailPopup
          mapDetail={maps.getMapDetail}
          isOpen={maps.getIsOpenMapDetailPopup}
          onTogglePopup={maps.onToggleMapDetailPoup}
          onClickReleaseShareMap={maps.onClickReleaseShareMap}
          onClickMapShare={maps.onClickMapShare}
          isLoading={maps.getIsLoadingImg}
          onClickGpShare={maps.onClickGpShare}
          onClickReleaseGpShareMap={maps.onClickReleaseGpShareMap}
        />
      </React.Fragment>
    );
  }
}

export default MapDetailPopupContainer;
