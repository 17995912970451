import React from 'react';
import { IoMdClose } from 'react-icons/io';
import './SmsRecipientList.scss';
import { observer } from 'mobx-react';
const SmsRecipientList = ({ recipientList, onDeleteRecipient }) => {
  return (
    <div className="SmsRecipientList">
      {recipientList.map(recipientNumber => (
        <div key={recipientNumber} className="SmsRecipientItem">
          <div className="recipient-number">{recipientNumber}</div>
          <div className="recipient-remove">
            <IoMdClose onClick={() => onDeleteRecipient(recipientNumber)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(SmsRecipientList);
