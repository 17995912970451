import axios from 'axios';

const API_URI = 'https://api.vrware.us/api/toast';
const LOCAL_API_URI = 'http://localhost:8001/api/toast';

let Axios;

if (process.env.NODE_ENV === 'production') {
  Axios = axios.create({
    baseURL: API_URI,
    timeout: 60000,
    headers: {
      clientId: 'JWPU0SBJPHKMZS9YJFNB',
      clientSecretKey: 'KJIKapzeWvyIviZm5wRKMYv86k7tVaBdE8n9TcWN'
    }
  });
} else if (process.env.NODE_ENV === 'development') {
  Axios = axios.create({
    baseURL: LOCAL_API_URI,
    timeout: 60000
  });
}

export default Axios;
