import React, { Component } from 'react';
import { LicenseCodeDetailPopup } from '.';
import { inject, observer } from 'mobx-react';

@inject('licenseCodes')
@observer
class LicenseCodeDetailPopupContainer extends Component {
  render() {
    const { licenseCodes } = this.props;
    return (
      <>
        <LicenseCodeDetailPopup
          licenseCodeInfo={licenseCodes.getLicenseCodeInfo}
          isShowPopup={licenseCodes.getIsShowDetailPopup}
          onTogglePopup={licenseCodes.onToggleDetailPopup}
        />
      </>
    );
  }
}

export default LicenseCodeDetailPopupContainer;
