import React, { Component } from 'react';
import PaymentDetailPopup from './PaymentDetailPopup';
import { inject, observer } from 'mobx-react';

@inject('payments')
@observer
class PaymentDetailPopupContainer extends Component {
  render() {
    const { payments } = this.props;
    return (
      <React.Fragment>
        <PaymentDetailPopup
          isShown={payments.getIsShownPaymentDetail}
          paymentInfo={payments.getPaymentInfo}
          onTogglePopup={payments.onTogglePaymentDetail}
        />
      </React.Fragment>
    );
  }
}

export default PaymentDetailPopupContainer;
