import React from 'react';
import './ProfessorDetailPopup.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Popup } from '../../common/Popup';
import { Button, Item, Segment } from 'semantic-ui-react';
const ProfessorDetailPopup = ({
  showMemberDetail,
  memberDetail,
  licenseDetail,
  onToggleMemberDetail,
  onToggleMemberUpdate,
  onToggleLicenseUpdate,
  onToggleShowUsage,
}) => {
  return (
    <div className='MemberDetailPopup'>
      <Popup
        title={'회원 상세정보'}
        isOpen={showMemberDetail}
        onTogglePopup={() => onToggleMemberDetail()}
        btns={
          <React.Fragment>
            {memberDetail.memberType === '3' && (
              <Button positive onClick={onToggleShowUsage}>
                사용량 보기
              </Button>
            )}
            <Button positive onClick={onToggleMemberUpdate}>
              회원정보 수정
            </Button>
            <Button positive onClick={onToggleLicenseUpdate}>
              라이선스 수정
            </Button>
          </React.Fragment>
        }
      >
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>이메일</Item.Header>
              <Item.Description>
                <Segment>{memberDetail.email}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>닉네임</Item.Header>
              <Item.Description>
                <Segment>{memberDetail.nickname}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>회원타입</Item.Header>
              <Item.Description>
                <Segment>
                  {(memberDetail.memberType === '1' && '일반') ||
                    (memberDetail.memberType === '2' && '학생') ||
                    (memberDetail.memberType === '3' && '선생님') ||
                    (!memberDetail.memberType && '일반')}
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>가입날짜</Item.Header>
              <Item.Description>
                <Segment>{dateFormat(memberDetail.joinDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>최근 접속일</Item.Header>
              <Item.Description>
                <Segment>
                  {memberDetail.lastLoginDate
                    ? dateFormat(memberDetail.lastLoginDate, 'YYYY년 MM월 DD일 HH시 mm분')
                    : '최근 접속 기록 없음'}
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>라이선스 등급</Item.Header>
              <Item.Description>
                <Segment>
                  {licenseDetail ? (licenseDetail.licenseGrade ? licenseDetail.licenseGrade : 'lite') : 'lite'}
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>라이선스 만료일</Item.Header>
              <Item.Description>
                <Segment>{dateFormat(licenseDetail.expireDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Popup>
    </div>
  );
};

export default ProfessorDetailPopup;
