import React from 'react';
import './DashBoardHeader.scss';

const DashBoardHeader = ({ title, tabList, currentTab, onClickTab }) => {
  if (!tabList || !currentTab) return null;
  const tabInfo = tabList.find(tab => tab.value === currentTab);
  return (
    <div className='DashBoardHeader'>
      <h2 className='dashboard-title'>
        {title} > {tabInfo.text}
      </h2>
      <div className='dashboard-tab-wrap'>
        <ul className='tab-list'>
          {tabList.map(tab => (
            <li className={`tab-item ${tab.value === currentTab && 'tab-selected'}`} key={tab.text}>
              <span onClick={() => onClickTab(tab.value)}>{tab.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashBoardHeader;
