import { observable, action, computed } from 'mobx';
import { VrwareApi } from 'lib/api';
class EditorStore {
  @observable title = null;
  @observable markdown = null;
  @observable cursor = null;
  @observable description = null;
  @observable isPrivate = false;
  @observable noticeId = null;
  @observable isEdit = false;
  @observable insertText = null;
  @observable isSubmitBox = false;
  @observable isUploadMask = false;
  @observable editorType = null;
  @observable manualId = null;
  @observable manualType = 'school';
  @observable noticeType = 'notice';
  @observable manualTarget = 'student';

  @action
  setEditorType = editorType => {
    this.editorType = editorType;
  };

  @action
  onChangeManualType = manualType => {
    this.manualType = manualType;
  };

  @action
  onChangeNoticeType = noticeType => {
    this.noticeType = noticeType;
  };

  @action
  onChangeManualTarget = manualTarget => {
    this.manualTarget = manualTarget;
  };

  @action
  loadEditManual = manualId => {
    if (!manualId) return;
    VrwareApi.get(`/v1.0/manuals?manualId=${manualId}`)
      .then(
        action(post => {
          this.isEdit = true;
          const manualData = post.data.data.manualList[0];
          this.manualId = manualData.manualId;
          this.manualType = manualData.manualType;
          this.manualTarget = manualData.manualTarget;
          this.title = manualData.title;
          this.markdown = manualData.content;
        })
      )
      .catch(err => {
        // console.log(err);
      });
  };
  @action
  loadEditNotice = noticeId => {
    if (noticeId) {
      VrwareApi.get(`/notices?noticeId=${noticeId}`)
        .then(
          action(post => {
            this.isEdit = true;
            const noticeData = post.data.data.noticeList[0];
            this.noticeId = noticeData.noticeId;
            this.noticeType = noticeData.noticeType;
            this.title = noticeData.title;
            this.markdown = noticeData.content;
          })
        )
        .catch(err => {
          // console.log(err);
        });
    }
  };

  @action
  showSubmitBox = () => {
    this.isSubmitBox = !this.isSubmitBox;
  };
  @action
  onChangeTitle = title => {
    this.title = title;
  };

  @action
  onChangeMarkdown = body => {
    const cursor = body.getCursor();
    const markdown = body.getValue();

    this.cursor = cursor;

    if (body !== markdown) {
      this.markdown = markdown;
    }

    if (this.description !== body.getLine(0)) {
      this.description = body.getLine(0);
    }
  };

  @action
  onClickSave = () => {
    if (!localStorage.getItem('globe_user')) return;
    const { title, markdown, editorType } = this;
    if (!title || !markdown) return;

    let saveApi;

    switch (editorType) {
      case 'notice':
        const { noticeType } = this;
        saveApi = VrwareApi.post('/v1.0/notices', {
          title: title,
          content: markdown,
          noticeType: noticeType
        });
        break;
      case 'manual':
        const { manualType, manualTarget } = this;
        saveApi = VrwareApi.post('/v1.0/manuals', {
          title: title,
          content: markdown,
          manualType: manualType,
          manualTarget: manualTarget
        });
        break;
      default:
        break;
    }

    saveApi
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.clearPostInfo();
            window.location.href = `/${editorType}s`;
            return alert('작성 성공');
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert('작성 실패');
        }
      });
  };

  @action
  onClickUpdate = () => {
    if (!localStorage.getItem('globe_user')) return;
    const { title, markdown, editorType } = this;
    if (!title || !markdown) return;
    let updateApi;

    switch (editorType) {
      case 'notice':
        const { noticeId, noticeType } = this;
        if (!noticeId) return;
        updateApi = VrwareApi.patch(`/v1.0/notices/${noticeId}`, {
          noticeId: noticeId,
          title: title,
          content: markdown,
          noticeType: noticeType
        });

        break;
      case 'manual':
        const { manualId, manualType, manualTarget } = this;
        if (!manualId) return;
        updateApi = VrwareApi.patch(`/v1.0/manuals/${manualId}`, {
          manualId: manualId,
          title: title,
          content: markdown,
          manualType: manualType,
          manualTarget: manualTarget
        });

        break;

      default:
        break;
    }
    // const email = JSON.parse(localStorage.getItem('globe_user')).email;
    updateApi
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.clearPostInfo();
            window.location.href = `/${editorType}s`;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert('수정 실패');
        }
      });
  };

  @action
  onUploadImage = files => {
    if (!localStorage.getItem('globe_user')) return;
    if (!files[0]) return;
    if (files[0].size > 1024 * 1024 * 10) return;
    const fileTypeRegex = /^image\/(.*?)/;
    if (!fileTypeRegex.test(files[0].type)) return;
    const { editorType } = this;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('filename', files[0].name);

    VrwareApi.post(`/v1.0/images/${editorType}`, data)
      .then(res => {
        if (res.data.status_code === 200) {
          let img = `\n![${res.data.data.fileName}](${res.data.data.imgUrl})\n`;
          this.insertText = img;
          this.setUploadMask(false);
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.setUploadMask(false);
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClearInsertText = () => {
    this.insertText = null;
  };

  @action
  onClickNewEditor = () => {
    this.isEdit = false;
    this.noticeId = null;
    this.title = null;
    this.markdown = null;
    this.isPrivate = false;
    this.isSubmitBox = false;
  };

  @action
  clearPostInfo = () => {
    this.title = null;
    this.markdown = null;
    this.cursor = null;
    this.thumbnail = null;
    this.description = null;
    this.tags = [];
    this.isPrivate = false;
    this.postId = null;
    this.isSubmitBox = false;
  };

  @action
  setUploadMask = boolean => {
    this.isUploadMask = boolean;
  };

  @computed
  get getIsSubmitBox() {
    return this.isSubmitBox;
  }

  @computed
  get getIsUploadMask() {
    return this.isUploadMask;
  }

  @computed
  get getEditorType() {
    return this.editorType;
  }

  @computed
  get getManualType() {
    return this.manualType;
  }
  @computed
  get getNoticeType() {
    return this.noticeType;
  }
  @computed
  get getManualTarget() {
    return this.manualTarget;
  }
}

export default new EditorStore();
