import React, { Component } from 'react';
import { NursingDetailPopup } from '.';
import { inject, observer } from 'mobx-react';

@inject('nursing')
@observer
class NursingDetailPopupContainer extends Component {
  render() {
    const { nursing, onToggleShowUsage } = this.props;
    return (
      <React.Fragment>
        <NursingDetailPopup
          showMemberDetail={nursing.showMemberDetail}

          onToggleSchoolDetail={nursing.onToggleSchoolDetail}
          onToggleShowUsage={onToggleShowUsage}
          viewname={nursing.viewname}
          viewstart={nursing.viewstart}
          viewexpired={nursing.viewexpired}
          onChangeRequestUpdateStartDate={nursing.onChangeRequestUpdateStartDate}
          onChangeRequestUpdateEndDate={nursing.onChangeRequestUpdateEndDate}
          requestUpdateEndDate={nursing.getRequestUpdateEndDate}
          requestUpdateStartDate={nursing.getRequestUpdateStartDate}
          onClickUpdateDate={nursing.onClickUpdateDate}
          



        />
      </React.Fragment>
    );
  }
}

export default NursingDetailPopupContainer;
