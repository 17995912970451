import React, { Component } from 'react';
import PaymentListOptions from './PaymentListOptions';
import { inject, observer } from 'mobx-react';

@inject('payments')
@observer
class PaymentListOptionsContainer extends Component {
  render() {
    const { payments } = this.props;
    return (
      <React.Fragment>
        <PaymentListOptions
          onClickSearch={payments.onClickSearchPayments}
          onChangeEmail={payments.onChangeEmail}
          onEnterSearchPayments={payments.onEnterSearchPayments}
          onClickClear={payments.onClearPaymentListOptions}
          onClickPaymentsExportExcel={payments.onClickPaymentsExportExcel}
        />
      </React.Fragment>
    );
  }
}

export default PaymentListOptionsContainer;
