import React, { Component } from 'react';
import { StudentDetailPopup } from '.';
import { inject, observer } from 'mobx-react';

@inject('students')
@observer
class StudentDetailPopupContainer extends Component {
  render() {
    const { students, onToggleShowUsage } = this.props;
    return (
      <React.Fragment>
        <StudentDetailPopup
          showMemberDetail={students.showMemberDetail}
          memberDetail={students.getMemberDetail}
          licenseDetail={students.getLicenseDetail}
          onToggleMemberDetail={students.onToggleMemberDetail}
          onToggleMemberUpdate={students.onToggleMemberUpdate}
          onToggleLicenseUpdate={students.onToggleLicenseUpdate}
          onToggleShowUsage={onToggleShowUsage}
        />
      </React.Fragment>
    );
  }
}

export default StudentDetailPopupContainer;
