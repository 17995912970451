import React from 'react';
import './DashBoardTemplate.scss';
import { DashBoardHeader } from '../DashBoardHeader';
import { LeftDashBoard } from '../LeftDashBoard';

const DashBoardTemplate = ({ title, tabList, onClickTab, currentTab, children }) => {
  return (
    <div className='DashBoardTemplate'>
      <LeftDashBoard />
      <main>
        <DashBoardHeader title={title} tabList={tabList} onClickTab={onClickTab} currentTab={currentTab} />
        {children}
      </main>
    </div>
  );
};

export default DashBoardTemplate;
