import React, { Component } from 'react';
import SmsSentHistoryOption from './SmsSentHistoryOption';
import { inject, observer } from 'mobx-react';

@inject('sms')
@observer
class SmsSentHistoryOptionContainer extends Component {
  render() {
    const { sms } = this.props;
    return (
      <React.Fragment>
        <SmsSentHistoryOption
          startRequestDate={sms.getStartRequestDate}
          startResultDate={sms.getStartResultDate}
          endResultDate={sms.getEndResultDate}
          endRequestDate={sms.getEndRequestDate}
          msgResultCode={sms.getMsgResultCode}
          msgStatus={sms.getMsgStatus}
          msgType={sms.getMsgType}
          onChangeRequestId={sms.onChangeRequestId}
          onChangeStartRequestDate={sms.onChangeStartRequestDate}
          onChangeEndResultDate={sms.onChangeEndResultDate}
          onChangeEndRequestDate={sms.onChangeEndRequestDate}
          onChangeMsgResultCode={sms.onChangeMsgResultCode}
          onChangeMsgStatus={sms.onChangeMsgStatus}
          onChangeMsgType={sms.onChangeMsgType}
          onChangeReceivePhoneNumber={sms.onChangeReceivePhoneNumber}
          onChangeStartResultDate={sms.onChangeStartResultDate}
          onClearSentSmsList={sms.onClearSentSmsList}
          onSearchSentSmsList={sms.onSearchSentSmsList}
        />
      </React.Fragment>
    );
  }
}

export default SmsSentHistoryOptionContainer;
