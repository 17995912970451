import React, { Component } from 'react';
import ProgramRegister from './ProgramRegister';
import { inject, observer } from 'mobx-react';

@inject('programs')
@observer
class ProgramRegisterContainer extends Component {
  render() {
    const { programs } = this.props;

    return (
      <React.Fragment>
        <ProgramRegister
          onChangeProgramName={programs.onChangeProgramName}
          onChangeProgramFileUrl={programs.onChangeProgramFileUrl}
          onChangeProgramPlatform={programs.onChangeProgramPlatform}
          onChangeProgramVersion={programs.onChangeProgramVersion}
          onClickRegisterProgram={programs.onClickRegisterProgram}
          notifyProgramName={programs.getNotifyProgramName}
          notifyProgramFileUrl={programs.getNotifyProgramFileUrl}
          notifyProgramVersion={programs.getNotifyProgramVersion}
          notifyProgramPlatform={programs.getNotifyProgramPlatform}
          onClickRegisterClear={programs.onClickRegisterClear}
          programPlatform={programs.getProgramPlatform}
        />
      </React.Fragment>
    );
  }
}

export default ProgramRegisterContainer;
