import React, { Component } from 'react';
import { MainTemplate } from '../components/main/MainTemplate';
import { LoginFormContainer } from 'components/login/LoginForm';
import { inject, observer } from 'mobx-react';
import Members from './Members';

@inject('session')
@observer
class Main extends Component {
  render() {
    const { session } = this.props;
    return <MainTemplate>{!session.isSession ? <LoginFormContainer /> : <Members />}</MainTemplate>;
  }
}

export default Main;
