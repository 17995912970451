import React from 'react';
import './LicenseCodeUsedUsers.scss';
import { dateFormat } from '../../../lib/common/dateFormat';

const LicenseCodeUsedUsers = ({ userInfo }) => {
  return (
    <div className='LicenseCodeUsedUsers'>
      <div className='license-code-used-email'>{userInfo.email}</div>
      <div className='license-code-used-date'>{dateFormat(userInfo.useDate, 'YYYY년 MM월 DD일 HH시 mm분')}</div>
    </div>
  );
};

export default LicenseCodeUsedUsers;
