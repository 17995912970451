import React from 'react';
import './MainTemplate.scss';

const MainTemplate = ({ header, children, footer }) => {
  return (
    <div className='MainTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default MainTemplate;
