import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ManualList from './ManualList';

@inject('manuals')
@observer
class ManualListContainer extends Component {
  componentDidMount() {
    const { manuals } = this.props;
    manuals.loadManualList();
  }
  render() {
    const { manuals } = this.props;
    return (
      <React.Fragment>
        <ManualList
          manualList={manuals.getManualList}
          onDeleteManual={manuals.onDeleteManual}
          count={manuals.getManualListTotalCount}
        />
      </React.Fragment>
    );
  }
}

export default ManualListContainer;
