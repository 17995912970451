import React, { Component } from 'react';
import LicenseList from './LicenseList';
import { inject, observer } from 'mobx-react';

@inject('licenses')
@observer
class LicenseListContainer extends Component {
  render() {
    const { licenses } = this.props;
    return (
      <React.Fragment>
        <LicenseList licenseList={licenses.getLicenseList} count={licenses.getLicenseListTotalCount} />
      </React.Fragment>
    );
  }
}

export default LicenseListContainer;
