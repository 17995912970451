import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { PaymentListContainer } from '../PaymentList';
import { PaymentDetailPopupContainer } from '../PaymentDetailPopup';
import { PaymentListOptionsContainer } from '../PaymentListOptions';
import PaymentPaginationContainer from '../PaymentPagination/PaymentPaginationContainer';
const PaymentDashBoard = ({ paymentTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'결제'} tabList={paymentTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<PaymentListOptionsContainer />}>
          <PaymentListContainer />
          <PaymentPaginationContainer />
          <PaymentDetailPopupContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default PaymentDashBoard;
