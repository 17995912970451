import React from 'react';
import { EmailDashBoardContainer } from '../components/email/EmailDashBoard';
import HeaderContainer from '../components/base/Header/HeaderContainer';
import { EmailSentHistoryDetailPopupContainer } from '../components/email/EmailSentHistoryDetailPopup';

const Email = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <EmailDashBoardContainer />
      <EmailSentHistoryDetailPopupContainer />
    </React.Fragment>
  );
};

export default Email;
