import { action, observable, computed } from 'mobx';
import { VrwareApi, NursingApi } from '../lib/api';

class StudentsStore {
  @observable memberList = [];
  @observable memberDetail = {};
  @observable licenseDetail = {};
  @observable memberListTotalCount = 0;
  @observable memberState = { label: '전체', value: 'all' };
  @observable memberType = { label: '전체', value: 'all' };
  @observable searchType = { label: '이메일', value: 'email' };
  @observable licenseGrade = 'lite';
  @observable expireDate = '';
  @observable isLoading = true;
  @observable limit = {
    label: '15',
    value: 15,
  };
  @observable offset = 0;
  @observable search = '';
  @observable currentPage = 1;
  @observable isSearching = false;
  @observable showMemberDetail = false;
  @observable showLicenseUpdate = false;
  @observable showMemberUpdate = false;
  @observable selectMemberType = '1'; //MemberUpdatePopup에서 사용하는 memberType 변경값
  @observable selectMemberState = '1'; //MemberUpdatePopup에서 사용하는 memberState 변경값
  @observable currentTab = 'list';


  //검색어 조건
  @observable school = '';
  @observable name = '';
  @observable uid = '';
  @observable grade = {
    label: '전체',
    value: 'all',
  };

  @observable studentList = [];
  @observable studentListTotalCount = 0;

  @observable totalPages = 0;

  @action
  onClickTab = (tab) => {
    this.currentTab = tab;
  };

  @computed
  get getCurrentTab() {
    return this.currentTab;
  }

  @action
  onToggleMemberDetail = (email) => {
    this.showMemberDetail = !this.showMemberDetail;
    if (email) {
      this.onClickMemberDetail(email);
    }
  };

  @action
  onToggleMemberUpdate = () => {
    this.showMemberDetail = false;
    this.showMemberUpdate = !this.showMemberUpdate;
  };

  @action
  onToggleLicenseUpdate = () => {
    this.showMemberDetail = false;
    this.showLicenseUpdate = !this.showLicenseUpdate;
  };

  

  @action
  onClickClear = () => {
    document.getElementById('admin-register-email').value = '';

    this.school = '';
  };

  @action
  onClickLicenseUpdate = (email) => {
    const { licenseGrade, expireDate } = this;

    VrwareApi.post(`/licenses`, {
      email: email,
      licenseGrade: licenseGrade,
      expireDate: expireDate,
      group: 'GP',
      licenseType: 'school',
    })
      .then(
        action((res) => {
          if (res.data.status_code === 200) {
            this.showLicenseUpdate = false;
            alert(res.data.alert_message);
          }
        }),
      )
      .catch((err) => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickMemberUpdate = (email) => {
    const { selectMemberType, selectMemberState } = this;
    if (!selectMemberType) return;
    if (!selectMemberState) return;

    VrwareApi.patch(`/members/${email}`, {
      memberType: selectMemberType,
      memberState: selectMemberState,
    })
      .then(
        action((res) => {
          if (res.data.status_code === 200) {
            this.showMemberUpdate = false;
            alert(res.data.alert_message);
            this.onClickSearch();
          }
        }),
      )
      .catch((err) => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onChangePage = (page) => {
    this.currentPage = page;
    this.offset = page-1;
    this.onClickStudentSearch();
  };

  @action
  onChangeLicenseGrade = (licenseGrade) => {
    this.licenseGrade = licenseGrade;
  };

  @action
  onChangeExpireDate = (expireDate) => {
    this.expireDate = expireDate;
  };

  @action
  onChangeLimit = (limit) => {
    this.limit = limit;
    this.offset = 0;
    this.currentPage = 1;
    this.memberList = [];
    this.onClickStudentSearch(true);
  };

  @computed
  get getLimit() {
    return this.limit;
  }

  @action
  onChangeMemberState = (memberState) => {
    this.memberState = memberState;
    this.offset = 0;
    this.currentPage = 1;
    this.memberList = [];
    this.onClickSearch(true);
  };

  @computed
  get getMemberState() {
    return this.memberState;
  }

  @action
  onChangeMemberType = (memberType) => {
    this.memberType = memberType;
    this.offset = 0;
    this.memberList = [];
    this.currentPage = 1;
    this.onClickSearch(true);
  };

  @computed
  get getMemberType() {
    return this.memberType;
  }

  @computed
  get getExpireDate() {
    return this.expireDate;
  }

  @action
  onChangeSelectMemberType = (memberType) => {
    this.selectMemberType = memberType;
  };

  @action
  onChangeSelectMemberState = (memberState) => {
    this.selectMemberState = memberState;
  };

  @action
  onChangeSearchType = (searchType) => {
    this.searchType = searchType;
  };

  @computed
  get getSearchType() {
    return this.searchType;
  }

  @action
  onEnterSearch = (e) => {
    if (e.key === 'Enter') {
      this.onClickStudentSearch(true);
    }
  };

  @action
  onChangeSearch = (search) => {
    this.search = search;
  };

  @action
  onClickMemberDetail = (email) => {
    VrwareApi.get(`/members?email=${email}`)
      .then(
        action((res) => {
          if (res.data.status_code === 200) {
            this.memberDetail = res.data.data.memberInfo[0];

            return VrwareApi.get(`/licenses?email=${email}&licenseType=school`);
          }
        }),
      )
      .then(
        action((_res) => {
          if (_res.data.status_code === 200) {
            this.licenseDetail = _res.data.data.licenseList[0];
            this.expireDate = new Date(_res.data.data.licenseList[0].expireDate);
            this.licenseGrade = _res.data.data.licenseList[0].licenseGrade;
          }
        }),
      );
  };

  @action
  onClickSearch = (isSearch) => {
    // //검색 시 처음부터 Loadmore 가능하게
    if (isSearch) {
      this.offset = 0;
      this.currentPage = 1;
    }
    VrwareApi.get(
      `/members?limit=${this.limit.value}&offset=${this.offset}&q=${this.search}&state=${this.memberState.value}&type=${this.memberType.value}&t=${this.searchType.value}`,
    )
      .then(
        action((res) => {
          if (res.data.status_code === 200) {
            this.memberList = res.data.data.memberList;
            this.memberListTotalCount = res.data.data.memberListTotalCount;

            this.isLoading = false;
          }
        }),
      )
      .catch(
        action((err) => {
          if (err.response.data.status_code >= 400) {
            return alert(err.response.data.alert_message);
          }
        }),
      );
  };

  @computed
  get getMemberList() {
    return this.memberList;
  }

  @computed
  get getMemberDetail() {
    return this.memberDetail;
  }

  @computed
  get getLicenseDetail() {
    return this.licenseDetail;
  }




  @computed
  get getGrade() {
    return this.grade;
  }

  @action
  onChangeGrade = (grade) => {
    this.grade = grade;
  };

  @action
  onChangeSchool = (school) => {
    this.school = school;
  };

  @action
  onChangeName= (name) => {
    this.name = name;
  };

  @action
  onChangeUid = (uid) => {
    this.uid = uid;
  };

  @computed
  get getStudentList() {
    return this.studentList;
  }
  
  @computed
  get getTotalPages() {
    return this.totalPages;
  }

  @action
  onClickClearMemberList = () => {
    document.getElementById('member-list-search1').value = '';
    document.getElementById('member-list-search2').value = '';
    document.getElementById('member-list-search3').value = '';

    this.limit = {
      label: '15',
      value: 15,
    };

    this.grade = {
      label: '전체',
      value: 'all',
    };
  
    this.memberState = { label: '전체', value: 'all' };
    this.memberType = { label: '전체', value: 'all' };
    this.searchType = { label: '이메일', value: 'email' };
    this.search = '';
    
    this.uid = '';
    this.name = '';
    this.school = '';
    this.onClickStudentSearch(true);
  };


  @action
  onClickStudentSearch = (isSearch) => {
    // //검색 시 처음부터 Loadmore 가능하게
    if (isSearch) {
      this.offset = 0;
      this.currentPage = 1;
    }
    NursingApi.get(
      `/students?school=${this.school}&name=${this.name}&uid=${this.uid}&grade=${this.grade.value}&limit=${this.limit.value}&offset=${this.offset}`,
    )
      .then(
        action((res) => {
 
            this.studentList = res.data.content;
            this.studentListTotalCount = res.data.totalElements;
            this.isLoading = false;
            this.totalPages = res.data.totalPages;
          
        }),
      )
  };



}

export default new StudentsStore();
