import React, { Component } from 'react';
import PromotionRegister from './PromotionRegister';
import { inject, observer } from 'mobx-react';

@inject('promotions')
@observer
class PromotionRegisterContainer extends Component {
  render() {
    const { promotions } = this.props;

    return (
      <React.Fragment>
        <PromotionRegister
          onClickRegisterPromotion={promotions.onClickRegisterPromotion}
          onClickClear={promotions.onClickClear}
          onChangePromotionCode={promotions.onChangePromotionCode}
          onChangeIssuer={promotions.onChangeIssuer}
          onChangeDcRate={promotions.onChangeDcRate}
          onChangeExpireDate={promotions.onChangeExpireDate}
          expireDate={promotions.getExpireDate}
        />
      </React.Fragment>
    );
  }
}

export default PromotionRegisterContainer;
