import React from 'react';
import { Link } from 'react-router-dom';
import './UserMenu.scss';

const UserMenu = ({ onClick, onLogout }) => {
  return (
    <div className='UserMenu' onClick={onClick}>
      <ul className='user-menu-wrap'>
        <Link to={`/settings`}>
          <li className='user-menu-item'>설정</li>
        </Link>
        <li className='user-menu-item' onClick={onLogout}>
          로그아웃
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
