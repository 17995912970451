import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import { ProgramDashBoardContainer } from '../components/programs/ProgramDashBoard';

const Programs = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <ProgramDashBoardContainer />
    </React.Fragment>
  );
};

export default Programs;
