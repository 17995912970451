import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { NoticeListOptionsContainer } from '../NoticeListOptions';
import { NoticeListContainer } from '../NoticeList';

const NoticeDashBoard = ({ noticeTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'공지사항'} tabList={noticeTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<NoticeListOptionsContainer />}>
          <NoticeListContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default NoticeDashBoard;
