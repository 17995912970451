import React from 'react';
import { HeaderContainer } from '../components/base/Header';
import SmsDashBoardContainer from '../components/sms/SmsDashBoard/SmsDashBoardContainer';

export const Sms = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <SmsDashBoardContainer />
    </React.Fragment>
  );
};

export default Sms;
