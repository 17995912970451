import React, { Component } from 'react';
import AuthDashBoard from './AuthDashBoard';
import { inject, observer } from 'mobx-react';

@inject('auths', 'session')
@observer
class AuthDashBoardContainer extends Component {
  render() {
    const { auths, session } = this.props;
    return (
      <React.Fragment>
        <AuthDashBoard
          tabList={authTabList}
          currentTab={auths.getCurrentTab}
          onClickTab={auths.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const authTabList = [
  {
    value: 'list',
    text: 'API 인증 키 목록'
  }
];

export default AuthDashBoardContainer;
