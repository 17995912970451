import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ManualDashBoard from './ManualDashBoard';

@inject('manuals', 'session')
@observer
class ManualDashBoardContainer extends Component {
  render() {
    const { manuals, session } = this.props;
    return (
      <React.Fragment>
        <ManualDashBoard
          tabList={tabList}
          currentTab={manuals.getCurrentTab}
          isSession={session.isSession}
          onClickTab={manuals.onClickTab}
        />
      </React.Fragment>
    );
  }
}

const tabList = [
  {
    value: 'list',
    text: '매뉴얼 목록'
  }
];

export default ManualDashBoardContainer;
