import React, { Component } from 'react';
import { LicenseUpdatePopup } from '.';
import { inject, observer } from 'mobx-react';

@inject('members')
@observer
class LicenseUpdatePopupContainer extends Component {
  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        <LicenseUpdatePopup
          onChangeLicenseGrade={members.onChangeLicenseGrade}
          onToggleLicenseUpdate={members.onToggleLicenseUpdate}
          showLicenseUpdate={members.showLicenseUpdate}
          memberDetail={members.getMemberDetail}
          licenseDetail={members.getLicenseDetail}
          onChangeExpireDate={members.onChangeExpireDate}
          onClickLicenseUpdate={members.onClickLicenseUpdate}
          expireDate={members.getExpireDate}
        />
      </React.Fragment>
    );
  }
}

export default LicenseUpdatePopupContainer;
