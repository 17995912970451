import React from 'react';
import './PaymentListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Button, Icon } from 'semantic-ui-react';

const PaymentListOptions = ({
  onClickClear,
  onClickSearch,
  onChangeEmail,
  onEnterSearchPayments,
  onClickPaymentsExportExcel
}) => {
  return (
    <div className='PaymentListOptions'>
      <div className='payment-list-option-section'>
        <LabelWithNode
          label={'구매자'}
          node={
            <input
              id='payment-list-email'
              type='text'
              placeholder={'검색하실 이메일을 입력해 주세요.'}
              onChange={e => onChangeEmail(e.target.value)}
              onKeyPress={onEnterSearchPayments}
            ></input>
          }
        ></LabelWithNode>
      </div>
      <div className='payment-list-option-section'>
        <LabelWithNode
          label={'리스트 다운로드'}
          node={
            <Button size='small' color='blue' icon labelPosition='left' onClick={onClickPaymentsExportExcel}>
              <Icon name='download' />
              CSV 파일로 받기
            </Button>
          }
        ></LabelWithNode>
      </div>
      <div className='payment-list-option-section'>
        <div className='payment-list-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickSearch} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

export default PaymentListOptions;
