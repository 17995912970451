import React, { Component } from 'react';
import PaymentPagination from './PaymentPagination';
import { inject, observer } from 'mobx-react';

@inject('payments')
@observer
class PaymentPaginationContainer extends Component {
  render() {
    const { payments } = this.props;

    return (
      <React.Fragment>
        <PaymentPagination
          currentPage={payments.getCurrentPage}
          limit={payments.getLimit}
          onChangePage={payments.onChangePage}
          total={payments.getPaymentListTotalCount}
        />
      </React.Fragment>
    );
  }
}

export default PaymentPaginationContainer;
