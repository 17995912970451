import React from 'react';
import './SmsDelivery.scss';
import DateTimePicker from 'react-datetime-picker';
import TextareaAutosize from 'react-autosize-textarea';
import { SmsRecipientListContainer } from '../SmsRecipientList';
import Select from 'react-select';
import { LabelWithNode } from '../../common/LabelWithNode';

const SmsDelivery = ({
  requestDate,
  onChangeMessageType,
  onChangeRequestDate,
  onChangeTitle,
  onChangeTextareaBody,
  messageType,
  onClickSendMessage,
  onClickClear,
  onInsertRecipient,
  titleBytes,
  bodyBytes,
  notifyTitle,
  notifyBody
}) => {
  return (
    <div className="SmsDelivery">
      <div className="sms-delivery-section">
        <LabelWithNode
          label={'발송 유형'}
          node={
            <Select
              id="sms-message-type"
              defaultValue={messageTypeList[0]}
              value={messageType}
              onChange={onChangeMessageType}
              options={messageTypeList}
            ></Select>
          }
        />
      </div>
      <div className="sms-delivery-section">
        <LabelWithNode label={'발신 번호'} node={<span>070-7932-2585</span>} />
      </div>
      <div className="sms-delivery-section">
        <LabelWithNode
          label={'예약 발송'}
          node={<DateTimePicker id="sms-date-picker" onChange={onChangeRequestDate} value={requestDate} />}
        />
      </div>
      <div className="sms-delivery-section">
        <LabelWithNode
          label={'제목'}
          node={
            <input
              id="sms-input-title"
              type="text"
              placeholder={'제목을 입력해 주세요.'}
              onChange={e => onChangeTitle(e.target.value)}
              disabled={messageType.value === '0' && true}
            />
          }
          notifyLeft={notifyTitle}
          notifyRight={`${titleBytes}/${messageType.titleMaxBytes} Bytes (최대 ${messageType.titleMaxLength}자)`}
        />
      </div>
      <div className="sms-delivery-section">
        <LabelWithNode
          label={'내용'}
          node={
            <TextareaAutosize
              id="sms-textarea-body"
              placeholder={'내용을 입력해 주세요.'}
              onChange={e => onChangeTextareaBody(e.target.value)}
              rows={5}
            />
          }
          notifyLeft={notifyBody}
          notifyRight={`${bodyBytes}/${messageType.maxBytes} Bytes (최대 ${messageType.maxLength}자)`}
        />
      </div>
      <div className="sms-delivery-section">
        <LabelWithNode
          label={'수신번호 목록'}
          node={<input type="text" onKeyUp={onInsertRecipient} placeholder={'(-)없이 숫자만 입력 후 Enter.'} />}
        />
        <SmsRecipientListContainer />
      </div>
      <div className="sms-delivery-section">
        <div className="sms-delivery-btns">
          <div className="sms-delivery-btn">
            <button className="sms-send-btn" onClick={onClickSendMessage}>
              보내기
            </button>
          </div>
          <div className="sms-delivery-btn">
            <button className="sms-clear-btn" onClick={onClickClear}>
              초기화
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const messageTypeList = [
  { value: '0', label: 'SMS', maxBytes: '90', maxLength: '255', titleMaxBytes: '40', titleMaxLength: '120' },
  { value: '1', label: 'MMS', maxBytes: '2000', maxLength: '4000', titleMaxBytes: '40', titleMaxLength: '120' }
];

export default SmsDelivery;
