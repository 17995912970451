import React from 'react';
import './MemberItem.scss';

import { dateFormat } from '../../../lib/common/dateFormat';
import { Table } from 'semantic-ui-react';
const MemberItem = ({ index, member, onToggleMemberDetail }) => {
  return (
    <Table.Row className='MemberItem'>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell onClick={() => onToggleMemberDetail(member.email)} className='member-email'>
        {member.email}
      </Table.Cell>
      <Table.Cell>{member.nickname}</Table.Cell>
      <Table.Cell>
        {(member.memberType === '1' && '일반') ||
          (member.memberType === '2' && '학생') ||
          (member.memberType === '3' && '선생님') ||
          (!member.memberType && '일반')}
      </Table.Cell>
      <Table.Cell>
        {(member.memberState === '0' && '가입대기') ||
          (member.memberState === '1' && '정상') ||
          (member.memberState === '2' && '휴면') ||
          (member.memberState === '3' && '정지') ||
          (member.memberState === '4' && '탈퇴')}
      </Table.Cell>
      <Table.Cell>{dateFormat(member.joinDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell>
        {member.lastLoginDate ? dateFormat(member.lastLoginDate, 'YYYY년 MM월 DD일 HH시 mm분') : '로그인 이력 없음'}
      </Table.Cell>
    </Table.Row>
  );
};

export default MemberItem;
