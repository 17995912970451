import React from 'react';
import './ManualList.scss';
import { Table } from 'semantic-ui-react';
import ManualItem from '../ManualItem/ManualItem';

const ManualList = ({ manualList, onDeleteManual, count }) => {
  return (
    <div className='ManualList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.HeaderCell>매뉴얼 타입</Table.HeaderCell>
            <Table.HeaderCell>매뉴얼 대상</Table.HeaderCell>
            <Table.HeaderCell>작성일</Table.HeaderCell>
            <Table.HeaderCell>수정일</Table.HeaderCell>
            <Table.HeaderCell>수정</Table.HeaderCell>
            <Table.HeaderCell>삭제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {manualList.map(manualInfo => (
            <ManualItem manualInfo={manualInfo} onDeleteManual={onDeleteManual} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ManualList;
