import React from 'react';
import './MapList.scss';
import { Pagination } from '../../common/Pagination';
import getExcerpt from '../../../lib/common/excerpt';

const MapList = ({ mapList, totalCount, onChangePage, currentPage, onClickMapDetail }) => {
  return (
    <>
      <div className='MapList'>
        {mapList.map(mapInfo => (
          <div className='MapItem' onClick={() => onClickMapDetail(mapInfo.map_id)} key={mapInfo.map_id}>
            <div className='map-item-wrap'>
              <div
                className='map-thumbnail'
                style={{
                  backgroundImage: `url(${mapInfo.imgSmallThumbUrl ? mapInfo.imgSmallThumbUrl : mapInfo.imgThumbUrl})`
                }}
              ></div>
              <div className='map-info-wrap'>
                <h2>{mapInfo.title}</h2>
                <p className='map-desc'>{mapInfo.desc && getExcerpt(mapInfo.desc, 100)}</p>
                <p className='map-producer'>{mapInfo.producer && `by ${mapInfo.producer}`}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination totalPages={totalCount} onChangePage={onChangePage} currentPage={currentPage} />
    </>
  );
};

export default MapList;
