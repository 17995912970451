import React, { Component } from 'react';
import ProfessorList from './ProfessorList';

import { inject, observer } from 'mobx-react';

@inject('professors')
@observer
class ProfessorListContainer extends Component {
  render() {
    const { professors } = this.props;
    return (
      <React.Fragment>
        <ProfessorList
          professorList={professors.getProfessorList}
          isLoading={professors.isLoading}
          onToggleMemberDetail={professors.onToggleMemberDetail}
          count={professors.professorListTotalCount}
        />
      </React.Fragment>
    );
  }
}

export default ProfessorListContainer;
