import axios from "axios";

const API_URI = "https://nursing.mrware.us/api/vrware";
const LOCAL_API_URI = "http://localhost:8008/api/vrware";

let Axios;

if (process.env.NODE_ENV === "production") {
  Axios = axios.create({
    baseURL: API_URI,
    timeout: 60000
  });
} else if (process.env.NODE_ENV === "development") {
  Axios = axios.create({
    baseURL: LOCAL_API_URI,
    timeout: 60000
  });
}

export default Axios;

export const getUserPostList = nickname => {
  return Axios.get(`${API_URI}/blog/post?nickname=${nickname}`);
};