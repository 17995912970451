import React, { Component } from 'react';
import ProgramDashBoard from './ProgramDashBoard';
import { inject, observer } from 'mobx-react';

@inject('programs', 'session')
@observer
class ProgramDashBoardContainer extends Component {
  render() {
    const { programs, session } = this.props;
    return (
      <React.Fragment>
        <ProgramDashBoard
          tabList={programTabList}
          currentTab={programs.getCurrentTab}
          onClickTab={programs.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const programTabList = [
  {
    value: 'add',
    text: '프로그램 등록'
  },
  {
    value: 'list',
    text: '프로그램 목록'
  }
];

export default ProgramDashBoardContainer;
