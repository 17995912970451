import React from 'react';
import './PromotionListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Button, Icon } from 'semantic-ui-react';

const PromotionListOptions = ({
  onChangeSearchPromotionCode,
  onChangeSearchIssuer,
  onEnterSearch,
  onClickSearch,
  onClickSearchClear
}) => {
  return (
    <div className='PromotionListOptions'>
      <div className='promotion-list-option-section'>
        <LabelWithNode
          label={'발급자'}
          node={
            <input
              id='promotion-issuer-search'
              type='text'
              name='search'
              placeholder='검색할 발급자를 입력해주세요.'
              onChange={e => onChangeSearchIssuer(e.target.value)}
              onKeyPress={onEnterSearch}
            ></input>
          }
        ></LabelWithNode>
      </div>
      <div className='promotion-list-option-section'>
        <LabelWithNode
          label={'프로모션 코드 (기업코드)'}
          node={
            <input
              id='promotion-code-search'
              type='text'
              name='search'
              placeholder='검색할 프로모션 코드를 입력해주세요.'
              onChange={e => onChangeSearchPromotionCode(e.target.value)}
              onKeyPress={onEnterSearch}
            ></input>
          }
        ></LabelWithNode>
      </div>
      <div className='promotion-list-option-section'>
        <div className='promotion-list-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickSearch} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickSearchClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

export default PromotionListOptions;
