import React, { Component } from 'react';
import MapListOptions from './MapListOptions';
import { inject, observer } from 'mobx-react';

@inject('maps')
@observer
class MapListOptionsContainer extends Component {
  render() {
    const { maps } = this.props;
    return (
      <React.Fragment>
        <MapListOptions
          onClickSearchMap={maps.onClickSearchMap}
          onChangeSearchMapTitle={maps.onChangeSearchMapTitle}
          onEnterSearchMap={maps.onEnterSearchMap}
          onChangeSearchMapEmail={maps.onChangeSearchMapEmail}
          onClickSearchMapClear={maps.onClickSearchMapClear}
          isShared={maps.getIsShared}
          onToggleIsShared={maps.onToggleIsShared}
          onToggleIsGpShared={maps.onToggleIsGpShared}
          isGpShared={maps.getIsGpShared}
        />
      </React.Fragment>
    );
  }
}

export default MapListOptionsContainer;
