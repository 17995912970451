import React, { Component } from 'react';
import MemberListPagination from './MemberListPagination';
import { inject, observer } from 'mobx-react';

@inject('members')
@observer
class MemberListPaginationContainer extends Component {
  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        <MemberListPagination
          currentPage={members.currentPage}
          limit={members.getLimit}
          memberListTotalCount={members.memberListTotalCount}
          onChangePage={members.onChangePage}
        />
      </React.Fragment>
    );
  }
}

export default MemberListPaginationContainer;
