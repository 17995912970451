import React, { Component } from 'react';
import { StudentAddOptions } from '.';
import { inject, observer } from 'mobx-react';

@inject('studentAdd')
@observer
class StudentAddOptionsContainer extends Component {
  render() {
    const { studentAdd } = this.props;
    return (
      <React.Fragment>
        <StudentAddOptions onFileLoadAndStudentSignUp={studentAdd.onFileLoadAndStudentSignUp} />
      </React.Fragment>
    );
  }
}

export default StudentAddOptionsContainer;
