import React from 'react';
import './LicenseUpdatePopup.scss';
import { Item, Segment, Select, Button } from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';
import { Popup } from '../../common/Popup';

const LicenseUpdatePopup = ({
  showLicenseUpdate,
  onToggleLicenseUpdate,
  memberDetail,
  licenseDetail,
  onChangeLicenseGrade,
  onChangeExpireDate,
  onClickLicenseUpdate,
  expireDate,
}) => {
  const licenseGrade = [
    { text: 'lite', value: 'lite' },
    { text: 'home', value: 'home' },
    { text: 'tutor', value: 'tutor' },
    { text: 'lab', value: 'lab' },
    { text: 'class', value: 'class' },
    {
      text: 'Academy',
      value: 'academy',
    },
  ];
  return (
    <div className='LicenseUpdatePopup'>
      <Popup
        isOpen={showLicenseUpdate}
        title={'라이선스 수정'}
        onTogglePopup={() => onToggleLicenseUpdate()}
        btns={
          <Button positive onClick={() => onClickLicenseUpdate(memberDetail.email)}>
            수정하기
          </Button>
        }
      >
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>이메일</Item.Header>
              <Item.Description>
                <Segment>{memberDetail.email}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>닉네임</Item.Header>
              <Item.Description>
                <Segment>{memberDetail.nickname}</Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>라이선스 등급</Item.Header>
              <Item.Description>
                <Segment>
                  <Select
                    placeholder='Select member type'
                    options={licenseGrade}
                    onChange={(e, { value }) => onChangeLicenseGrade(value)}
                    defaultValue={licenseDetail.licenseGrade || 'lite'}
                  />
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Header>라이선스 만료일</Item.Header>
              <Item.Description>
                <Segment>
                  <DateTimePicker onChange={onChangeExpireDate} value={expireDate} />
                </Segment>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Popup>
    </div>
  );
};

export default LicenseUpdatePopup;
