import React, { Component } from 'react';
import { ManualListOptions } from '.';
import { inject, observer } from 'mobx-react';

@inject('editor')
@observer
class ManualListOptionsContainer extends Component {
  render() {
    const { editor } = this.props;

    return (
      <React.Fragment>
        <ManualListOptions onClickNewEditor={editor.onClickNewEditor} />
      </React.Fragment>
    );
  }
}

export default ManualListOptionsContainer;
