import React, { Component } from 'react';
import MemberList from './StudentList';

import { inject, observer } from 'mobx-react';

@inject('students')
@observer
class StudentListContainer extends Component {
  render() {
    const { students } = this.props;
    return (
      <React.Fragment>
        <MemberList
          studentList={students.getStudentList}
          isLoading={students.isLoading}
          onToggleMemberDetail={students.onToggleMemberDetail}
          count={students.studentListTotalCount}
        />
      </React.Fragment>
    );
  }
}

export default StudentListContainer;
