import React, { Component } from 'react';
import MemberListOptions from './MemberListOptions';

import { inject, observer } from 'mobx-react';

@inject('members')
@observer
class MemberListOptionsContainer extends Component {
  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        <MemberListOptions
          onChangeSearch={members.onChangeSearch}
          onClickSearch={members.onClickSearch}
          onEnterSearch={members.onEnterSearch}
          onChangeLimit={members.onChangeLimit}
          onChangeMemberState={members.onChangeMemberState}
          onChangeMemberType={members.onChangeMemberType}
          onChangeSearchType={members.onChangeSearchType}
          limit={members.getLimit}
          memberState={members.getMemberState}
          memberType={members.getMemberType}
          searchType={members.getSearchType}
          onClickClear={members.onClickClearMemberList}
        />
      </React.Fragment>
    );
  }
}

export default MemberListOptionsContainer;
