import React, { Component } from 'react';
import MapDashBoard from './MapDashBoard';
import { inject, observer } from 'mobx-react';

@inject('maps', 'session')
@observer
class MapDashBoardContainer extends Component {
  render() {
    const { maps, session } = this.props;
    return (
      <React.Fragment>
        <MapDashBoard
          tabList={tabList}
          currentTab={maps.getCurrentTab}
          onClickTab={maps.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const tabList = [{ value: 'list', text: '맵 목록' }];
export default MapDashBoardContainer;
