import React, { Component } from 'react';
import { MemberAddOptions } from '.';
import { inject, observer } from 'mobx-react';

@inject('memberAdd')
@observer
class MemberAddOptionsContainer extends Component {
  render() {
    const { memberAdd } = this.props;
    return (
      <React.Fragment>
        <MemberAddOptions onFileLoadAndSignUp={memberAdd.onFileLoadAndSignUp} />
      </React.Fragment>
    );
  }
}

export default MemberAddOptionsContainer;
