import React, { Component } from 'react';
import PromotionDashBoard from './PromotionDashBoard';
import { inject, observer } from 'mobx-react';

@inject('promotions', 'session')
@observer
class PromotionDashBoardContainer extends Component {
  render() {
    const { promotions, session } = this.props;
    return (
      <React.Fragment>
        <PromotionDashBoard
          promotionTabList={promotionTabList}
          currentTab={promotions.getCurrentTab}
          onClickTab={promotions.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const promotionTabList = [
  {
    value: 'add',
    text: '프로모션 등록'
  },
  {
    value: 'list',
    text: '프로모션 목록'
  }
];

export default PromotionDashBoardContainer;
