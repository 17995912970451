import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { LicenseCodeList } from '.';

@inject('licenseCodes')
@observer
class LicenseCodeListContainer extends Component {
  render() {
    const { licenseCodes } = this.props;
    return (
      <React.Fragment>
        <LicenseCodeList
          licenseCodeList={licenseCodes.getLicenseCodeList}
          currentPage={licenseCodes.getCurrentPage}
          totalPages={licenseCodes.getTotalPages}
          onChangePage={licenseCodes.onChangePage}
          onClickDetail={licenseCodes.onClickLicenseCodeDetail}
        />
      </React.Fragment>
    );
  }
}

export default LicenseCodeListContainer;
