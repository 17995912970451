import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PromotionUpdatePopup from './PromotionUpdatePopup';

@inject('promotions')
@observer
class PromotionUpdatePopupContainer extends Component {
  render() {
    const { promotions } = this.props;
    return (
      <React.Fragment>
        <PromotionUpdatePopup
          isShown={promotions.getIsShownUpdatePromotion}
          promotionInfo={promotions.getPromotionDetail}
          onToggleUpdatePromotion={promotions.onToggleUpdatePromotion}
          onChangeUpdateDcRate={promotions.onChangeUpdateDcRate}
          onChangeUpdateExpireDate={promotions.onChangeUpdateExpireDate}
          onChangeUpdateIssuer={promotions.onChangeUpdateIssuer}
          onChangeUpdatePromotionCode={promotions.onChangeUpdatePromotionCode}
          updateExpireDate={promotions.getUpdateExpireDate}
          onClickUpdatePromotion={promotions.onClickUpdatePromotion}
        />
      </React.Fragment>
    );
  }
}

export default PromotionUpdatePopupContainer;
