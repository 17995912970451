import React, { Component } from 'react';
import AdminListOptions from './AdminListOptions';
import { inject, observer } from 'mobx-react';

@inject('admins')
@observer
class AdminListOptionsContainer extends Component {
  render() {
    const { admins } = this.props;
    return (
      <React.Fragment>
        <AdminListOptions
          onClickSearch={admins.onClickSearch}
          onEnterSearch={admins.onEnterSearch}
          onChangeSearchAdminEmail={admins.onChangeSearchAdminEmail}
        />
      </React.Fragment>
    );
  }
}

export default AdminListOptionsContainer;
