import React, { Component } from 'react';
import NursingListOptions from './NursingListOptions';
import { inject, observer } from 'mobx-react';

@inject('nursing')
@observer
class NursingListOptionsContainer extends Component {
  render() {
    const { nursing } = this.props;
    return (
      <React.Fragment>
        <NursingListOptions
          onClickSearch={nursing.onClickSearch}
          onEnterSearch={nursing.onEnterSearch}
          onChangeSchool={nursing.onChangeSchool}
          onChangeRequestStartDate={nursing.onChangeRequestStartDate}
          onChangeRequestEndDate={nursing.onChangeRequestEndDate}
          requestStartDate={nursing.getRequestStartDate}
          requestEndDate={nursing.getRequestEndDate}
        />
      </React.Fragment>
    );
  }
}

export default NursingListOptionsContainer;
