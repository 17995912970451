import React from 'react';
import './SmsSentHistoryListPagination.scss';
import { Pagination } from '../../common/Pagination';

const SmsSentHistoryListPagination = ({ smsListTotalCount, onChangePage, currentPage, limit }) => {
  if (!smsListTotalCount) return null;
  return (
    <div className='SmsSentHistoryListPagination'>
      <Pagination
        onChangePage={onChangePage}
        currentPage={currentPage}
        totalPages={Math.floor(smsListTotalCount / limit)}
      />
    </div>
  );
};

export default SmsSentHistoryListPagination;
