import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { LicenseCodeRegisterContainer } from '../LicenseCodeRegister';
import { LicenseCodeListContainer } from '../LicenseCodeList';
import { LicenseCodeListOptionsContainer } from '../LicenseCodeListOptions';
import LicenseCodeDetailPopupContainer from '../LicenseCodeDetailPopup/LicenseCodeDetailPopupContainer';

const LicenseCodeDashBoard = ({ tabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'라이선스 코드'} tabList={tabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<LicenseCodeListOptionsContainer />}>
          <LicenseCodeListContainer />
          <LicenseCodeDetailPopupContainer />
        </DashBoardBody>
      )}
      {currentTab === 'add' && (
        <DashBoardBody>
          <LicenseCodeRegisterContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default LicenseCodeDashBoard;
