import React from 'react';
import './SettingTemplate.scss';

export const SettingTemplate = ({ children }) => {
  return (
    <div className="SettingTemplate">
      <main>{children}</main>
    </div>
  );
};
