import React from 'react';
import './EmailSentHistoryListPagination.scss';
import Pagination from 'rc-pagination';

const EmailSentHistoryListPagination = ({ emailListTotalCount, onChangePage, currentPage, limit }) => {
  if (!emailListTotalCount) return null;
  return (
    <div className="EmailSentHistoryListPagination">
      <Pagination onChange={onChangePage} current={currentPage} pageSize={limit} total={emailListTotalCount} />
    </div>
  );
};

export default EmailSentHistoryListPagination;
