import React from 'react';
import CSVReader from 'react-csv-reader';
import './ProfessorAddOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
const ProfessorAddOptions = ({ onFileLoadAndProfessorSignUp }) => {
  return (
    <div className="MemberAddOptions">
      <LabelWithNode
        label={'일괄 가입'}
        node={
          <div className="member-csv-wrap">
            <div className="csv-template-download">
              <a href="/NursingSkills_Professor_Insert_Form.csv">템플릿다운로드</a>
            </div>
            <div className="member-csv-upload">
              <input className="member-csv-file-name" id="member-csv-file-name" value="파일선택" disabled="disabled" />
              <label htmlFor="member-csv-file">업로드</label>
              <CSVReader
                onFileLoaded={data => onFileLoadAndProfessorSignUp(data)}
                parserOptions={parseOptions}
                inputId={'member-csv-file'}
              />
            </div>
          </div>
        }
      ></LabelWithNode>
    </div>
  );
};

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true
};

export default ProfessorAddOptions;
