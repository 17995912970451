import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import EmailCarbonCopyList from './EmailCarbonCopyList';

@inject('email')
@observer
class EmailCarbonCopyListContainer extends Component {
  render() {
    const { email } = this.props;
    return (
      <React.Fragment>
        <EmailCarbonCopyList carbonCopyList={email.getCarbonCopyList} onDeleteCarbonCopy={email.onDeleteCarbonCopy} />
      </React.Fragment>
    );
  }
}

export default EmailCarbonCopyListContainer;
