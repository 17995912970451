import React from 'react';
import './NursingListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button, Icon } from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';

const NursingListOptions = ({ onClickSearch, onEnterSearch, onChangeSchool,
  onChangeRequestStartDate,onChangeRequestEndDate,requestStartDate,requestEndDate


}) => {
  return (
    <div className='AdminListOptions'>
      <div className='admin-list-options-section'>
        <LabelWithNode
          label={'학교명'}
          node={
            <Input
              fluid
              placeholder={'학교명을 입력해 주세요.'}
              onKeyPress={onEnterSearch}
              onChange={e => onChangeSchool(e.target.value)}
            />
          }
        />
      </div>

      <div className='date-group'>
      <div className="admin-list-options-section">
        <LabelWithNode
          label={'시작일'}
          node={<DateTimePicker id="sms-date-pickerr"   onChange={onChangeRequestStartDate} value={requestStartDate} />}
        />
      </div>

      <div className="admin-list-options-section">
        <LabelWithNode
          label={'종료일'}
          node={<DateTimePicker id="sms-date-picker"  onChange={onChangeRequestEndDate} value={requestEndDate} />}
        />
      </div>

      </div>
      <div className='admin-list-options-section'>
        <div className='admin-list-options-btns'>
          <Button color='blue' icon labelPosition='right' onClick={onClickSearch}>
            검색
            <Icon name='search' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NursingListOptions;
