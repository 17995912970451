import React from 'react';
import { Icon, Pagination } from 'semantic-ui-react';
import './Pagination.scss';
const ListPagination = ({ totalPages, currentPage, onChangePage }) => {
  if (totalPages === 0) return null;
  return (
    <div className='ListPagination'>
      <Pagination
        activePage={currentPage}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
        totalPages={totalPages}
        onPageChange={(e, { activePage }) => onChangePage(activePage)}
      />
    </div>
  );
};

export default ListPagination;
