import React, { Component } from 'react';
import PromotionList from './PromotionList';
import { inject, observer } from 'mobx-react';

@inject('promotions')
@observer
class PromotionListContainer extends Component {
  render() {
    const { promotions } = this.props;

    return (
      <React.Fragment>
        <PromotionList
          promotionList={promotions.getPromotionList}
          count={promotions.getPromotionListTotalCount}
          onClickPromotionDetail={promotions.onClickPromotionDetail}
        />
      </React.Fragment>
    );
  }
}

export default PromotionListContainer;
