import React, { Component } from 'react';
import MemberList from './MemberList';

import { inject, observer } from 'mobx-react';

@inject('members')
@observer
class MemberListContainer extends Component {
  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        <MemberList
          licenseInfo={members.getLicenseInfo}
          memberList={members.getMemberList}
          isLoading={members.isLoading}
          getMembers={members.getMembers}
          isSearching={members.isSearching}
          onToggleMemberDetail={members.onToggleMemberDetail}
          count={members.memberListTotalCount}
        />
      </React.Fragment>
    );
  }
}

export default MemberListContainer;
