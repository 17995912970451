import React from 'react';
import './PromotionUpdatePopup.scss';
import { NewPopup } from '../../common/NewPopup';
import { LabelWithNode } from '../../common/LabelWithNode';
import DateTimePicker from 'react-datetime-picker';
const PromotionUpdatePopup = ({
  isShown,
  updateExpireDate,
  promotionInfo,
  onToggleUpdatePromotion,
  onChangeUpdateIssuer,
  onChangeUpdatePromotionCode,
  onChangeUpdateDcRate,
  onChangeUpdateExpireDate,
  onClickUpdatePromotion
}) => {
  if (!isShown) return null;
  return (
    <div className="PromotionUpdatePopup">
      <NewPopup
        title={'프로모션 정보수정'}
        onTogglePopup={() => onToggleUpdatePromotion()}
        buttons={
          <React.Fragment>
            <button className="promotion-update-btn" onClick={() => onClickUpdatePromotion(promotionInfo.promotionId)}>
              수정
            </button>
          </React.Fragment>
        }
      >
        <div className="PromotionRegister">
          <div className="promotion-register-section">
            <LabelWithNode
              label={'발급자'}
              node={
                <input
                  id="promotion-issuer"
                  type="text"
                  placeholder={'발급자를 입력해 주세요.'}
                  onChange={e => onChangeUpdateIssuer(e.target.value)}
                  defaultValue={promotionInfo.issuer}
                />
              }
            />
          </div>
          <div className="promotion-register-section">
            <LabelWithNode
              label={'프로모션 코드 (기업코드)'}
              node={
                <input
                  id="promotion-code"
                  type="text"
                  placeholder={'프로모션 코드를 입력해 주세요.'}
                  onChange={e => onChangeUpdatePromotionCode(e.target.value)}
                  defaultValue={promotionInfo.promotionCode}
                />
              }
            />
          </div>
          <div className="promotion-register-section">
            <LabelWithNode
              label={'코드 할인율'}
              node={
                <input
                  id="promotion-update-dc-rate"
                  type="text"
                  placeholder={'프로모션 할인율을 입력해 주세요. (숫자만)'}
                  onChange={e => onChangeUpdateDcRate(e.target.value)}
                  maxLength={2}
                  defaultValue={promotionInfo.dcRate}
                />
              }
            />
          </div>
          <div className="promotion-register-section">
            <LabelWithNode
              label={'유효 기간'}
              node={<DateTimePicker onChange={onChangeUpdateExpireDate} value={updateExpireDate} />}
            />
          </div>
        </div>
      </NewPopup>
    </div>
  );
};

export default PromotionUpdatePopup;
