import React, { useState } from 'react';
import HeaderContainer from '../components/base/Header/HeaderContainer';
import { ProfessorDetailPopupContainer } from '../components/professor/ProfessorDetailPopup';
import { ProfessorDashBoardContainer } from '../components/professor/ProfessorDashBoard';

const Professors = () => {
  const [isShowUsage, setIsShowUsage] = useState(false);

  const onToggleShowUsage = () => {
    setIsShowUsage(!isShowUsage);
  };

  return (
    <React.Fragment>
      <HeaderContainer />
      <ProfessorDashBoardContainer />
      <ProfessorDetailPopupContainer onToggleShowUsage={onToggleShowUsage} />
    </React.Fragment>
  );
};

export default Professors;
