import { action, observable, computed } from 'mobx';
import { SchoolApi } from '../lib/api';
class PromotionsStore {
  //#region 프로모션 탭
  @observable currentTab = 'add';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };

  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 프로모션 등록 @observable
  @observable issuer = '';
  @observable promotionCode = '';
  @observable dcRate = '';
  @observable expireDate = '';
  //#endregion

  //#region 프로모션 등록 @action
  @action
  onClickRegisterPromotion = () => {
    const { issuer, promotionCode, dcRate, expireDate } = this;
    if (!issuer) {
      return alert('발급자를 입력하지 않았습니다.');
    }
    if (!promotionCode) {
      return alert('프로모션 코드를 입력하지 않았습니다.');
    }
    if (!dcRate) {
      return alert('코드 할인율을 입력하지 않았습니다.');
    }
    if (!expireDate) {
      return alert('유효기간을 입력하지 않았습니다.');
    }
    if (new Date().getTime() >= expireDate.getTime()) {
      return alert('유효기간은 현재 날짜보다 적거나 같을 수 없습니다.');
    }

    const bodyOptions = {
      issuer: issuer,
      promotionCode: promotionCode,
      dcRate: dcRate,
      expireDate: expireDate
    };
    SchoolApi.post(`/promotions`, bodyOptions)
      .then(res => {
        if (res.data.status_code === 200) {
          this.onClickClear();
          return alert(res.data.alert_message);
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickClear = () => {
    document.getElementById('promotion-issuer').value = '';
    document.getElementById('promotion-code').value = '';
    document.getElementById('promotion-dc-rate').value = '';

    this.issuer = '';
    this.promotionCode = '';
    this.dcRate = '';
    this.expireDate = '';
  };

  @action
  onChangeIssuer = issuer => {
    this.issuer = issuer;
  };

  @action
  onChangePromotionCode = promotionCode => {
    this.promotionCode = promotionCode;
  };

  @action
  onChangeDcRate = dcRate => {
    const regex = /[0-9\b]+$/;
    // eslint-disable-next-line no-useless-escape
    const regexStr = /[ㄱ-ㅎ|가-힣|a-z|A-Z|`~!@#$%^&*|\\\'\"()-_+=;:\/?|\b]+$/;
    var temp = dcRate;

    if (regex.test(dcRate)) {
      this.dcRate = dcRate;
    } else {
      document.getElementById('promotion-dc-rate').value = temp.replace(regexStr, '');
    }
  };

  @action
  onChangeExpireDate = expireDate => {
    if (!expireDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.expireDate = '');
    }
    this.expireDate = expireDate;
  };

  //#endregion

  //#region 프로모션 등록 @computed
  @computed
  get getExpireDate() {
    return this.expireDate;
  }

  //#endregion

  //#region 프로모션 목록 조회 @observable
  @observable promotionList = [];
  @observable promotionDetail = {};
  @observable offset = 0;
  @observable limit = 15;
  @observable currentPage = 1;
  @observable searchPromotionCode = '';
  @observable searchIssuer = '';
  @observable promotionListTotalCount = 0;
  @observable isShownPromotionDetail = false;
  @observable isShownUpdatePromotion = false;
  @observable updateIssuer = '';
  @observable updatePromotionCode = '';
  @observable updateDcRate = '';
  @observable updateExpireDate = '';
  //#endregion

  //#region 프로모션 목록 조회 @action
  @action
  onChangePage = page => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearchPromotions();
  };

  @action
  onClickSearchPromotions = () => {
    const { searchPromotionCode, searchIssuer } = this;
    SchoolApi.get(
      `/promotions?offset=${this.offset}&limit=${this.limit}&promotionCode=${searchPromotionCode ||
        ''}&issuer=${searchIssuer}`
    )
      .then(res => {
        if (res.data.status_code === 200) {
          this.promotionList = res.data.data.promotionList;
          this.promotionListTotalCount = res.data.data.promotionListTotalCount;
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.promotionList = [];
          this.promotionListTotalCount = 0;
          return alert(err.response.data.alert_message);
        }
      });
  };
  @action
  onClickPromotionDetail = promotionId => {
    SchoolApi.get(`/promotions?promotionId=${promotionId}`)
      .then(res => {
        if (res.data.status_code === 200) {
          this.promotionDetail = res.data.data.promotionList[0];
          this.updateExpireDate = new Date(res.data.data.promotionList[0].expireDate);
          this.onTogglePromotionDetail();
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.promotionDetail = {};
        }
      });
  };

  @action
  onTogglePromotionDetail = () => {
    this.isShownPromotionDetail = !this.isShownPromotionDetail;
  };

  @action
  onToggleUpdatePromotion = () => {
    this.isShownPromotionDetail = false;
    this.isShownUpdatePromotion = !this.isShownUpdatePromotion;
  };

  @action
  onEnterSearch = e => {
    if (e.key === 'Enter') {
      this.onClickSearchPromotions();
    }
  };

  @action
  onChangeSearchIssuer = issuer => {
    this.searchIssuer = issuer;
  };

  @action
  onChangeSearchPromotionCode = promotionCode => {
    this.searchPromotionCode = promotionCode;
  };

  @action
  onClickSearchClear = () => {
    document.getElementById('promotion-code-search').value = '';
    document.getElementById('promotion-issuer-search').value = '';

    this.searchIssuer = '';
    this.searchPromotionCode = '';
  };

  @action
  onChangeUpdateIssuer = issuer => {
    this.updateIssuer = issuer;
  };

  @action
  onChangeUpdatePromotionCode = promotionCode => {
    this.updatePromotionCode = promotionCode;
  };

  @action
  onChangeUpdateDcRate = dcRate => {
    const regex = /[0-9\b]+$/;
    // eslint-disable-next-line no-useless-escape
    const regexStr = /[ㄱ-ㅎ|가-힣|a-z|A-Z|`~!@#$%^&*|\\\'\"()-_+=;:\/?|\b]+$/;
    var temp = dcRate;

    if (regex.test(dcRate)) {
      this.updateDcRate = dcRate;
    } else {
      document.getElementById('promotion-update-dc-rate').value = temp.replace(regexStr, '');
    }
  };

  @action
  onChangeUpdateExpireDate = expireDate => {
    if (!expireDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.updateExpireDate = '');
    }
    this.updateExpireDate = expireDate;
  };

  @action
  onClickUpdatePromotion = promotionId => {
    if (!promotionId) return;
    const { updateIssuer, updateDcRate, updateExpireDate, updatePromotionCode } = this;
    let updateOptions = {
      issuer: updateIssuer,
      dcRate: updateDcRate,
      promotionCode: updatePromotionCode,
      expireDate: updateExpireDate
    };
    SchoolApi.patch(`/promotions/${promotionId}`, updateOptions)
      .then(res => {
        if (res.data.status_code === 200) {
          this.onClickSearchPromotions();
          this.onToggleUpdatePromotion();
          return alert(res.data.alert_message);
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickDeletePromotion = promotionId => {
    if (!promotionId) return;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('프로모션을 삭제하시겠습니까?') === true) {
      SchoolApi.delete(`/promotions/${promotionId}`)
        .then(res => {
          if (res.data.status_code === 200) {
            this.onClickSearchPromotions();
            this.onTogglePromotionDetail();
            return alert(res.data.alert_message);
          }
        })
        .catch(err => {
          if (err.response.data.status_code >= 400) {
            return alert(err.response.data.alert_message);
          }
        });
    }
  };
  //#endregion

  //#region 프로모션 목록 조회 @computed
  @computed
  get getPromotionList() {
    return this.promotionList;
  }

  @computed
  get getPromotionDetail() {
    return this.promotionDetail;
  }

  @computed
  get getPromotionListTotalCount() {
    return this.promotionListTotalCount;
  }

  @computed
  get getIsShownPromotionDetail() {
    return this.isShownPromotionDetail;
  }

  @computed
  get getIsShownUpdatePromotion() {
    return this.isShownUpdatePromotion;
  }
  @computed
  get getUpdateExpireDate() {
    return this.updateExpireDate;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getLimit() {
    return this.limit;
  }
  //#endregion
}

export default new PromotionsStore();
