import React from 'react';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Table, Button } from 'semantic-ui-react';
const PromotionItem = ({ promotionInfo, onClickPromotionDetail }) => {
  return (
    <Table.Row className='PromotionItem'>
      <Table.Cell>{promotionInfo.issuer}</Table.Cell>
      <Table.Cell>{promotionInfo.promotionCode}</Table.Cell>
      <Table.Cell>{promotionInfo.dcRate}%</Table.Cell>
      <Table.Cell>{dateFormat(promotionInfo.expireDate, 'YYYY년 MM월 DD일 HH시 mm분')}</Table.Cell>
      <Table.Cell onClick={() => onClickPromotionDetail(promotionInfo.promotionId)}>
        <Button size='mini' positive>
          보기
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

//TODO: 프로모션 사용가능 여부 행 넣기

export default PromotionItem;
