import React from 'react';
import './SmsSentHistoryOption.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';

const SmsSentHistoryOption = ({
  onChangeRequestId,
  onChangeStartRequestDate,
  onChangeEndRequestDate,
  onChangeStartResultDate,
  onChangeEndResultDate,
  onChangeReceivePhoneNumber,
  onChangeMsgStatus,
  onChangeMsgResultCode,
  onChangeMsgType,
  startRequestDate,
  endRequestDate,
  startResultDate,
  endResultDate,
  msgStatus,
  msgResultCode,
  msgType,
  onSearchSentSmsList,
  onClearSentSmsList
}) => {
  return (
    <div className='SmsSentHistoryOption'>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'발송 유형'}
          node={
            <Select
              id='msg-type'
              onChange={onChangeMsgType}
              value={msgType}
              defaultValue={msgTypeList[0]}
              options={msgTypeList}
            />
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'요청 ID'}
          node={
            <input
              id='sms-request-id'
              type='text'
              placeholder='요청 ID를 입력해 주세요.'
              onChange={e => onChangeRequestId(e.target.value)}
            />
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'발송 일시 (시작 날짜, 종료 날짜)'}
          node={
            <React.Fragment>
              <DateTimePicker onChange={onChangeStartRequestDate} value={new Date(startRequestDate)} />
              <DateTimePicker onChange={onChangeEndRequestDate} value={new Date(endRequestDate)} />
            </React.Fragment>
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'수신 일시 (시작 날짜, 종료 날짜)'}
          node={
            <React.Fragment>
              <DateTimePicker onChange={onChangeStartResultDate} value={startResultDate} />
              <DateTimePicker onChange={onChangeEndResultDate} value={endResultDate} />
            </React.Fragment>
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'수신 번호'}
          node={
            <React.Fragment>
              <input
                id='receiver-sms'
                type='text'
                placeholder='수신 번호을 입력해 주세요.'
                autoComplete='new-password'
                onChange={e => onChangeReceivePhoneNumber(e.target.value)}
              />
            </React.Fragment>
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'요청 상태'}
          node={
            <Select
              id='msg-status'
              onChange={onChangeMsgStatus}
              value={msgStatus}
              defaultValue={msgStatusList[0]}
              options={msgStatusList}
            />
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <LabelWithNode
          label={'발송 결과'}
          node={
            <Select
              id='msg-result-code'
              onChange={onChangeMsgResultCode}
              value={msgResultCode}
              defaultValue={msgResultCodeList[0]}
              options={msgResultCodeList}
            />
          }
        />
      </div>
      <div className='sms-sent-list-section'>
        <div className='sms-sent-list-btns'>
          <div className='sms-sent-list-btn'>
            <button className='sms-search-btn' onClick={onSearchSentSmsList}>
              검색
            </button>
          </div>
          <div className='sms-sent-list-btn'>
            <button className='sms-sent-list-clear-btn' onClick={onClearSentSmsList}>
              초기화
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const msgTypeList = [
  {
    value: '0',
    label: 'SMS'
  },
  {
    value: '1',
    label: 'MMS'
  },
  {
    value: '2',
    label: 'Auth'
  }
];

const msgResultCodeList = [
  {
    value: '',
    label: '전체'
  },
  {
    value: 'MTR1',
    label: '성공'
  },
  {
    value: 'MTR2',
    label: '실패'
  }
];

const msgStatusList = [
  {
    value: '',
    label: '전체'
  },
  {
    value: '0',
    label: '실패'
  },
  {
    value: '1',
    label: '요청'
  },
  {
    value: '2',
    label: '처리 중'
  },
  {
    value: '3',
    label: '성공'
  },
  {
    value: '4',
    label: '예약취소'
  },
  {
    value: '5',
    label: '중복실패'
  }
];

export default SmsSentHistoryOption;
