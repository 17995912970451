import React from 'react';
import './EmailSentHistoryItem.scss';
import { Table } from 'semantic-ui-react';

const EmailSentHistoryItem = ({ emailInfo, onClickDetailEmail }) => {
  if (!emailInfo) return null;
  return (
    <Table.Row className='EmailSentHistoryItem'>
      <Table.Cell>{emailInfo.requestId}</Table.Cell>
      <Table.Cell>{emailInfo.senderAddress}</Table.Cell>
      <Table.Cell>
        {(emailInfo.receiveMailAddr && emailInfo.receiveMailAddr) ||
          (emailInfo.receiverList && emailInfo.receiverList.map(receiverInfo => receiverInfo.receiveMailAddr))}
      </Table.Cell>
      <Table.Cell>
        <p className='sent-history-detail' onClick={() => onClickDetailEmail(emailInfo.requestId, emailInfo.mailSeq)}>
          {emailInfo.title}
        </p>
      </Table.Cell>
      <Table.Cell>{emailInfo.mailStatusName}</Table.Cell>
      <Table.Cell>{emailInfo.requestDate}</Table.Cell>
      <Table.Cell>
        {(emailInfo.resultDate && emailInfo.resultDate) ||
          (emailInfo.receiverList && emailInfo.receiverList.map(receiverInfo => receiverInfo.resultDate))}
      </Table.Cell>
    </Table.Row>
  );
};

export default EmailSentHistoryItem;
