import React, { useState } from 'react';
import HeaderContainer from '../components/base/Header/HeaderContainer';
import { MemberDetailPopupContainer } from '../components/members/MemberDetailPopup';
import { LicenseUpdatePopupContainer } from '../components/members/LicenseUpdatePopup';
import { MemberUpdatePopupContainer } from '../components/members/MemberUpdatePopup';
import { MemberDashBoardContainer } from '../components/members/MemberDashBoard';
import UsagePopupContainer from '../components/members/UsagePopup/UsagePopupContainer';

const Members = () => {
  const [isShowUsage, setIsShowUsage] = useState(false);

  const onToggleShowUsage = () => {
    setIsShowUsage(!isShowUsage);
  };

  return (
    <React.Fragment>
      <HeaderContainer />
      <MemberDashBoardContainer />
      <MemberDetailPopupContainer onToggleShowUsage={onToggleShowUsage} />
      <UsagePopupContainer isShowUsage={isShowUsage} onToggleShowUsage={onToggleShowUsage} />
      <MemberUpdatePopupContainer />
      <LicenseUpdatePopupContainer />
    </React.Fragment>
  );
};

export default Members;
