import React from 'react';
import './ProgramListOptions.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button, Icon, Dropdown } from 'semantic-ui-react';
const ProgramListOptions = ({
  onEnterSearch,
  onChangeSearchProgramName,
  onChangeSearchProgramPlatform,
  onClickSearchProgram,
  searchProgramPlatform,
  onClickSearchClear
}) => {
  return (
    <div className='ProgramListOptions'>
      <div className='program-list-options-section'>
        <LabelWithNode
          label={'프로그램 이름'}
          node={
            <Input
              id='program-search-name'
              type='text'
              placeholder={'프로그램 이름을 입력해 주세요.'}
              onChange={e => onChangeSearchProgramName(e.target.value)}
              onKeyPress={onEnterSearch}
            />
          }
        />
      </div>
      <div className='program-list-options-section'>
        <LabelWithNode
          label={'프로그램 플랫폼'}
          node={
            <>
              <Dropdown
                placeholder='Select Platform'
                fluid
                search
                selection
                clearable
                value={searchProgramPlatform}
                options={platformOptions}
                id='program-search-platform'
                onChange={(e, { value }) => onChangeSearchProgramPlatform(value)}
              />
            </>
          }
        />
      </div>
      <div className='program-list-options-section'>
        <div className='program-list-options-btns'>
          <Button.Group>
            <Button color={'blue'} onClick={onClickSearchProgram} icon labelPosition='right'>
              검색
              <Icon name='search' />
            </Button>
            <Button.Or />
            <Button onClick={onClickSearchClear}>초기화</Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};

const platformOptions = [
  { key: 'windows', value: 'windows', icon: 'windows', text: 'Windows' },
  { key: 'android', value: 'android', icon: 'android', text: 'Android' },
  { key: 'macOS', value: 'macOS', icon: 'apple', text: 'macOS' },
  { key: 'iOS', value: 'iOS', icon: 'apple', text: 'iOS' },
  { key: 'web', value: 'web', icon: 'chrome', text: 'Web' }
];

export default ProgramListOptions;
