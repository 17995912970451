import React from 'react';
import './NewPopup.scss';
import { IoIosClose } from 'react-icons/io';

const NewPopup = ({ children, title, onTogglePopup, buttons }) => {
  return (
    <div className="Popup">
      <div className="popup-backdrop" />
      <div className="popup-dialog">
        <div className="popup-content">
          <div className="popup-header">
            <button className="close-btn">
              <IoIosClose onClick={() => onTogglePopup()} />
            </button>
            <h4 className="popup-title">{title}</h4>
          </div>
          <div className="popup-body">{children}</div>
          <div className="popup-footer">
            {buttons}
            <button className="popup-close-btn" onClick={() => onTogglePopup()}>
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPopup;
