import React from 'react';
import './AdminRegister.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import { Input, Button } from 'semantic-ui-react';
const AdminRegister = ({ onChangeAdminEmail, onChangeAdminNickname, onClickRegisterAdmin, onClickClear }) => {
  return (
    <div className='AdminRegister'>
      <div className='admin-register-section'>
        <LabelWithNode
          label='관리자 이메일'
          node={
            <Input
              id='admin-register-email'
              placeholder='관리자 이메일을 입력해 주세요.'
              onChange={e => onChangeAdminEmail(e.target.value)}
            />
          }
        ></LabelWithNode>
      </div>
      <div className='admin-register-section'>
        <LabelWithNode
          label='관리자 닉네임'
          node={
            <Input
              id='admin-register-nickname'
              placeholder='관리자 닉네임을 입력해 주세요.'
              onChange={e => onChangeAdminNickname(e.target.value)}
            />
          }
        ></LabelWithNode>
      </div>
      <div className='admin-register-section'>
        <div className='admin-register-btns'>
          <Button positive onClick={onClickRegisterAdmin}>
            등록
          </Button>
          <Button onClick={onClickClear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

export default AdminRegister;
