import { observable, action, computed } from 'mobx';
import { VrwareApi } from '../lib/api';

class AdminsStore {
  //#region 관리자 탭
  @observable currentTab = 'list';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 관리자 목록 조회
  @observable adminList = [];
  @observable totalCount = 0;
  @observable limit = 5;
  @observable offset = 0;
  @observable currentPage = 1;
  @observable totalPages = 0;
  @observable adminEmail = '';
  @observable searchAdminEmail = '';

  @action
  onClickSearchAdmins = () => {
    const { offset, limit, searchAdminEmail } = this;
    VrwareApi.get(`/v1.0/admins?offset=${offset}&limit=${limit}&adminEmail=${searchAdminEmail || ''}`)
      .then(res => {
        if (res.data.status === 'successful') {
          this.adminList = res.data.data.adminList;
          this.totalCount = res.data.data.totalCount;
          this.totalPages = Math.floor(this.totalCount / limit);
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          this.adminList = [];
          this.totalCount = 0;
          this.totalPages = 0;
        }
      });
  };

  @action
  onClickReleaseAdmin = adminEmail => {
    if (!adminEmail) return;
    // eslint-disable-next-line no-restricted-globals
    if (confirm('해당 관리자의 권한을 해제하시겠습니까? 해제 시 관리자 로그인이 불가능합니다.') === true) {
      VrwareApi.delete(`/v1.0/admins/${adminEmail}`)
        .then(res => {
          if (res.data.status === 'successful') {
            this.onClickSearchAdmins();
            return alert('관리자 권한 해제 완료');
          }
        })
        .catch(err => {
          if (err.response.data.status_code > 400) {
            return alert('관리자 권한 해제 실패');
          }
        });
    }
  };

  @action
  onClickSearch = () => {
    this.clearPagination();
    this.onClickSearchAdmins();
  };

  @action
  clearPagination = () => {
    this.currentPage = 1;
    this.offset = 0;
  };

  @action
  onChangeAdminEmail = adminEmail => {
    this.adminEmail = adminEmail;
  };
  @action
  onChangeAdminNickname = adminNickname => {
    this.adminNickname = adminNickname;
  };

  @action
  onClickRegisterAdmin = () => {
    const { adminEmail, adminNickname } = this;
    VrwareApi.post(`/v1.0/admins`, { adminEmail, adminNickname })
      .then(res => {
        if (res.data.status === 'successful') {
          this.onClickClear();
          return alert('관리자 등록 완료');
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickClear = () => {
    document.getElementById('admin-register-email').value = '';
    document.getElementById('admin-register-nickname').value = '';

    this.adminEmail = '';
    this.adminNickname = '';
  };

  @action
  onChangeSearchAdminEmail = searchAdminEmail => {
    this.searchAdminEmail = searchAdminEmail;
  };

  @action
  onChangeAdminListPage = page => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearchAdmins();
  };

  @action
  onEnterSearch = e => {
    if (e.key !== 'Enter') return;
    this.clearPagination();
    this.onClickSearchAdmins();
  };

  @computed
  get getTotalPages() {
    return this.totalPages;
  }

  @computed
  get getTotalCount() {
    return this.totalCount;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getAdminList() {
    return this.adminList;
  }
  //#endregion
}

export default new AdminsStore();
