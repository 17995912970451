import React, { useState } from 'react';
import { HeaderContainer } from '../components/base/Header';
import { NursingDetailPopupContainer } from '../components/nursing/NursingDetailPopup'
import { NursingDashBoardContainer } from '../components/nursing/NursingDashBoard';

 const Nursings = () => {

  const [isShowUsage, setIsShowUsage] = useState(false);

  const onToggleShowUsage = () => {
    setIsShowUsage(!isShowUsage);
  };

  return (
    <React.Fragment>
      <HeaderContainer />
      <NursingDashBoardContainer />
      <NursingDetailPopupContainer onToggleShowUsage={onToggleShowUsage}/>
    </React.Fragment>
  );
};

export default Nursings;
