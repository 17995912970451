import React from 'react';
import './PromotionList.scss';
import { PromotionItem } from '../PromotionItem';
import { Table } from 'semantic-ui-react';
const PromotionList = ({ promotionList, count, onClickPromotionDetail }) => {
  if (!promotionList.length) return null;
  return (
    <div className='PromotionList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>발급자</Table.HeaderCell>
            <Table.HeaderCell>프로모션 코드</Table.HeaderCell>
            <Table.HeaderCell>프로모션 할인율</Table.HeaderCell>
            <Table.HeaderCell>유효 기간</Table.HeaderCell>
            <Table.HeaderCell>상세보기</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {promotionList.map((promotionInfo, index) => (
            <PromotionItem key={index} promotionInfo={promotionInfo} onClickPromotionDetail={onClickPromotionDetail} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PromotionList;
