import React from 'react';
import './SmsSentHistoryList.scss';
import { SmsSentHistoryItem } from '../SmsSentHistoryItem';
import { Table } from 'semantic-ui-react';

const SmsSentHistoryList = ({ sentSmsList, onClickDetailSms }) => {
  if (!sentSmsList.data) return null;
  return (
    <div className='SmsSentHistoryList'>
      {sentSmsList.totalCount ? <h4>총 {sentSmsList.totalCount || '0'} 건이 검색되었습니다.</h4> : null}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>요청 ID</Table.HeaderCell>
            <Table.HeaderCell>발신 번호</Table.HeaderCell>

            <Table.HeaderCell>수신 번호</Table.HeaderCell>
            {sentSmsList.data.length && sentSmsList.data[0].title ? <Table.HeaderCell>제목</Table.HeaderCell> : null}
            <Table.HeaderCell>내용</Table.HeaderCell>
            <Table.HeaderCell>요청 상태</Table.HeaderCell>
            <Table.HeaderCell>단말기 발송 상태</Table.HeaderCell>
            <Table.HeaderCell>요청 시간</Table.HeaderCell>
            <Table.HeaderCell>단말기 도착 시간</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sentSmsList.data.length ? (
            sentSmsList.data.map((smsInfo, i) => (
              <SmsSentHistoryItem key={i} smsInfo={smsInfo} onClickDetailSms={onClickDetailSms} />
            ))
          ) : (
            <SmsSentHistoryItem
              key={sentSmsList.data.requestId}
              smsInfo={sentSmsList.data}
              onClickDetailSms={onClickDetailSms}
            />
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default SmsSentHistoryList;
