import { observable, action, computed } from 'mobx';
import { VrwareApi } from 'lib/api';

class NoticesStore {
  @observable noticeList = [];
  @observable noticeListTotalCount = 0;
  @observable currentTab = 'list';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };

  @computed
  get getCurrentTab() {
    return this.currentTab;
  }

  @action
  loadNoticeList = () => {
    VrwareApi.get(`/notices`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.noticeList = res.data.data.noticeList;
            this.noticeListTotalCount = res.data.data.noticeListTotalCount;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.noticeList = [];
        }
      });
  };

  @action
  onDeleteNotice = noticeId => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('공지사항을 삭제하시겠습니까?') === true) {
      VrwareApi.delete(`/notices/${noticeId}`)
        .then(
          action(res => {
            if (res.data.status_code === 200) {
              this.loadNoticeList();
              return alert('공지사항 삭제 성공');
            }
          })
        )
        .catch(err => {
          if (err.response.data.status_code >= 400) {
            return alert('공지사항 삭제 실패');
          }
        });
    }
  };

  @computed
  get getNoticeList() {
    return this.noticeList;
  }

  @computed
  get getNoticeListTotalCount() {
    return this.noticeListTotalCount;
  }
}

export default new NoticesStore();
