import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { HeaderRight } from '../HeaderRight';

const Header = ({ isSession }) => {
  return (
    <div className="Header">
      <div className="header-wrap" id={'header-wrap'}>
        <div className="logo">
          <Link to="/">VRWARE Admin</Link>
        </div>
        <div className="header-right">{isSession && <HeaderRight />}</div>
      </div>
    </div>
  );
};

export default Header;
