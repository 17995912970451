import React, { Component } from 'react';
import AuthListOptions from './AuthListOptions';
import { inject, observer } from 'mobx-react';

@inject('auths')
@observer
class AuthListOptionsContainer extends Component {
  render() {
    const { auths } = this.props;
    return (
      <React.Fragment>
        <AuthListOptions
          onClickSearchAuth={auths.onClickSearchAuth}
          onClickSearchClear={auths.onClickSearchClear}
          onChangeSearchProgramName={auths.onChangeSearchProgramName}
          onChangeSearchProgramPlatform={auths.onChangeSearchProgramPlatform}
          onEnterSearch={auths.onEnterSearch}
          searchProgramPlatform={auths.getSearchProgramPlatform}
        />
      </React.Fragment>
    );
  }
}

export default AuthListOptionsContainer;
