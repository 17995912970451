import { observable, action, computed } from 'mobx';
import { VrwareApi } from '../lib/api';
class AuthsStore {
  //#region 인증 키 탭
  @observable currentTab = 'list';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };

  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 인증 키 목록 조회
  @observable authList = [];
  @observable totalCount = 0;
  @observable currentPage = 1;
  @observable limit = 15;
  @observable offset = 0;
  @observable searchProgramName = '';
  @observable searchProgramPlatform = '';

  @action
  onClickSearchAuth = () => {
    const { offset, limit, searchProgramName, searchProgramPlatform } = this;
    VrwareApi.get(
      `/v1.0/auths?offset=${offset}&limit=${limit}&programName=${searchProgramName ||
        ''}&programPlatform=${searchProgramPlatform || ''}`
    )
      .then(res => {
        if (res.data.status_code === 200) {
          this.authList = res.data.data.authList;
          this.totalCount = res.data.data.totalCount / limit;
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          this.authList = [];
          this.totalCount = 0;
        }
      });
  };

  @action
  onChangePage = page => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearchAuth();
  };

  @action
  onClickSearchClear = () => {
    document.getElementById('auth-search-name').value = '';
    document.getElementById('auth-search-platform').value = '';

    this.searchProgramName = '';
    this.searchProgramPlatform = '';
  };

  @action
  onEnterSearch = e => {
    if (e.key !== 'Enter') return;
    this.onClickSearchAuth();
  };

  @action
  onChangeSearchProgramName = searchProgramName => {
    this.searchProgramName = searchProgramName;
  };

  @action
  onChangeSearchProgramPlatform = searchProgramPlatform => {
    this.searchProgramPlatform = searchProgramPlatform;
  };

  @computed
  get getSearchProgramPlatform() {
    return this.searchProgramPlatform;
  }

  @computed
  get getAuthList() {
    return this.authList;
  }

  @computed
  get getTotalCount() {
    return this.totalCount;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }
  //#endregion
}

export default new AuthsStore();
