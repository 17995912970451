import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { VrwareApi, NursingApi } from '../lib/api';

class NursingStore {
  //#region 관리자 탭
  @observable currentTab = 'list';

  @observable school = '';


  @observable viewname = '';
  @observable viewstart = '';
  @observable viewexpired = '';

  @observable lid = '';


  @observable showMemberDetail = false;

  @observable requestStartDate = '';
  @observable requestStartDate1 = '';

  @observable requestUpdateStartDate = '';
  @observable requestUpdateStartDate1 = '';


  @observable requestEndDate = '';
  @observable requestEndDate1 = '';

  @observable requestUpdateEndDate = '';
  @observable updateexpiredDate = '';


  @observable requestInsertStartDate = '';
  @observable startDate = '';

  @observable requestInsertEndDate = '';
  @observable expiredDate = '';

  @observable name = '';

  @observable formatDate = moment().format('yyyy-MM-dd HH:mm:ss:SSS');

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 관리자 목록 조회
  @observable adminList = [];
  @observable totalCount = 0;
  @observable limit = 5;
  @observable offset = 0;
  @observable currentPage = 1;
  @observable totalPages = 0;
  @observable adminEmail = '';
  @observable searchAdminEmail = '';

  @observable schoolList = [];



  // 사용기간 조회
  @action
  onClickSearchSchool = () => {
    const { school, requestStartDate1, requestEndDate1, offset } = this;

    NursingApi.get(`/license/all?name=${school}&start=${requestStartDate1}&expired=${requestEndDate1}&offset=${offset}`)
        .then(res => {
            this.schoolList = res.data.content;
            this.totalCount = res.data.totalElements;
            this.totalPages = res.data.totalPages;
        })
  }
  
  @action
  onClickSearchAdmins = () => {
    const { offset, limit, searchAdminEmail } = this;
    VrwareApi.get(`/v1.0/admins?offset=${offset}&limit=${limit}&adminEmail=${searchAdminEmail || ''}`)
      .then(res => {
        if (res.data.status === 'successful') {
          this.adminList = res.data.data.adminList;
          this.totalCount = res.data.data.totalCount;
          this.totalPages = Math.floor(this.totalCount / limit);
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          this.adminList = [];
          this.totalCount = 0;
          this.totalPages = 0;
        }
      });
  };

  @action
  onClickReleaseAdmin = adminEmail => {
    if (!adminEmail) return;
    // eslint-disable-next-line no-restricted-globals
    if (confirm('해당 관리자의 권한을 해제하시겠습니까? 해제 시 관리자 로그인이 불가능합니다.') === true) {
      VrwareApi.delete(`/v1.0/admins/${adminEmail}`)
        .then(res => {
          if (res.data.status === 'successful') {
            this.onClickSearchAdmins();
            return alert('관리자 권한 해제 완료');
          }
        })
        .catch(err => {
          if (err.response.data.status_code > 400) {
            return alert('관리자 권한 해제 실패');
          }
        });
    }
  };

  @action
  onChangeStartRequestDate = startRequestDate => {
    if (!startRequestDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.startRequestDate = '');
    }
    this.startRequestDate = startRequestDate;
  };
  @action
  onChangeEndRequestDate = endRequestDate => {
    if (!endRequestDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.endRequestDate = '');
    }
    this.endRequestDate = endRequestDate;
  };
  @action
  onChangeStartResultDate = startResultDate => {
    if (!startResultDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.startResultDate = '');
    }
    this.startResultDate = startResultDate;
  };

  @action
  onChangeEndResultDate = endResultDate => {
    if (!endResultDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.endResultDate = '');
    }
    this.endResultDate = endResultDate;
  };

  @action
  onChangeRequestStartDate = requestStartDate => {

    console.log(requestStartDate);

    if (!requestStartDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.requestStartDate = '', this.requestStartDate1 = '');
    }

    var date = moment(requestStartDate, 'YYYY-MM-DDTHH:mm:sszz');

    var date1 = date.format('YYYY-MM-DDTHH:mm:sszz');

    this.requestStartDate1 = date1;

    this.requestStartDate = requestStartDate;
  };

  @action
  onChangeRequestInsertStartDate = requestInsertStartDate => {

    if (!requestInsertStartDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.startDate = '', this.requestInsertStartDate = '');
    }

    var date = moment(requestInsertStartDate, 'YYYY-MM-DDTHH:mm:sszz');

    var date1 = date.format('YYYY-MM-DDTHH:mm:sszz');

    this.startDate = date1;

    this.requestInsertStartDate = requestInsertStartDate;
  };

  

  @action
  onChangeRequestEndDate = requestEndDate => {

    if (!requestEndDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.requestEndDate1 = '', this.requestEndDate = '');
    }

    var date = moment(requestEndDate, 'YYYY-MM-DDTHH:mm:sszz');

    var date1 = date.format('YYYY-MM-DDTHH:mm:sszz');

    this.requestEndDate1 = date1;

    this.requestEndDate = requestEndDate;
  };

  @action
  onChangeRequestInsertEndDate = requestInsertEndDate => {

    if (!requestInsertEndDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.expiredDate = '', this.requestInsertEndDate = '');
    }

    var date = moment(requestInsertEndDate, 'YYYY-MM-DDTHH:mm:sszz');

    var date1 = date.format('YYYY-MM-DDTHH:mm:sszz');

    this.expiredDate = date1;

    this.requestInsertEndDate = requestInsertEndDate;
  };


//수정용
@action
onChangeRequestUpdateStartDate = requestUpdateStartDate => {

  console.log(this.lid);
  
  if (!requestUpdateStartDate) {
    //X 버튼으로 지울때 null 값이 들어가는 것을 방지
    return (this.viewstart = '');
  }

  this.viewstart = requestUpdateStartDate;

  var date = moment(requestUpdateStartDate, 'YYYY-MM-DDTHH:mm:sszz');

  var date1 = date.format('YYYY-MM-DDTHH:mm:sszz');

  this.startDate = date1;


  
};


  //수정용
  @action
  onChangeRequestUpdateEndDate = requestUpdateEndDate => {

    if (!requestUpdateEndDate) {
      //X 버튼으로 지울때 null 값이 들어가는 것을 방지
      return (this.viewexpired = '');
    }


    this.viewexpired = requestUpdateEndDate;

    
    var date = moment(requestUpdateEndDate, 'YYYY-MM-DDTHH:mm:sszz');

    var date1 = date.format('YYYY-MM-DDTHH:mm:sszz');

    this.expiredDate = date1;

  };



  @action
  onClickSearch = () => {
    this.clearPagination();
    this.onClickSearchSchool();
  };


  @action
  onToggleSchoolDetail = (school, start, end, lid) => {
    this.showMemberDetail = !this.showMemberDetail;
    if (school) {

      this.viewname = school;
      this.name = school;
      this.viewstart = start;
      this.viewexpired = end;
      this.lid = lid;

      this.startDate = start;
      this.expiredDate = end;

      //this.onClickMemberDetail(school, start, end);
    }
  };

  //사용기간 조회시 사용
  @action
  onChangeSchool = school => {
    this.school = school;
  };

  //사용기간 등록시 사용
  @action
  onChangeSchoolName = school => {
    this.name = school;
  };

  @action
  clearPagination = () => {
    this.currentPage = 1;
    this.offset = 0;
  };

  @action
  onChangeAdminEmail = adminEmail => {
    this.adminEmail = adminEmail;
  };
  @action
  onChangeAdminNickname = adminNickname => {
    this.adminNickname = adminNickname;
  };

  @action
  onClickRegisterAdmin = () => {
    const { adminEmail, adminNickname } = this;
    VrwareApi.post(`/v1.0/admins`, { adminEmail, adminNickname })
      .then(res => {
        if (res.data.status === 'successful') {
          this.onClickClear();
          return alert('관리자 등록 완료');
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          return alert(err.response.data.alert_message);
        }
      });
  };


  @action
  onClickUsedDate = () => {
    const { name,  startDate, expiredDate } = this;

    NursingApi.post(`/license/`, {name, startDate, expiredDate})
    .then(res => {
      this.onClickClear();
          return alert('등록 완료');
    })
    .catch(err => {
      if (err.response.data.code === -3005) {
        return alert(err.response.data.msg);
      }else if(err.response.data.startDate){
        return alert(err.response.data.startDate);
      }else if(err.response.data.expiredDate){
        return alert(err.response.data.expiredDate);
      }else if(err.response.data.code === -8000){
        return alert(err.response.data.msg);
      }
    });
  }



  @action
  onClickUpdateDate = () => {
    const { lid, name,  startDate, expiredDate } = this;
    console.log(lid);
    console.log(name);
    console.log(startDate);
    console.log(expiredDate);
    NursingApi.post(`/license/modify`, {lid, name, startDate, expiredDate})
    .then(res => {
      this.onClickSearchSchool();
          return alert('수정 완료');
    })
    .catch(err => {
      
    });
  }


  @action
  onClickClear = () => {
    document.getElementById('admin-register-email').value = '';

    this.school = '';
  };

  

  @action
  onChangeSearchAdminEmail = searchAdminEmail => {
    this.searchAdminEmail = searchAdminEmail;
  };

  @action
  onChangeAdminListPage = page => {
    this.currentPage = page;
    this.offset = page-1;
    this.onClickSearchSchool();
  };

  @action
  onEnterSearch = e => {
    if (e.key !== 'Enter') return;
    this.clearPagination();
    this.onClickSearchSchool();
  };

  @computed
  get getTotalPages() {
    return this.totalPages;
  }

  @computed
  get getTotalCount() {
    return this.totalCount;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getAdminList() {
    return this.adminList;
  }

  @computed
  get getSchoolList(){
    return this.schoolList;
  }

  @computed
  get getRequestStartDate() {
    return this.requestStartDate;
  }

  @computed
  get getRequestEndDate() {
    return this.requestEndDate;
  }


  @computed
  get getRequestUpdateStartDate(){
    return this.requestUpdateStartDate;
  }

  @computed
  get getRequestUpdateEndDate(){
    return this.requestUpdateEndDate;
  }


  @computed
  get getRequestInsertStartDate() {
    return this.requestInsertStartDate;
  }

  @computed
  get getRequestInsertEndDate() {
    return this.requestInsertEndDate;
  }

  //#endregion
}

export default new NursingStore();
