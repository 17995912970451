import React, { Component } from 'react';
import SmsDashBoard from './SmsDashBoard';
import { inject, observer } from 'mobx-react';

@inject('sms', 'session')
@observer
class SmsDashBoardContainer extends Component {
  render() {
    const { sms, session } = this.props;
    return (
      <React.Fragment>
        <SmsDashBoard
          smsTabList={smsTabList}
          onClickTab={sms.onClickTab}
          currentTab={sms.getCurrentTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const smsTabList = [
  {
    value: 'send',
    text: '문자 발송하기'
  },
  {
    value: 'sent-sms-list',
    text: '문자 발송내역 조회'
  }
];

export default SmsDashBoardContainer;
