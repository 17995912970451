import React from 'react';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { ProfessorListOptionsContainer } from '../ProfessorListOptions';
import { ProfessorAddOptionsContainer } from '../ProfessorAddOptions';
import { ProfessorListContainer } from '../ProfessorList';
import { ProfessorListPaginationContainer } from '../ProfessorListPagination';

const ProfessorDashBoard = ({ memberTabList, onClickTab, currentTab, isSession }) => {
  if (!isSession) return (window.location.href = '/');
  return (
    <DashBoardTemplate title={'교수'} tabList={memberTabList} onClickTab={onClickTab} currentTab={currentTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<ProfessorListOptionsContainer />}>
          <ProfessorListContainer />
          <ProfessorListPaginationContainer />
        </DashBoardBody>
      )}
      {currentTab === 'add' && <DashBoardBody option={<ProfessorAddOptionsContainer />}></DashBoardBody>}
    </DashBoardTemplate>
  );
};

export default ProfessorDashBoard;
