import React, { Component } from 'react';
import SmsSentHistoryListPagination from './SmsSentHistoryListPagination';
import { inject, observer } from 'mobx-react';

@inject('sms')
@observer
class SmsSentHistoryListPaginationContainer extends Component {
  render() {
    const { sms } = this.props;
    return (
      <React.Fragment>
        <SmsSentHistoryListPagination
          smsListTotalCount={sms.getEmailListTotalCount}
          limit={sms.getLimit}
          currentPage={sms.getCurrentPage}
          onChangePage={sms.onChangePage}
        />
      </React.Fragment>
    );
  }
}

export default SmsSentHistoryListPaginationContainer;
