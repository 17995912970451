import React from 'react';
import './EmailSentHistoryDetailPopup.scss';
import { NewPopup } from '../../common/NewPopup';
import HTMLRenderer from 'react-html-renderer';

const EmailSentHistoryDetailPopup = ({ isShown, onTogglePopup, emailInfo }) => {
  if (!isShown) return null;

  return (
    <div className="EmailSentHistoryDetailPopup">
      <NewPopup title={'메일 상세 조회'} onTogglePopup={onTogglePopup}>
        <div className="email-detail-info-wrap">
          <h2>메일 상세 정보</h2>
          <div className="email-detail-info">
            <div className="email-detail-section">
              <label>요청 ID</label>
              <p>{emailInfo.requestId}</p>
              <label>요청 IP</label>
              <p>{emailInfo.requestIp}</p>
            </div>
            <div className="email-detail-section">
              <label>요청 일시</label>
              <p>{emailInfo.requestDate}</p>
              <label>수신 일시</label>
              <p>{emailInfo.receiverList.map(receiverInfo => receiverInfo.resultDate)}</p>
            </div>
            <div className="email-detail-section">
              <label>발송 상태</label>
              <p>{emailInfo.mailStatusName}</p>
            </div>
            <div className="email-detail-section">
              <label>메일 ID</label>
              <p>{emailInfo.resultId}</p>
            </div>
            <div className="email-detail-section">
              <label>발신 정보</label>
              <p>{`${emailInfo.senderName}<${emailInfo.senderAddress}>`}</p>
            </div>
            <div className="email-detail-section">
              <label>수신 정보</label>
              <div className="detail-receiver-list">
                {emailInfo.receiverList.map(receiverInfo => (
                  <div className="detail-receiver-item">{receiverInfo.receiveMailAddr}</div>
                ))}
              </div>
            </div>
            <div className="email-detail-section">
              <label>첨부파일</label>
              <p>{emailInfo.attachFileList.map(file => file)}</p>
            </div>
          </div>
        </div>
        <div className="email-info-wrap">
          <h2>메일 내용</h2>
          <div className="email-info">
            <div className="email-title">
              <p>제목</p>
              {emailInfo.title}
            </div>
            <div className="email-body">
              <p>내용</p>
              <HTMLRenderer html={emailInfo.body} />
            </div>
          </div>
        </div>
      </NewPopup>
    </div>
  );
};

export default EmailSentHistoryDetailPopup;
