import React from 'react';
import { MemberItem } from '../MemberItem';
import './MemberList.scss';
import { Table } from 'semantic-ui-react';

const MemberList = ({ memberList, isLoading, onToggleMemberDetail, count }) => {
  if (isLoading) return null;
  return (
    <div className='MemberList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No</Table.HeaderCell>
            <Table.HeaderCell>이메일</Table.HeaderCell>
            <Table.HeaderCell>닉네임</Table.HeaderCell>
            <Table.HeaderCell>회원타입</Table.HeaderCell>
            <Table.HeaderCell>회원상태</Table.HeaderCell>
            <Table.HeaderCell>가입날짜</Table.HeaderCell>
            <Table.HeaderCell>최근 접속일</Table.HeaderCell>
            {/* <Table.HeaderCell>라이선스 등급</Table.HeaderCell>
            <Table.HeaderCell>라이선스 만료일</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {memberList.map((member, index) => (
            <MemberItem key={index} index={index} member={member} onToggleMemberDetail={onToggleMemberDetail} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default MemberList;
