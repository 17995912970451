import React, { Component } from 'react';
import PromotionListPagination from './PromotionListPagination';
import { inject, observer } from 'mobx-react';

@inject('promotions')
@observer
class PromotionListPaginationContainer extends Component {
  render() {
    const { promotions } = this.props;
    return (
      <React.Fragment>
        <PromotionListPagination
          limit={promotions.getLimit}
          currentPage={promotions.getCurrentPage}
          promotionListTotalCount={promotions.getPromotionListTotalCount}
          onChangePage={promotions.onChangePage}
        />
      </React.Fragment>
    );
  }
}

export default PromotionListPaginationContainer;
