import React from 'react';
import './LoginForm.scss';
import { Button, Input, Icon } from 'semantic-ui-react';

const LoginForm = ({ onClickLogin, onChangeEmail, onChangePwd, isSession, loginEnter }) => {
  if (isSession) return null;
  return (
    <div className='LoginForm'>
      <div className='login-wrap'>
        <h2 className='login-title'>VRWARE Admin Login</h2>
        <div className='login-input-wrap'>
          <div className='input-email'>
            <Input
              type='text'
              placeholder={'이메일을 입력해 주세요.'}
              onChange={e => onChangeEmail(e.target.value)}
              onKeyPress={loginEnter}
              fluid
            />
          </div>
          <div className='input-pwd'>
            <Input
              type='password'
              placeholder={'비밀번호를 입력해 주세요.'}
              onChange={e => onChangePwd(e.target.value)}
              onKeyPress={loginEnter}
              fluid
            />
          </div>
        </div>
        <div className='login-btn'>
          <Button animated='vertical' color='blue' onClick={onClickLogin} fluid>
            <Button.Content visible>로그인</Button.Content>
            <Button.Content hidden>
              <Icon name='unlock' />
            </Button.Content>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
