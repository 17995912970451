import React from 'react';
import './EmailDelivery.scss';
import { LabelWithNode } from '../../common/LabelWithNode';
import DateTimePicker from 'react-datetime-picker';
import TextareaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import { EmailReceiverListContainer } from '../EmailReceiverList';
import { EmailCarbonCopyListContainer } from '../EmailCarbonCopyList';
const EmailDelivery = ({
  onChangeTitle,
  onChangeBody,
  onChangeRequestDate,
  onChangeEmailType,
  onInsertReceiver,
  onInsertCarbonCopy,
  onClickClear,
  onClickSendEmail,
  onTogglePopup,
  emailType,
  requestDate
}) => {
  return (
    <div className="EmailDelivery">
      <div className="email-delivery-section">
        <LabelWithNode
          label={'메일 유형'}
          node={
            <Select
              id="email-type"
              defaultValue={emailTypeList[0]}
              options={emailTypeList}
              value={emailType}
              onChange={onChangeEmailType}
            />
          }
        />
      </div>
      <div className="email-delivery-section">
        <LabelWithNode label={'발송 메일'} node={<span>gpsales@globepoint.co.kr</span>} />
      </div>
      <div className="email-delivery-section">
        <LabelWithNode
          label={'예약 전송'}
          node={<DateTimePicker onChange={onChangeRequestDate} value={requestDate} />}
        />
      </div>
      <div className="email-delivery-section">
        <LabelWithNode
          label={'제목'}
          node={
            <React.Fragment>
              <input
                id="email-title"
                type="text"
                onChange={e => onChangeTitle(e.target.value)}
                placeholder="제목을 입력해 주세요."
              />
            </React.Fragment>
          }
        />
      </div>
      <div className="email-delivery-section">
        <LabelWithNode
          label={'내용'}
          node={
            <TextareaAutosize
              id="email-textarea-body"
              rows={4}
              onChange={e => onChangeBody(e.target.value)}
              placeholder="내용을 입력해 주세요."
            />
          }
        />
      </div>
      <div className="email-delivery-section">
        <LabelWithNode
          label={'받는 사람'}
          node={
            <input
              type="text"
              onKeyUp={onInsertReceiver}
              placeholder="이메일 입력 후 Enter."
              autoComplete="new-password"
            />
          }
        />
        <EmailReceiverListContainer />
      </div>
      <div className="email-delivery-section">
        {(emailType.value === '0' || emailType.value === '3') && (
          <React.Fragment>
            <LabelWithNode
              label={'참조'}
              node={
                <input
                  type="text"
                  onKeyUp={onInsertCarbonCopy}
                  placeholder="이메일 입력 후 Enter."
                  autoComplete="new-password"
                />
              }
            />
            <EmailCarbonCopyListContainer />
          </React.Fragment>
        )}
      </div>
      <div className="email-delivery-section">
        <div className="email-delivery-btns">
          <div className="email-delivery-btn">
            <button className="email-send-btn" onClick={onTogglePopup}>
              보내기
            </button>
          </div>
          <div className="email-delivery-btn">
            <button className="email-clear-btn" onClick={onClickClear}>
              초기화
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const emailTypeList = [
  { value: '0', label: '일반 메일' },
  { value: '1', label: '개별 메일' },
  // { value: '2', label: '인증 메일' }, 얘는 서버에서만 보내는 전용으로 두겠음
  { value: '3', label: '광고 메일' },
  { value: '4', label: '광고 개별 메일' }
];

export default EmailDelivery;
