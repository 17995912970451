import React, { Component } from 'react';
import SmsRecipientList from './SmsRecipientList';
import { inject, observer } from 'mobx-react';

@inject('sms')
@observer
class SmsRecipientListContainer extends Component {
  render() {
    const { sms } = this.props;
    return (
      <React.Fragment>
        <SmsRecipientList recipientList={sms.getRecipientList} onDeleteRecipient={sms.onDeleteRecipient} />
      </React.Fragment>
    );
  }
}

export default SmsRecipientListContainer;
