import React from 'react';
import './SmsSentHistoryItem.scss';
import { Table } from 'semantic-ui-react';
const SmsSentHistoryItem = ({ smsInfo, onClickDetailSms }) => {
  if (!smsInfo) return null;
  return (
    <Table.Row className='SmsSentHistoryItem'>
      <Table.Cell>
        <p onClick={() => onClickDetailSms(smsInfo.requestId, smsInfo.mtPr)}>{smsInfo.requestId}</p>
      </Table.Cell>
      <Table.Cell>{smsInfo.sendNo}</Table.Cell>
      <Table.Cell>{smsInfo.recipientNo}</Table.Cell>
      {smsInfo.title ? <Table.Cell>{substrTitle(smsInfo.title)}</Table.Cell> : null}
      <Table.Cell>{substrBody(smsInfo.body)}</Table.Cell>
      <Table.Cell>{smsInfo.msgStatusName}</Table.Cell>
      <Table.Cell>{smsInfo.resultCodeName}</Table.Cell>
      <Table.Cell>{smsInfo.requestDate}</Table.Cell>
      <Table.Cell>{smsInfo.resultDate}</Table.Cell>
    </Table.Row>
  );
};

const substrTitle = input => {
  if (!input) return null;

  if (input.length >= 15) {
    return input.substr(0, 15) + '...';
  }
  return input;
};

const substrBody = input => {
  if (!input) return null;

  if (input.length >= 20) {
    return input.substr(0, 20) + '...';
  }
  return input;
};

export default SmsSentHistoryItem;
