import { observable, action, computed } from 'mobx';
import { SchoolApi } from '../lib/api';

class MapsStore {
  //#region 맵 탭
  @observable currentTab = 'list';

  @action
  onClickTab = tab => {
    this.currentTab = tab;
  };
  @computed
  get getCurrentTab() {
    return this.currentTab;
  }
  //#endregion

  //#region 맵 목록 조회
  @observable offset = 0;
  @observable limit = 8;
  @observable mapList = [];
  @observable mapDetail = {};
  @observable totalCount = 0;
  @observable currentPage = 1;
  @observable isOpenMapDetailPopup = false;
  @observable searchMapTitle = '';
  @observable searchMapEmail = '';
  @observable isShared = false;
  @observable isLoadingImg = false;
  @observable isGpShared = false;

  @action
  onClickSearchMap = isSearch => {
    let { offset } = this;
    const { limit, searchMapTitle, searchMapEmail, isShared, isGpShared } = this;

    if (isSearch) {
      offset = 0;
      this.currentPage = 1;
    }

    SchoolApi.get(
      `/v1.0/maps?offset=${offset}&limit=${limit}&title=${searchMapTitle || ''}&email=${searchMapEmail ||
        ''}&isShared=${isShared || ''}&isGpShared=${isGpShared || ''}`
    )
      .then(res => {
        if (res.data.status === 'successful') {
          this.mapList = res.data.data.mapList;
          this.totalCount = Math.floor(res.data.data.totalCount / limit);
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          this.mapList = [];
          this.totalCount = 0;
        }
      });
  };

  @action
  onChangeSearchMapTitle = title => {
    this.searchMapTitle = title;
  };

  @action
  onChangeSearchMapEmail = email => {
    this.searchMapEmail = email;
  };

  @action
  onEnterSearchMap = e => {
    if (e.key !== 'Enter') return;
    this.onClickSearchMap(true);
  };

  @action
  onToggleIsShared = () => {
    this.isShared = !this.isShared;
    this.onClickSearchMap(true);
  };

  @action
  onToggleIsGpShared = () => {
    this.isGpShared = !this.isGpShared;
    this.onClickSearchMap(true);
  };

  @action
  onClickMapShare = mapId => {
    if (!mapId) return;
    SchoolApi.post(`/v1.0/share-maps/${mapId}`).then(res => {
      if (res.data.status === 'successful') {
        this.onToggleMapDetailPoup();
        this.onClickSearchMap();
        return alert('해당 맵을 공유하였습니다.');
      }
    });
  };

  @action
  onClickReleaseShareMap = mapId => {
    if (!mapId) return;
    // eslint-disable-next-line no-restricted-globals
    if (confirm('공유 맵을 해제하시겠습니까?') === true) {
      SchoolApi.delete(`/v1.0/share-maps/${mapId}`).then(res => {
        if (res.data.status === 'successful') {
          this.onToggleMapDetailPoup();
          this.onClickSearchMap();
          return alert('공유 맵을 해제하였습니다.');
        }
      });
    }
  };

  @action
  onClickGpShare = mapId => {
    if (!mapId) return;

    SchoolApi.patch(`/v1.0/maps/${mapId}`, { isGpShared: true }).then(res => {
      if (res.data.status === 'successful') {
        this.onToggleMapDetailPoup();
        this.onClickSearchMap();
        return alert('해당 GP 맵을 공유하였습니다.');
      }
    });
  };

  @action
  onClickReleaseGpShareMap = mapId => {
    if (!mapId) return;
    // eslint-disable-next-line no-restricted-globals
    if (confirm('GP 공유 맵을 해제하시겠습니까?') === true) {
      SchoolApi.patch(`/v1.0/maps/${mapId}`, { isGpShared: false }).then(res => {
        if (res.data.status === 'successful') {
          this.onToggleMapDetailPoup();
          this.onClickSearchMap();
          return alert('공유 맵을 해제하였습니다.');
        }
      });
    }
  };

  @action
  onClickMapDetail = mapId => {
    this.isLoadingImg = false;
    SchoolApi.get(`/v1.0/maps?mapId=${mapId}`)
      .then(res => {
        if (res.data.status === 'successful') {
          setTimeout(() => {
            this.isLoadingImg = true;
          }, 300);
          this.mapDetail = res.data.data.mapList[0];
          this.onToggleMapDetailPoup();
        }
      })
      .catch(err => {
        if (err.response.data.status_code > 400) {
          this.mapList = {};
        }
      });
  };

  @action
  onClickSearchMapClear = () => {
    document.getElementById('map-search-name').value = '';
    document.getElementById('map-search-email').value = '';

    this.searchMapEmail = '';
    this.searchMapTitle = '';
  };

  @action
  onToggleMapDetailPoup = () => {
    this.isOpenMapDetailPopup = !this.isOpenMapDetailPopup;
  };

  @action
  onChangeMapListPage = page => {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.onClickSearchMap();
  };

  @computed
  get getIsShared() {
    return this.isShared;
  }

  @computed
  get getIsGpShared() {
    return this.isGpShared;
  }

  @computed
  get getMapList() {
    return this.mapList;
  }

  @computed
  get getMapDetail() {
    return this.mapDetail;
  }

  @computed
  get getTotalCount() {
    return this.totalCount;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @computed
  get getIsOpenMapDetailPopup() {
    return this.isOpenMapDetailPopup;
  }

  @computed
  get getIsLoadingImg() {
    return this.isLoadingImg;
  }
  //#endregion
}

export default new MapsStore();
