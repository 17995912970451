import React, { Component } from 'react';
import LicenseListOptions from './LicenseListOptions';
import { inject, observer } from 'mobx-react';

@inject('licenses')
@observer
class LicenseListOptionsContainer extends Component {
  render() {
    const { licenses } = this.props;
    return (
      <React.Fragment>
        <LicenseListOptions
          onChangeLicenseGrade={licenses.onChangeLicenseGrade}
          onChangeLicenseType={licenses.onChangeLicenseType}
          onChangeEmail={licenses.onChangeEmail}
          onClickSearch={licenses.onClickSearch}
          onEnterSearch={licenses.onEnterSearch}
          onChangeLimit={licenses.onChangeLimit}
        />
      </React.Fragment>
    );
  }
}

export default LicenseListOptionsContainer;
