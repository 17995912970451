import React, { Component } from 'react';
import ProgramListOptions from './ProgramListOptions';
import { inject, observer } from 'mobx-react';

@inject('programs')
@observer
class ProgramListOptionsContainer extends Component {
  render() {
    const { programs } = this.props;
    return (
      <React.Fragment>
        <ProgramListOptions
          onChangeSearchProgramName={programs.onChangeSearchProgramName}
          onChangeSearchProgramPlatform={programs.onChangeSearchProgramPlatform}
          onClickSearchProgram={programs.onClickSearchProgram}
          onEnterSearch={programs.onEnterSearch}
          searchProgramPlatform={programs.getSearchProgramPlatform}
          onClickSearchClear={programs.onClickSearchClear}
        />
      </React.Fragment>
    );
  }
}

export default ProgramListOptionsContainer;
