import React, { Component } from 'react';
import AuthList from './AuthList';
import { inject, observer } from 'mobx-react';

@inject('auths')
@observer
class AuthListContainer extends Component {
  render() {
    const { auths } = this.props;
    return (
      <React.Fragment>
        <AuthList
          authList={auths.getAuthList}
          totalCount={auths.getTotalCount}
          currentPage={auths.currentPage}
          onChangePage={auths.onChangePage}
        />
      </React.Fragment>
    );
  }
}

export default AuthListContainer;
