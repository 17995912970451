import React from 'react';
import './NursingDashBoard.scss';
import { DashBoardTemplate } from '../../base/DashBoardTemplate';
import { DashBoardBody } from '../../base/DashBoardBody';
import { NursingListContainer } from '../NursingList';
import { NursingListOptionsContainer } from '../NursingListOptions';
import { NursingRegisterContainer } from '../NursingRegister';

const NursingDashBoard = ({ tabList, currentTab, onClickTab, isSession }) => {
  if (!isSession) return (window.location = '/');
  return (
    <DashBoardTemplate title={'사용기간'} tabList={tabList} currentTab={currentTab} onClickTab={onClickTab}>
      {currentTab === 'list' && (
        <DashBoardBody option={<NursingListOptionsContainer />}>
          <NursingListContainer />
        </DashBoardBody>
      )}
      {currentTab === 'add' && (
        <DashBoardBody>
          <NursingRegisterContainer />
        </DashBoardBody>
      )}
    </DashBoardTemplate>
  );
};

export default NursingDashBoard;
