import { action } from 'mobx';
import { VrwareApi } from '../lib/api';

//회원 등록 Store
//작성자 : 이승우
//작성일 : 2020-01-02
class MemberAddStore {
  //CSV 파일 읽고 일괄 회원가입
  //작성자 : 이승우
  //작성일 : 2020-01-02
  @action
  onFileLoadAndSignUp = (memberList) => {
    if (!memberList[0]) return alert('파일이 비어있습니다.');

    let filename = document.getElementById('member-csv-file').files[0].name;

    //파일 확장자 확인
    let fileExt = filename.substring(filename.lastIndexOf('.'), filename.length).toLowerCase();
    if (fileExt !== '.csv') {
      //파일 초기화
      document.getElementById('member-csv-file').value = '';
      document.getElementById('member-csv-file-name').value = 'csv 파일만 읽을 수 있습니다.';
      return alert('확장자 csv 파일만 읽을 수 있습니다.');
    }

    //파일 이름 렌더링
    document.getElementById('member-csv-file-name').value = filename;

    memberList.map((member, i) => {
      //회원가입 parameters
      let signUpOption = {},
        licenseOption = {};

      if (!member.email) return alert(i + '번째 email 열이 존재하지 않습니다.');
      if (!member.pwd) return alert(i + '번째 pwd 열이 존재하지 않습니다.');
      if (!member.pwdCheck) return alert(i + '번째 pwdCheck 열이 존재하지 않습니다.');
      if (!member.nickname) return alert(i + '번째 nickname 열이 존재하지 않습니다.');
      if (!member.memberType) return alert(i + '번째 memberType 열이 존재하지 않습니다.');

      Object.assign(signUpOption, {
        email: member.email.trim(),
        pwd: member.pwd.trim(),
        pwdCheck: member.pwdCheck.trim(),
        nickname: member.nickname.trim(),
        memberType: member.memberType,
        isVrwareEmail: member.isVrwareEmail || false,
        receiveMarketing: member.receiveMarketing || false,
        group: member.group.trim(),
      });

      //vrware 계정일 경우
      if (member.isVrwareEmail) {
        Object.assign(signUpOption, {
          firstQuestion: member.firstQuestion,
          secondQuestion: member.secondQuestion,
          firstAnswer: member.firstAnswer.trim(),
          secondAnswer: member.secondAnswer.trim(),
        });
        Object.assign(licenseOption, {
          email: member.email.trim(),
          group: member.group.trim(),
          licenseType: member.licenseType.trim(),
          licenseGrade: member.licenseGrade.trim(),
          expireDate: member.expireDate.trim(),
        });
      }

      return VrwareApi.post('/members', signUpOption)
        .then((res) => {
          if (res.data.status_code === 200) {
            if (member.isVrwareEmail) {
              VrwareApi.post(`/v1.0/licenses`, licenseOption);
            }
            if (memberList.length === i + 1) {
              document.getElementById('member-csv-file').value = '';
              document.getElementById('member-csv-file-name').value = '일괄 등록 요청 성공';
              return alert('일괄 등록 요청 성공');
            }
          }
        })
        .catch((err) => {
          if (err.response.data.status_code >= 400) {
            document.getElementById('member-csv-file').value = '';
            document.getElementById('member-csv-file-name').value = err.response.data.alert_message;
            return alert(err.response.data.alert_message);
          }
        });
    });
  };
}

export default new MemberAddStore();
