import React, { Component } from 'react';
import NoticeDashBoard from './NoticeDashBoard';
import { inject, observer } from 'mobx-react';
@inject('notices', 'session')
@observer
class NoticeDashBoardContainer extends Component {
  render() {
    const { notices, session } = this.props;
    return (
      <React.Fragment>
        <NoticeDashBoard
          noticeTabList={noticeTabList}
          currentTab={notices.getCurrentTab}
          onClickTab={notices.onClickTab}
          isSession={session.isSession}
        />
      </React.Fragment>
    );
  }
}

const noticeTabList = [
  {
    value: 'list',
    text: '공지사항 목록'
  }
];

export default NoticeDashBoardContainer;
