import React from 'react';
import HeaderContainer from '../components/base/Header/HeaderContainer';
import { LicenseDashBoardContainer } from '../components/licenses/LicenseDashBoard';

const Licenses = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <LicenseDashBoardContainer />
    </React.Fragment>
  );
};

export default Licenses;
