import React from 'react';
import HeaderContainer from '../components/base/Header/HeaderContainer';
import { AuthDashBoardContainer } from '../components/auths/AuthDashBoard';

const Auths = () => {
  return (
    <React.Fragment>
      <HeaderContainer />
      <AuthDashBoardContainer />
    </React.Fragment>
  );
};

export default Auths;
