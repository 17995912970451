import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { observer } from 'mobx-react';
import './EmailCarbonCopyList.scss';

const EmailCarbonCopyList = ({ carbonCopyList, onDeleteCarbonCopy }) => {
  return (
    <div className="EmailCarbonCopyList">
      {carbonCopyList.map(carbonCopy => (
        <div key={carbonCopy} className="EmailCarbonCopyItem">
          <div className="cc-email">{carbonCopy}</div>
          <div className="cc-remove">
            <IoMdClose onClick={() => onDeleteCarbonCopy(carbonCopy)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(EmailCarbonCopyList);
