import React from 'react';
import { PaymentItem } from '../PaymentItem';
import './PaymentList.scss';
import { Table } from 'semantic-ui-react';

const PaymentList = ({ paymentList, count, onClickPaymentDetail }) => {
  if (!paymentList[0]) return null;
  return (
    <div className='PaymentList'>
      <h4>{count} 건이 검색되었습니다.</h4>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>이메일</Table.HeaderCell>
            {/* <Table.HeaderCell>상품정보</Table.HeaderCell> */}
            <Table.HeaderCell>결제일자</Table.HeaderCell>
            <Table.HeaderCell>결제번호</Table.HeaderCell>
            <Table.HeaderCell>결제금액</Table.HeaderCell>
            <Table.HeaderCell>결제정보</Table.HeaderCell>
            <Table.HeaderCell>할부</Table.HeaderCell>
            <Table.HeaderCell>카드정보</Table.HeaderCell>
            <Table.HeaderCell>상세보기</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {paymentList.map((paymentInfo) => (
            <PaymentItem paymentInfo={paymentInfo} onClickPaymentDetail={onClickPaymentDetail} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PaymentList;
